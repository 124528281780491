import React from 'react';
import {HashLoader} from "react-spinners";

const Loader = (props: { showLoading }) => {
    const {showLoading} = props
    return (
        <div style={{zIndex: 99999999, backgroundColor: '#131619', opacity: 0.9, height: '100vh'}}
             className={`h-100 w-100 position-absolute d-flex align-items-center justify-content-center`}>
            <HashLoader cssOverride={{zIndex: 100}}
                        color="#10d078"
                        loading={showLoading}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"/>
        </div>
    );
};

export default Loader;