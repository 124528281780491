import React, {useEffect} from 'react';
import {Button, Form, Input} from "antd";
import {irPhoneNoRegex} from "../../../shared/regex-and-validations";
import {sendValidationCodeService} from "../../../shared/services/services";
import {selectShowLoading, showLoadingAction, useAppDispatch, useAppSelector, userMobileAction} from "../../../redux";

const Login = (props) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const showLoading = useAppSelector(selectShowLoading);

    const sendValidationCode = async (form) => {
        dispatch(showLoadingAction(true))
        dispatch(userMobileAction(form.mobile));
        const res = await sendValidationCodeService(form)
        if (!!res && res.statusCode === 200) {
            props.validationCodeSent()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    };
    const onFormFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }

    useEffect(() => {
        if (props.mobile
            && props.mobile.length > 0
            && !showLoading
            && (!form.getFieldValue('mobile') || form.getFieldValue('mobile').length === 0 || form.getFieldValue('mobile') !== props.mobile)) {
            form.setFieldValue('mobile', props.mobile)
            form.submit();
        }
        // eslint-disable-next-line
    }, [props.mobile])
    return (
        <>
            <h3 className={`text-white text-center`}>ورود یا ثبت نام</h3>
            <Form
                className={`mt-5 w-100`}
                name="loginComponent"
                labelCol={{span: 25}}
                wrapperCol={{span: 25}}
                onFinish={sendValidationCode}
                onFinishFailed={onFormFailed}
                autoComplete="off"
                form={form}
            >
                <h5 className={`text-white`}>شماره موبایل</h5>
                <Form.Item
                    label=""
                    name="mobile"
                    rules={[
                        {required: true, message: 'لطفا شماره موبایل خود را وارد کنید!'},
                        {pattern: irPhoneNoRegex, message: "لطفا شماره موبایل را به صورت صحیح وارد کنید"}
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark w-100 ss02 mb-2`}/>
                </Form.Item>
                <Form.Item shouldUpdate wrapperCol={{span: 25}}>
                    {() => (
                        <Button type="primary"
                                htmlType="submit"
                                disabled={!!form.getFieldsError().filter(({errors}) => errors.length).length}
                                className={`btn btnPrimary px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3`}>
                            دریافت پیامک
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </>
    );
};

export default Login;