import React from 'react';
import styles from "./about-us.module.scss";
import Navbar from "../../shared/navbar/navbar";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import Support from "../home/support/support";
import style from "../home/your-help/your-help.module.scss";
import ReactPlayer from "react-player";

const AboutUs = () => {

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status);
    }


    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`${styles.gradiant2}`}></div>
            <div className={`${styles.whyBitBlexGradiant}`}></div>
            <div className={`${styles.whyBitBlexGradiant2}`}></div>
            <div className={`container d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div className={`${styles.container} container`}>
                    <div className={`row lh-lg fw-light`}>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-6 ss02 fontSizeSmall`}>
                            <h4 className={`fontWeightBold`}>درباره بیتبلکس</h4>
                            <div>
                                فعالیت اصلی بیت بلکس از 18 سال پیش آغاز شد. شروع فعالیت ما با تیمی کوچک و با هدف سرویس
                                دهی و
                                خدمات
                                به مشتریان بود؛ اما با گذر زمان و توسعه یافتن تکنولوژی های فراگیر ، ما نیز درصدد بهبود
                                نقاط
                                قوت
                                و
                                برطرف کردن نقاط ضعف خود بودیم تا بتوان در کنار رقبای سرسخت خود همیشه حرفی برای گفتن
                                داشته
                                باشیم.
                                از سال 93 با رشد مبحث تبادلات مالی دیجیتال، وارد عرصه شدیم و برای ارائه
                                راهکارهای کارآمد به مشتریان خود تلاش کردیم تا اینکه در سال های اخیر با ایجاد زیرساخت
                                سرویس و
                                خدمات مبتنی
                                بر
                                بلاکچین، زمینه ساز ارائه خدمات از سایر کسب و کارها، به شما مشتریان عزیزمان بودیم، در حال
                                حاضر با
                                گذشت 18
                                سال سابقه خدمات دیجیتال و ارائه صدها طرح موفق، زمان آن رسیده تا پرقدرت تر از همیشه در
                                کنار
                                شما
                                همراهان
                                همیشگی باشیم.
                            </div>
                            <img src="/img/logo.png" alt="support-comment" height={50} className={`mt-3`}/>
                        </div>
                        <div className={`col-xl-6 d-none d-xl-block position-relative`}>
                            <img src="/img/about-us-1.png" alt="about-us1" className={`${styles.img1}`}/>
                            <img src="/img/about-us-2.png" alt="about-us2" className={`${styles.img2}`}/>
                            <img src="/img/about-us-3.png" alt="about-us3" className={`${styles.img3}`}/>
                        </div>
                    </div>
                    <div
                        className={`${styles.secondCard} card box-shadow border-0 flex-column-reverse flex-md-row justify-content-around`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0`}>
                            <span className={`secondaryColor`}>گردش مالی ماهیانه</span>
                            <span className={`mt-1 mt-md-3 ss02`}>+ 100 میلیارد تومان</span>
                        </div>
                        <div className={`d-flex flex-column align-items-center justify-content-center mt-4 mt-md-0`}>
                            <span className={`secondaryColor`}>پشتیبانی</span>
                            <span className={`mt-1 mt-md-3 ss02`}>پشتیبانی 24/7</span>
                        </div>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <span className={`secondaryColor`}>معاملات</span>
                            <span className={`mt-1 mt-md-3 ss02`}>+ 20000 تراکنش</span>
                        </div>
                    </div>
                    <Support></Support>
                    <div className={`${styles.mainContainer}`}>
                        <h2 className={`fontWeightBold`}>
                            <span className={`primaryColor`}>چرا </span> {' '} <span
                            className={`text-white`}>بیتبلکس؟</span>
                        </h2>
                        <span className={`fontSizeSmall text-white ss02`}>
                            انتخاب بیش از 2 هزار کاربر فعال در حوزه رمز ارز و معاملات کریپتو
                        </span>
                        <div className={`position-relative w-100 mt-5`}>
                            <div className={`${styles.firstCard} card box-shadow`}>
                                <div className={`row`}>
                                    <div className="col-12 col-lg-6 p-0">
                                        <div
                                            className={`d-flex flex-column h-100 align-items-center justify-content-around`}>
                                            <div className={`d-flex align-items-center w-100`}>
                                                <img src="/img/24support.png" alt="24support"/>
                                                <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold ss02`}>پشتیبانی 24 ساعته</span>
                                    <span className={`fontSizeTiny mt-2`}>
                                        پاسخگویی سریع و دقیق به سوالات و مشکلات شما
                                    </span>
                                </span>
                                            </div>
                                            <div className={`d-flex align-items-center w-100 mt-4`}>
                                                <img src="/img/why-bitblex-chart.png" alt="why-bitblex-chart"/>
                                                <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold`}>محدودیت بالای تبادل</span>
                                    <span className={`fontSizeTiny mt-2`}>
                                        امکان استفاده و یکپارچگی در پلتفرم های ios و android
                                    </span>
                                </span>
                                            </div>
                                            <div className={`d-flex align-items-center w-100 mt-4`}>
                                                <img src="/img/fast-deal.png" alt="fast-deal"/>
                                                <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold`}>انجام سریع معاملات</span>
                                    <span
                                        className={`fontSizeTiny mt-2 ss02`}>احراز هویت آسان و در کمتر از 5 دقیقه</span>
                                </span>
                                            </div>
                                            <div className={`d-flex align-items-center w-100 mt-4`}>
                                                <img src="/img/private-wallet.png" alt="private-wallet"/>
                                                <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold`}>کیف پول امن و اختصاصی</span>
                                    <span className={`fontSizeTiny mt-2`}>
                                        امکان ساخت و استفاده از ولت شخصی برای هر کاربر با امنیت و سرعت بالا
                                    </span>
                                </span>
                                            </div>
                                            <div className={`d-flex align-items-center w-100 mt-4`}>
                                                <img src="/img/best-price.png" alt="best-price"/>
                                                <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold`}>بهترین نرخ تتر</span>
                                    <span className={`fontSizeTiny mt-2`}>معامله آسان و سریع با کم ترین کارمزد</span>
                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 p-0 mt-3 mt-lg-0">
                                        <div className={`w-100 videoContainer ${style.videoContainer}`}>
                                            <ReactPlayer url='video/IMG_8278.MP4'
                                                         width='100%'
                                                         height='100%'
                                                         style={{borderRadius: 20}}
                                                         controls={true}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-5 ${styles.supportMargin}`}>
                        <div className={`text-center`}>
                            <h4 className={`fontWeightBold mb-3`}>درباره بیتبلکس</h4>
                            <span className={`fw-light`}>بیت بلکس عضو رسمی نظام صنفی رایانه ای، شورای عالی فضای مجازی و شورای عالی انفورماتیک</span>
                            <div className={`mt-3 row`}>
                                <div className={`col-6 col-md-6 col-lg-3`}>
                                    <img src={`/img/blockChaninAnstitue.png`} alt="blockChaninAnstitue"/>
                                </div>
                                <div className={`col-6 col-md-6 col-lg-3`}>
                                    <img src={`/img/technologyPark.png`} alt="technologyPark"/>
                                </div>
                                <div className={`col-6 col-md-6 col-lg-3`}>
                                    <img src={`/img/Computerized guild system.png`} alt="Computerized guild system"/>
                                </div>
                                <div className={`col-6 col-md-6 col-lg-3`}>
                                    <img src={`/img/Blockchain Association of Iran.png`}
                                         alt="Blockchain Association of Iran"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUs;
