import React, {useCallback, useEffect, useState} from 'react';
import styles from './navbar.module.scss'
import {Button, Dropdown, MenuProps, Modal, notification, Popconfirm, Space} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {clearStore, selectIsAuthenticated, useAppDispatch, useAppSelector} from "../../redux";
import {useLocation, useNavigate} from "react-router";
import Roles from "../roles/roles";
import useWindowDimensions from "../dimensions/dimensions";
import EventEmitter from "../event-emitter/event-emitter";

const Navbar = (props) => {
    const [aboutUsOpen, setAboutUsOpen] = useState(false);
    const modalStyle: React.CSSProperties = {
        marginTop: '60px',
        marginBottom: '60px',
    }
    const menu: MenuProps['items'] = [
        {label: 'درباره ما', key: 'aboutUs'},
        {label: 'تماس با ما', key: 'contactUs'}
    ];
    const [items, setBurgerMenuItems] = useState<Array<any>>([]);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const dispatch = useAppDispatch();
    const [animate, setAnimate] = useState(true);
    const [rolesOpen, setRolesOpen] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const location = useLocation();
    const width = useWindowDimensions();

    let modalWidth: number | string;
    if (width > 1400) {
        modalWidth = 960
    } else if (width > 1200) {
        modalWidth = 720
    } else if (width > 992) {
        modalWidth = 540
    } else if (width > 768) {
        modalWidth = 320
    } else {
        modalWidth = '100%'
    }

    const onCancelLogOut = () => {
        api.destroy()
    }
    const onAcceptLogoutClick = () => {
        api.destroy()
        onLogoutClick()
    }
    const onClick = (data) => {
        switch (data.key) {
            case 'home':
                navigate('/')
                break;
            case 'blog':
                window.open('https://blog.bitblex.com', '_blank', 'noopener,noreferrer');
                break;
            case 'tgBot':
                window.open('https://t.me/BitblexBot', '_blank', 'noopener,noreferrer');
                break;
            case 'aboutUs':
                setAboutUsOpen(true)
                break;
            case 'login':
                props.onLoginClick()
                break;
            case 'exchange':
                navigate('/exchange')
                break;
            case 'logout':
                const key = `open${Date.now()}`;
                const btn = <Space>
                    <Button type="link" size="small" onClick={() => onCancelLogOut()}>
                        خیر
                    </Button>
                    <Button type="primary" size="small" onClick={() => onAcceptLogoutClick()}>
                        بله
                    </Button>
                </Space>;
                api.warning({
                    message: 'خروج از حساب کاربری',
                    description: 'آیا تمایل به خروج از حساب کاربری دارید؟',
                    btn,
                    key,
                    placement: 'bottomRight'
                });
                break;
            case 'usage-help':
                navigate('/usage-help')
                break;
            case 'invite-friends':
                navigate('/invite-friends')
                break;
                case 'tlg-bot':
                navigate('/tlg-bot')
                break;
            case 'roles':
                setRolesOpen(true)
                break;
        }
    };
    const onLogoutClick = useCallback(() => {
        dispatch(clearStore());
        setAboutUsOpen(aboutUsOpen);
        EventEmitter.emit('onLogoutClick')
    }, [aboutUsOpen, dispatch])

    useEffect(() => {
        const menus: any[] = [];
        menus.push(
            {label: 'صفحه اصلی', key: 'home'},
            {label: 'راهنمای استفاده', key: 'usage-help'},
            {label: 'کسب درآمد', key: 'invite-friends'},
            {label: 'ربات تلگرام', key: 'tlg-bot'},
            {label: 'بلاگ', key: 'blog'},
        )
        if (!isAuthenticated) {
            menus.push({label: 'ورود', key: 'login'})
        } else {
            if (location.pathname !== '/exchange') {
                menus.push({label: 'معاملات', key: 'exchange'})
            }
            menus.push({label: 'خروج', key: 'logout'})
        }
        setBurgerMenuItems(menus)
    }, [isAuthenticated, location.pathname, onLogoutClick, props])
    useEffect(() => {
        const interval = setInterval(() => {
            if (animate) {
                setAnimate(false);
            } else {
                setAnimate(true);
            }
        }, 3000);
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    });

    return (
        <div className={`w-100 position-relative d-flex flex-column p-0 m-0`} style={{zIndex: 3}}>
            {contextHolder}
            <nav className={`navbar navbar-expand-lg ${styles.navbar} p-0`}>
                <div className="container-fluid h-100">
                    {(location && location.pathname && location.pathname === '/exchange') ? <>
                        <button className="navbar-brand d-flex align-items-center justify-content-center">
                            <Dropdown className="navbar-toggler"
                                      placement="bottomLeft"
                                      arrow={{pointAtCenter: true}}
                                      trigger={['click']}
                                      menu={{items, onClick}}>
                                <i className={`fas fa-bars text-white`}/>
                            </Dropdown>
                            <img className={`cursor-pointer`}
                                 src="/img/logo.png"
                                 alt="bitblex"
                                 width={`150`}
                                 height={`30`}
                                 onClick={() => navigate('/')}/>
                        </button>
                        <div className={`navbar-toggler bg-white border-radius-70`}
                             style={{height: 35, width: 35}}
                             onClick={() => props.onEllipsisButtonClick()}>
                            <div className={`d-flex justify-content-center align-items-center`}>
                                <i className="fas fa-ellipsis-v text-dark fa-sm lh-base"/>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse ms-3" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item cursor-pointer">
                                    <button className="nav-link text-white" onClick={() => navigate('/')}>
                                        صفحه اصلی
                                    </button>
                                </li>
                                <li className="nav-item cursor-pointer">
                                    <a className="nav-link text-white"
                                       href="https://blog.bitblex.com"
                                       target="_blank"
                                       rel="noreferrer">بلاگ</a>
                                </li>
                                <li className="nav-item cursor-pointer">
                                    <a className="nav-link text-white"
                                       href="https://t.me/BitblexBot"
                                       target="_blank"
                                       rel="noreferrer">ربات تلگرام</a>
                                </li>
                                <li className="nav-item cursor-pointer">
                                    <button className="nav-link text-white" onClick={() => setRolesOpen(true)}>
                                        قوانین و مقررات
                                    </button>
                                </li>
                                <li className="nav-item cursor-pointer">
                                    <button className="nav-link text-white" onClick={() => navigate('/faq')}>
                                        سوالات متداول
                                    </button>
                                </li>
                                <li className="nav-item cursor-pointer">
                                    <Dropdown placement="bottomRight"
                                              menu={{items: menu, onClick}}
                                              trigger={['hover', 'click']}
                                              arrow={{pointAtCenter: true}}>
                                        <button className="nav-link text-white" onClick={e => e.preventDefault()}>
                                            <Space>ارتباط با ما</Space>
                                        </button>
                                    </Dropdown>
                                </li>
                            </ul>
                            <div className="d-inline-flex">
                                {isAuthenticated &&
                                    <Popconfirm
                                        title="خروج از حساب کاربری"
                                        description="آیا تمایل به خروج از حساب کاربری دارید?"
                                        onConfirm={onLogoutClick}
                                        okText="بله"
                                        cancelText="خیر">
                                        <button className={`btn btn-outline-danger px-4 ms-2`}>خروج</button>
                                    </Popconfirm>
                                }
                            </div>
                        </div>
                    </> : <>
                        <button className="navbar-brand d-flex align-items-center justify-content-center p-0 h-100"
                                role="menu">
                            <img className={`cursor-pointer`}
                                 src="/img/logo.png"
                                 alt="bitblex"
                                 width={`150`}
                                 height={`30`}
                                 onClick={() => navigate('/')}/>
                        </button>
                        <Dropdown className="navbar-toggler"
                                  placement="bottomLeft"
                                  arrow={{pointAtCenter: true}}
                                  trigger={['click']}
                                  menu={{items, onClick}}>
                            <button className={`btn btnPrimary text-white`}>
                                <i className={`fas fa-bars`}></i>
                            </button>
                        </Dropdown>
                        <div className="collapse navbar-collapse h-100" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 h-100">
                                <li className={`nav-item cursor-pointer d-flex align-items-center ${location.pathname === '/' ? styles.activeNavItem : ''}`}>
                                    <button className="nav-link text-white" onClick={() => navigate('/')}>
                                        صفحه اصلی
                                    </button>
                                </li>
                                <li className={`nav-item cursor-pointer d-flex align-items-center ${location.pathname === '/usage-help' ? styles.activeNavItem : ''}`}>
                                    <Dropdown placement="bottomRight"
                                              menu={{items: [], onClick}}
                                              trigger={['hover', 'click']}
                                              dropdownRender={() => (
                                                  <div className={`${styles.help} card box-shadow text-white`}>
                                                      <div className={`card-body`}>
                                                          <div
                                                              className={`d-flex align-items-center w-100 cursor-pointer`}>
                                                              <img src="/img/helpSvg.svg" height={45} width={45}
                                                                   alt="tlgHelpSvg"/>
                                                              <span
                                                                  onClick={() => navigate('/usage-help')}
                                                                  className={`text-white d-flex flex-column ms-3`}>
                                                                  <span
                                                                      className={`fontWeightBold fa-1x lh-base`}>راهنمای استفاده</span>
                                                                  <span
                                                                      className={`fontSizeTiny text-semi-muted mt-2 ss02 fa-sm lh-base`}>
                                                                      راهنمای استفاده از بخش های مختلف
                                                                  </span>
                                                              </span>
                                                          </div>
                                                          <div
                                                              className={`d-flex align-items-center w-100 mt-4 cursor-pointer`}>
                                                              <img src="/img/faqSvg.svg" height={45} width={45}
                                                                   alt="termsAndConditions"/>
                                                              <span onClick={() => navigate('/faq')}
                                                                    className={`text-white d-flex flex-column ms-3`}>
                                                                  <span
                                                                      className={`fontWeightBold fa-1x lh-base`}>سوالات متداول</span>
                                                                  <span
                                                                      className={`fontSizeTiny text-semi-muted mt-2 fa-sm lh-base`}>
                                                                      بخش از سوالات کاربران و پاسخ آن ها
                                                                  </span>
                                                              </span>
                                                          </div>
                                                          <div
                                                              className={`d-flex align-items-center w-100 mt-4 cursor-pointer`}>
                                                              <img src="/img/navphone-support.png"
                                                                   alt="support-comment"/>
                                                              <span onClick={() => navigate('/contact-us')}
                                                                    className={`text-white d-flex flex-column ms-3`}>
                                                                  <span
                                                                      className={`fontWeightBold fa-1x lh-base`}>پشتیبانی</span>
                                                                  <span
                                                                      className={`fontSizeTiny text-semi-muted mt-2 fa-sm lh-base`}>
                                                                      ارتباط با کارشناسان پشتیبانی
                                                                  </span>
                                                              </span>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )}
                                              arrow={false}>
                                        <button className="nav-link text-white" onClick={() => navigate('/usage-help')}>
                                            <Space>
                                                راهنمای استفاده
                                                <DownOutlined/>
                                            </Space>
                                        </button>
                                    </Dropdown>
                                </li>
                                <li className={`nav-item cursor-pointer d-flex align-items-center ${location.pathname === '/invite-friends' ? styles.activeNavItem : ''}`}>
                                    <button className="nav-link text-white"
                                            onClick={() => navigate('/invite-friends')}>
                                        کسب درآمد
                                    </button>
                                </li>
                                <li className={`nav-item cursor-pointer d-flex align-items-center ${location.pathname === '/tlg-bot' ? styles.activeNavItem : ''}`}>
                                    <button className="nav-link text-white"
                                            onClick={() => navigate('/tlg-bot')}>
                                        ربات تلگرام
                                    </button>
                                </li>
                                <li className={`nav-item cursor-pointer d-flex align-items-center`}>
                                    <button className="nav-link text-white"
                                            onClick={() => onClick({key: 'blog'})}>بلاگ
                                    </button>
                                </li>
                            </ul>
                            <div className="d-inline-flex">
                                {isAuthenticated ?
                                    <>
                                        <button onClick={() => navigate('/exchange')}
                                                className={`btn btnPrimary px-4`}>معاملات
                                        </button>
                                        <Popconfirm
                                            title="خروج از حساب کاربری"
                                            description="آیا تمایل به خروج از حساب کاربری دارید؟"
                                            onConfirm={onLogoutClick}
                                            okText="بله"
                                            cancelText="خیر">
                                            <button className={`btn btn-outline-danger px-4 ms-2`}>خروج</button>
                                        </Popconfirm>
                                    </> :
                                    <button onClick={() => props.onLoginClick()}
                                            className={`btn btnPrimary px-4 fontWeightBold 
                                                ${animate && 'animate__animated animate__shakeX animate__slower'}`}>
                                        ورود<i className={`fas fa-user ms-2`}></i>
                                    </button>
                                }
                            </div>
                        </div>
                    </>}
                </div>
            </nav>
            <Modal style={modalStyle}
                   title="قوانین و مقررات"
                   centered
                   open={rolesOpen}
                   onCancel={() => setRolesOpen(false)}
                   width={modalWidth}>
                <Roles/>
            </Modal>
        </div>
    )
};

export default Navbar;
