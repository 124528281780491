import React, {useEffect, useState} from 'react';
import {Button, Collapse, Dropdown, MenuProps, notification, Pagination, Skeleton, Slider, Tag, Tooltip} from "antd";
import {getTransactions} from "../../../shared/services/services";
import {selectDictionary, useAppDispatch, useAppSelector} from "../../../redux";

const {Panel} = Collapse;

interface transactionServiceParams {
    page: number,
    limit: number,
    status?: number,
    source?: string,
    valueOrPair?: string,
    currencyOrPair?: string,
    platform?: string,
    type?: string,
}

const History = () => {
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(10);
    const dictionary = useAppSelector(selectDictionary);
    const [transactions, setTransactions] = useState([]);
    const [statusFilter, setStatusFilter] = useState(-3);
    const [sourceFilter, setSourceFilter] = useState('');
    const [currencyOrPairFilter, setCurrencyOrPairFilter] = useState('');
    const [platformFilter, setPlatformFilter] = useState('');
    const [transactionTypeFilter, setTransactionTypeFilter] = useState('');
    const [showValueOrPair, setShowValueOrPair] = useState(false);
    const [valueOrPairRange, setValueOrPairRange] = useState<[number, number]>([0, 5000]);
    const [tooltipTitle, setTooltipTitle] = useState<string>('برای کپی کلیک کنید');
    const dispatch = useAppDispatch();
    const items: MenuProps['items'] = [
        {
            label: 'نوع تراکنش',
            key: 'transactionType',
            children: [
                {key: 'deposit', label: dictionary.transaction['deposit']},
                {key: 'withdrawal', label: dictionary.transaction['withdrawal']},
                {key: 'exchange', label: dictionary.transaction['exchange']},
            ],
        },
        {
            label: 'نوع پلتفرم',
            key: 'platform',
            children: [
                {key: 'SITE', label: dictionary.platform['site']},
                {key: 'TG', label: dictionary.platform['TG']},
                {key: 'SYSTEM', label: dictionary.platform['SYSTEM']},
            ],
        },
        {
            label: 'نوع ارز',
            key: 'currencyOrPair',
            children: [
                {key: 'USDTTRC20', label: dictionary.currencies['USDTTRC20']},
                {key: 'TOMAN', label: dictionary.currencies['TOMAN']}
            ],
        },
        {
            label: 'مقدار ارز',
            key: 'valueOrPair',
            children: [{key: 'true', label: 'فیلتر براساس مقدار'}]
        },
        {
            label: 'نوع منبع',
            key: 'source',
            children: [
                {key: 'admin', label: dictionary.transaction['source']['admin']},
                {key: 'user', label: dictionary.transaction['source']['user']},
                {key: 'onlinePayment', label: dictionary.transaction['source']['onlinePayment']},
                {key: 'blockchain', label: dictionary.transaction['source']['blockchain']},
                {key: 'bankTransfer', label: dictionary.transaction['source']['bankTransfer']},
            ],
        },
        {
            label: 'وضعیت',
            key: 'status',
            children: [
                {key: -2, label: dictionary.transaction['status'][-2]},
                {key: -1, label: dictionary.transaction['status'][-1]},
                {key: 0, label: dictionary.transaction['status'][0]},
                {key: 1, label: dictionary.transaction['status'][1]}
            ],
        }
    ];
    const transactionTypes = [{name: 'deposit'}, {name: 'withdrawal'}, {name: 'exchange'}];
    const statuses = [{code: 1}, {code: 0}, {code: -1}, {code: -2}];

    useEffect(() => {
        const params: transactionServiceParams = {page, limit: 10}
        if (statusFilter !== -3) {
            params.status = statusFilter;
        }
        if (sourceFilter !== '' && sourceFilter.length > 0) {
            params.source = sourceFilter;
        }
        if (currencyOrPairFilter !== '' && currencyOrPairFilter.length > 0) {
            params.currencyOrPair = currencyOrPairFilter;
        }
        if (platformFilter !== '' && platformFilter.length > 0) {
            params.platform = platformFilter;
        }
        if (transactionTypeFilter !== '' && transactionTypeFilter.length > 0) {
            params.type = transactionTypeFilter;
        }
        if (showValueOrPair) {
            if (valueOrPairRange[1] === 5000) {
                if (valueOrPairRange[0] === 0) {
                    params.valueOrPair = '0~MAX';
                } else {
                    params.valueOrPair = `${valueOrPairRange[0]}~MAX`
                }
            } else if (valueOrPairRange[0] === 0) {
                if (valueOrPairRange[1] === 5000) {
                    params.valueOrPair = '0~MAX';
                } else {
                    params.valueOrPair = `0~${valueOrPairRange[1]}`
                }
            } else {
                params.valueOrPair = `${valueOrPairRange[0]}~${valueOrPairRange[1]}`
            }
        }
        const getTransactionFunc = async (params: transactionServiceParams) => {
            setSkeletonLoading(true)
            let response = await getTransactions(params)
            if (!!response && !!response.data) {
                setSkeletonLoading(false)
                setTotal(response.data['allItems'])
                setTransactions(response.data['items'])
            } else {
                setSkeletonLoading(false)
            }
        };
        getTransactionFunc(params).catch(console.error);
    }, [currencyOrPairFilter, dispatch, page, platformFilter, showValueOrPair, sourceFilter, statusFilter, transactionTypeFilter, valueOrPairRange])

    const onCopyToClipboardClick = (item) => {
        navigator.clipboard.writeText(item);
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
        setTooltipTitle('کپی شد')
    }
    const onOpenTooltipChange = (visible) => {
        if (!visible) {
            setTooltipTitle('برای کپی کلیک کنید')
        }
    }
    const renderTransactionTypeBadge = (historyType) => {
        let transactionBadge;
        switch (historyType) {
            case transactionTypes[0].name:
                transactionBadge = <span
                    className={`bg-success text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[0].name]}</span>
                break;
            case transactionTypes[1].name:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[1].name]}</span>
                break;
            case transactionTypes[2].name:
                transactionBadge = <span
                    className={`bg-secondary text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[2].name]}</span>
                break;
            case transactionTypes[3].name:
                transactionBadge = <span
                    className={`bg-secondary text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[3].name]}</span>
                break;
        }
        return transactionBadge;
    }
    const renderTransactionStatusBadge = (historyStatus) => {
        let transactionBadge;
        switch (historyStatus) {
            case statuses[0].code:
                transactionBadge = <span
                    className={`bg-success text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[0].code]}</span>
                break;
            case statuses[1].code:
                transactionBadge = <span
                    className={`bg-warning text-dark px-2 box-shadow`}>{dictionary.transaction['status'][statuses[1].code]}</span>
                break;
            case statuses[2].code:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[2].code]}</span>
                break;
            case statuses[3].code:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[3].code]}</span>
                break;
        }
        return transactionBadge;
    }
    const onExpandTransactionClick = (transaction) => {
        let template;
        switch (transaction.type) {
            case transactionTypes[0].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز: </span>
                            <span className={`fontSizeMini`}>{dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار: </span>
                            <span
                                className={`fontSizeMini ss02`}>{Number(transaction['value']).toLocaleString('US')}</span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span
                                className={`fontSizeMini ss02`}>{(!transaction.description || (!!transaction.description && transaction.description.length === 0)) ? '-' : transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini `}>
                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                    <span onClick={() => onCopyToClipboardClick(transaction['_id'])}
                                          className={`text-end mt-2 fontSizeTiny cursor-pointer secondaryColor`}>
                                        {transaction['_id']}
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه خارجی: </span>
                            <span className={`fontSizeMini `}>{transaction['txId']}</span>
                        </div>
                    </div>
                </div>
                break;
            case transactionTypes[1].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار: </span>
                            <span
                                className={`fontSizeMini ss02`}>{Number(transaction['value']).toLocaleString('US')}</span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span
                                className={`fontSizeMini ss02`}>{(!transaction.description || (!!transaction.description && transaction.description.length === 0)) ? '-' : transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini `}>
                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                    <span onClick={() => onCopyToClipboardClick(transaction['_id'])}
                                          className={`text-end mt-2 fontSizeTiny cursor-pointer secondaryColor`}>
                                        {transaction['_id']}
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه خارجی: </span>
                            <span className={`fontSizeMini `}>{transaction['txId']}</span>
                        </div>
                    </div>
                </div>
                break;
            case transactionTypes[2].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-cash-register me-2`}></i>
                            <span className={`fontSizeNormal`}>نرخ تبدیل: </span>
                            <span
                                className={`fontSizeMini `}>{Number(transaction['exchangeRate']).toLocaleString('US')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز پرداختی: </span>
                            <span className={`fontSizeMini`}>
                                <span className={``}>{transaction['pairValue']}</span>
                                {dictionary.currencies[transaction['pairCurrency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز دریافتی: </span>
                            <span
                                className={`fontSizeMini`}><span
                                className={`ss02`}>{Number(transaction['value']).toLocaleString('US')}</span> {dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار کارمزد: </span>
                            <span className={`fontSizeMini`}>
                                <span
                                    className={``}>{transaction['fee']['value']}</span> {dictionary.currencies[transaction['fee']['currency']]}
                            </span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span
                                className={`fontSizeMini ss02`}>{(!transaction.description || (!!transaction.description && transaction.description.length === 0)) ? '-' : transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini `}>
                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                    <span onClick={() => onCopyToClipboardClick(transaction['_id'])}
                                          className={`text-end mt-2 fontSizeTiny cursor-pointer secondaryColor`}>
                                        {transaction['_id']}
                                    </span>
                                </Tooltip>
                            </span>
                        </div>
                    </div>
                </div>
                break;
        }
        return template;
    }
    const onAfterChange = (value: [number, number]) => {
        setValueOrPairRange(value);
    };
    const onClick: MenuProps['onClick'] = (data) => {
        switch (data.keyPath[1]) {
            case 'transactionType':
                switch (data.key) {
                    case 'deposit':
                        if (transactionTypeFilter === 'deposit') {
                            setTransactionTypeFilter('')
                        } else {
                            setTransactionTypeFilter('deposit')
                        }
                        break;
                    case 'withdrawal':
                        if (transactionTypeFilter === 'withdrawal') {
                            setTransactionTypeFilter('')
                        } else {
                            setTransactionTypeFilter('withdrawal')
                        }
                        break;
                    case 'exchange':
                        if (transactionTypeFilter === 'exchange') {
                            setTransactionTypeFilter('')
                        } else {
                            setTransactionTypeFilter('exchange')
                        }
                        break;
                }
                break;
            case 'platform':
                switch (data.key) {
                    case 'SITE':
                        if (platformFilter === 'SITE') {
                            setPlatformFilter('')
                        } else {
                            setPlatformFilter('SITE')
                        }
                        break;
                    case 'TG':
                        if (platformFilter === 'TG') {
                            setPlatformFilter('')
                        } else {
                            setPlatformFilter('TG')
                        }
                        break;
                    case 'SYSTEM':
                        if (platformFilter === 'SYSTEM') {
                            setPlatformFilter('')
                        } else {
                            setPlatformFilter('SYSTEM')
                        }
                        break;
                }
                break;
            case 'currencyOrPair':
                switch (data.key) {
                    case 'USDTTRC20':
                        if (currencyOrPairFilter === 'USDTTRC20') {
                            setCurrencyOrPairFilter('')
                        } else {
                            setCurrencyOrPairFilter('USDTTRC20')
                        }
                        break;
                    case 'TOMAN':
                        if (currencyOrPairFilter === 'TOMAN') {
                            setCurrencyOrPairFilter('')
                        } else {
                            setCurrencyOrPairFilter('TOMAN')
                        }
                        break;
                }
                break;
            case 'valueOrPair':
                if (showValueOrPair) {
                    setShowValueOrPair(false)
                } else {
                    setShowValueOrPair(true)
                }
                break;
            case 'source':
                switch (data.key) {
                    case 'admin':
                        if (sourceFilter === 'admin') {
                            setSourceFilter('')
                        } else {
                            setSourceFilter('admin')
                        }
                        break;
                    case 'user':
                        if (sourceFilter === 'user') {
                            setSourceFilter('')
                        } else {
                            setSourceFilter('user')
                        }
                        break;
                    case 'onlinePayment':
                        if (sourceFilter === 'onlinePayment') {
                            setSourceFilter('')
                        } else {
                            setSourceFilter('onlinePayment')
                        }
                        break;
                    case 'blockchain':
                        if (sourceFilter === 'blockchain') {
                            setSourceFilter('')
                        } else {
                            setSourceFilter('blockchain')
                        }
                        break;
                    case 'bankTransfer':
                        if (sourceFilter === 'bankTransfer') {
                            setSourceFilter('')
                        } else {
                            setSourceFilter('bankTransfer')
                        }
                        break;
                }
                break;
            case 'status':
                switch (parseInt(data.key)) {
                    case -2:
                        if (statusFilter === -2) {
                            setStatusFilter(-3)
                        } else {
                            setStatusFilter(-2)
                        }
                        break;
                    case -1:
                        if (statusFilter === -1) {
                            setStatusFilter(-3)
                        } else {
                            setStatusFilter(-1)
                        }
                        break;
                    case 0:
                        if (statusFilter === 0) {
                            setStatusFilter(-3)
                        } else {
                            setStatusFilter(0)
                        }
                        break;
                    case 1:
                        if (statusFilter === 1) {
                            setStatusFilter(-3)
                        } else {
                            setStatusFilter(1)
                        }
                        break;
                }
                break;
        }
    };

    return (
        <div className={`h-100 w-100 mt-5 px-3`}>
            <h3 className={`secondaryColor fontWeightBold text-center`}>تاریخچه تراکنش ها</h3>
            <div className={`d-flex mt-3 align-items-center`}>
                <Dropdown className={`w-50`}
                          placement="bottomLeft"
                          arrow={{pointAtCenter: true}}
                          trigger={['click']}
                          menu={{items, onClick}}>
                    <Button className={`btn btnSecondary d-flex align-items-center justify-content-center w-50`}>
                        <i className="fas fa-filter me-2"></i> فیلتر
                    </Button>
                </Dropdown>
                <div className={`d-flex align-items-center justify-content-center flex-column w-100`}>
                    <Pagination className={`ss02 w-100`}
                                defaultCurrent={page}
                                total={total}
                                responsive
                                showLessItems
                                onChange={(e) => setPage(e)}/>
                </div>
            </div>
            <div className={`w-100 mt-3`}>
                {statusFilter !== -3 && <Tag closable
                                             color="#10d078"
                                             onClose={() => setStatusFilter(-3)}>
                    <span className={`cursor-pointer`} onClick={() => setStatusFilter(-3)}>
                        {dictionary.transaction['status'][statusFilter]}
                    </span>
                </Tag>}
                {(sourceFilter !== '' && sourceFilter.length > 0) && <Tag closable
                                                                          color="#10d078"
                                                                          onClose={() => setSourceFilter('')}>
                    <span className={`cursor-pointer`} onClick={() => setSourceFilter('')}>
                        {dictionary.transaction['source'][sourceFilter]}
                    </span>
                </Tag>}
                {(currencyOrPairFilter !== '' && currencyOrPairFilter.length > 0) && <Tag closable
                                                                                          color="#10d078"
                                                                                          onClose={() => setCurrencyOrPairFilter('')}>
                    <span className={`cursor-pointer`} onClick={() => setCurrencyOrPairFilter('')}>
                        {dictionary.currencies[currencyOrPairFilter]}
                    </span>
                </Tag>}
                {(platformFilter !== '' && platformFilter.length > 0) && <Tag closable
                                                                              color="#10d078"
                                                                              onClose={() => setPlatformFilter('')}>
                    <span className={`cursor-pointer`} onClick={() => setPlatformFilter('')}>
                        {dictionary.platform[platformFilter.toLowerCase()]}
                    </span>
                </Tag>}
                {(transactionTypeFilter !== '' && transactionTypeFilter.length > 0) && <Tag closable
                                                                                            color="#10d078"
                                                                                            onClose={() => setTransactionTypeFilter('')}>
                    <span className={`cursor-pointer`} onClick={() => setTransactionTypeFilter('')}>
                        {dictionary.transaction[transactionTypeFilter]}
                    </span>
                </Tag>}
                {showValueOrPair && <Tag closable
                                         className={`w-100 d-flex align-items-center`}
                                         color="#10d078"
                                         onClose={() => setShowValueOrPair(false)}>
                    <span className={`me-2`}>مقدار ارز</span>
                    <Slider style={{width: '95%'}} className={`d-inline-flex`} min={0} max={5000}
                            defaultValue={valueOrPairRange} range onAfterChange={onAfterChange}/>
                </Tag>}
            </div>
            {!skeletonLoading ? <Collapse accordion className={`bgTertiaryColor box-shadow mt-3 mb-3`}>
                {transactions.map((transaction, key) => {
                    return <Panel header={<div key={key} className={`mt-2 cursor-pointer`}>
                        <div className={`d-flex align-items-center justify-content-between`}>
                            {renderTransactionTypeBadge(transaction['type'])}
                            {renderTransactionStatusBadge(transaction['status'])}
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`d-flex align-items-center justify-content-between text-white`}>
                                    <span className={``}>
                                        <span
                                            className={`ss02`}>{Number(transaction['value']).toLocaleString('US')}</span>{' '}{dictionary.currencies[transaction['currency']]}
                                    </span>
                            <span className={``}>
                                        {new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}
                                    </span>
                        </div>
                    </div>} key={key}>
                        {onExpandTransactionClick(transaction)}
                        <span></span>
                    </Panel>
                })}
            </Collapse> : <Skeleton active loading={true}></Skeleton>}
        </div>
    );
};

export default History;