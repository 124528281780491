import React, {useEffect, useState} from 'react';
import {Collapse, Input} from "antd";
import Navbar from "../../shared/navbar/navbar";
import styles from "./FAQ.module.scss";
import EventEmitter from "../../shared/event-emitter/event-emitter";

const {Panel} = Collapse;

const Faq = () => {
    const [searchValue, setSearchValue] = useState<string>('')
    const faqArray = [
        {
            question: 'شما کی هستید؟',
            answer: 'ما یک استارت آپ نوپا هستیم که در ضمینه پرداخت های دیجیتال و خدمات وابسته به اون شروع به کار کردیم؛ در فاز اول سامانه، ما،در حال حاضر بر روی وب سایت و ربات تمام اتوماتیک تلگرامی ارائه خدمات می کنیم.'
        },
        {
            question: 'از کجا باید شروع کنم؟',
            answer: 'شما می توانید با زدن دکمه ورود در وب سایت ما و یا دکمه شروع در ربات تلگرامی ما، فرایند پیوستن خود را به خانواده ما شروع کنید.'
        },
        {
            question: 'شما از چه ارزهای دیجیتالی پشتیبانی می کنید؟',
            answer: 'در حال حاضر در فاز اول تمرکز ما بر ایجاد بستر ساده و امن برای معامله ی ارز دلار دیجیتال یا همان تتر هست و با توسعه سامانه ما ارزهای دیجیتال بیشتر و خدمات وسیع تری ارائه خواهیم داد.'
        },
        {
            question: 'اگر در ربات یا وب سایت عضو بشم و احراز کنم، آیا می توانم از تمام بستر خدمات شما استفاده کنم؟',
            answer: 'بله ، سیستم به نحوی طراحی شده که شما بتوانید به احراز بر بستر یکی از محصولات ما، از تمام محصولات استفاده کنید.'
        },
        {
            question: 'من عضو شدم، جرا نمی تونم تتر بخرم؟',
            answer: 'بر اساس دستور عمل پلیس فتا، شما برای خرید نیازمند احراز هوئیت هستید، بنابراین پس از ورود به پنل معاملات، دکمه تکمیل احراز هویت را زده و مراحل آن را کامل کنید ، تا از خدمات کامل ما استفاده کنید.'
        },
        {
            question: 'احراز هویت چیست؟',
            answer: 'به مجموعه فرایتد هایی که طی آن کاربر پلتفرم معاملاتی ما، هویت خود را اعلام و به ما معرفی می کند، احراز هویت گفته می شود؛ این امر بر اساس استاندارد ابلاغی توسط پلیس فتا جمهوری اسلامی اجرا خواهد شد.'
        },
        {
            question: 'چه مدارکی برای احراز هویت لازم دارم؟',
            answer: 'شروع ثبت نام با استفاده از شماره تلفن همراه معتبر امکان پذیر خواهد بود. در ادامه با توجه به سطح کاربری مورد نیاز، کاربر باید اقدام به تکمیل اطلاعات پروفایل خود نماید. در سطح یک، کاربر ملزم به ارائه شماره تلفن همراه، اسکن رنگی کارت ملی، پاسپورت یا شناسنامه جدید، شماره کارت بانکی، حساب و شبا به نام خود کاربر و ویدیو تایید شرایط و قوانین استفاده از سامانه می باشد'
        },
        {
            question: 'چرا باید اطلاعات شخصی خود را برای خرید و فروش ارائه دهم؟',
            answer: 'بیت بلکس، همگام با مقررات جهانی و داخلی، ملزم به احراز هویت کاربران محترم جهت ارائه خدمات خرید و فروش ارزهای دیجیتال خواهد بود. تیم بیت بلکس متعهد می باشد که این اطلاعات را به صورت کاملاً محرمانه و به منظور حفظ امنیت سرمایه ی کاربران محترم، دریافت و محفوظ بدارد؛ این اطلاعات به صورت امن ذخیره شده و فقط بر اساس حکم قانونی در اختبار مراجع ذیربط قرار خواهد گرفت.'
        },
        {
            question: 'محدودیت خرید و یا فروش شما چقدر است؟',
            answer: 'ما محدودیت حداکثری برای خرید و یا فروش هیچ کاربری نداریم، اما شما حداقل می توانید پانصد هزار تومان یا ده تتر خرید بفرمایید.'
        },
        {
            question: 'آیا موجودی ریالی و تتری من در نزد شما امن است؟',
            answer: 'بله، برای ایجاد حداکثر امنیت هر کاربر دارای شناسه و آدرس کیف پول مخصوص به خود خواهد بود، که بعد از خرید ویا فروش موجودی شما به کیف پول های امن ما منتقل خواهد شد.'
        },
        {
            question: 'چطور می تونم به شما اطمینان کنم؟',
            answer: 'مانند هر کسب و کار جدید، این اعتماد به صورت دو طرفه باید شکل گیرد، اما شما می توانید با بررسی سوابق ما در بسترهای مختلف ارائه خدمات ما و همچنین بررسی کامل اطلاعات درگاه پرداخت ما، از امنیت سرمایه خود مطمئن شوید.'
        },
        {
            question: 'زمان بندی تسویه شما به چه صورت است؟',
            answer: 'از بابت تسویه حساب ریالی ، ما بر اساس سیکل تسویه حساب سامانه شاپرک بانک مرکزی، اقدام به تسویه وجوه شما خواهیم کرد، مگر آنکه مبلغ شما خارج از سطح تراکنش مشخص شده توسط بانک مرکزی جمهوری اسلامی باشد، که در آن صورت الباقی تسویه شما در روز بعد انجام خواهد شد و از بابت ارز دیجیتال، سیستم خودکار ما در روزهای کاری هر نیم ساعت یک بار و در روزهای تعطیل هر یک ساعت و نیم یک بار اقدام به تسویه در خواست های واریز ارز دیجیتال خواهد کرد.'
        }
    ]
    const [faq, setFaq] = useState<any[]>(faqArray)

    useEffect(() => {
        window.addEventListener("goftino_ready", function () {
            const goftinoWidget = window.document.getElementById("goftino_w");
            goftinoWidget?.remove();
        });
    }, []);
    useEffect(() => {
        if (searchValue.length === 0) {
            setFaq(faqArray)
        } else {
            setFaq(faqArray.filter(item => item.answer.includes(searchValue) || item.question.includes(searchValue)))
        }
        // eslint-disable-next-line
    }, [searchValue]);

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }
    const onChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`${styles.gradiant2}`}></div>
            <div className={`${styles.centerGradiant}`}></div>
            <div className={`container d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div className={`${styles.container} container`}>
                    <div className={`row lh-lg fw-light`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-6 d-flex justify-content-center flex-column ss02 fontSizeSmall`}>
                            <h4 className={`fontWeightBold`}>سوالات متداول</h4>
                            <div className={`mt-3`}>در این بخش می توانید پاسخ سوالات خود را پیدا نمایید</div>
                            <div>می توانید موضوع مد نظر خود را جستجو نمایید</div>
                            <div className={`${styles.startNow} mt-2`}>
                                <Input placeholder={`جستجو...`}
                                       className={`border-o text-white`}
                                       bordered={false}
                                       value={searchValue}
                                       onChange={onChangeSearchValue}
                                />
                            </div>
                        </div>
                        <div className={`col-xl-6 d-none d-xl-block position-relative`}>
                            <img src="/img/faqCoin.png" alt="faqCoin" className={`${styles.img1}`}/>
                        </div>
                    </div>
                    {(searchValue?.length > 0) ?
                        <>
                            <button className={`btn btn-danger py-1 fontSizeSmall cursor-pointer me-2`}
                                    onClick={() => onChangeSearchValue({target: {value: ''}})}>
                                <i className={`fas fa-times-circle fontSizeTiny`}></i>
                            </button>
                            <h5 className={`fontWeightBold d-inline-block mt-2`}>فیلتر: {searchValue}</h5>
                        </> : <h5 className={`fontWeightBold d-inline-block mt-2`}>دسترسی سریع</h5>}
                    <Collapse accordion className={`bg-transparent w-100 box-shadow mt-3 mb-3 border-0`}>
                        {faq.map((faq, key) => {
                            return <Panel
                                className={`${styles.collapse} faqPanel`}
                                header={<div key={key} className={`cursor-pointer text-white`}>{faq.question}</div>}
                                key={key}>
                                <span className={`text-white`}>{faq.answer}</span>
                            </Panel>
                        })}
                    </Collapse>
                </div>
            </div>
        </>
    )
}

export default Faq;