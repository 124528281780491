import React from 'react';
import style from './your-help.module.scss';
import {useNavigate} from "react-router";
import ReactPlayer from 'react-player'

const YourHelp = () => {
    const navigate = useNavigate();

    const navigator = (path) => {
        navigate(path)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    return (
        <div className={`row ${style.mainContainer}`}>
            <div className={`col-md-6 d-flex justify-content-center flex-column`}>
                <h2 className={`fontWeightBold`}>راهنمای شما</h2>
                <span className={`fontSizeSmall text-semi-muted lh-sm`}>
                    پشتیبانی و رفع نیاز های شما، وظیفه ماست
                </span>
                <span className={`fontSizeNormal text-semi-muted lh-base mt-4`}>
                    در وب سایت و ربات تلگرام، ویدیوهای آموزشی، سوالات متداول و بخش راهنما در اختیار شماست
                </span>
                <span className={`mt-4`}>
                    <button className={`btn btn-primary py-1 fontSizeSmall`}
                            onClick={() => navigator('/usage-help')}>
                        راهنمای سایت
                        <i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                    </button>
                </span>
            </div>
            <div className={`col-md-6  mt-4 mt-md-0`}>
                <div className={`w-100 videoContainer ${style.videoContainer}`}>
                    <ReactPlayer url='video/help.mp4'
                                 width='100%'
                                 height='100%'
                                 style={{borderRadius: 20}}
                                 controls={true}/>
                </div>
            </div>
        </div>
    );
};

export default YourHelp;
