import {Action, combineReducers, configureStore, ThunkAction,} from '@reduxjs/toolkit';
import {
    currenciesTopicReducer,
    dictionaryReducer,
    festivalTopicReducer,
    joinRefReducer,
    otpCodeReducer,
    showLoadingReducer,
    tokenReducer,
    unreadMessagesReducer,
    userMobileReducer,
    userProfileReducer
} from "./reducers";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    version: 1,
    storage
}

const reducer = combineReducers({
    token: tokenReducer,
    userMobile: userMobileReducer,
    otpCode: otpCodeReducer,
    joinRef: joinRefReducer,
    userProfile: userProfileReducer,
    showLoading: showLoadingReducer,
    dictionary: dictionaryReducer,
    currenciesTopic: currenciesTopicReducer,
    festivalTopic: festivalTopicReducer,
    unreadMessages: unreadMessagesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'authentication/clearStore') {
        state = undefined;
    }
    return reducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    })
});

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;