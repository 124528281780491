import React, {useState} from 'react';
import {notification, Tooltip} from "antd";

const BankTransferDepositToman = (props) => {
    let {bankTransferDepositAccount, tomanDepositAmount} = props;
    tomanDepositAmount = Number(tomanDepositAmount).toLocaleString('US')
    const [tooltipTitle, setTooltipTitle] = useState<string>('برای کپی کلیک کنید');

    const onCopyToClipboardClick = (item) => {
        navigator.clipboard.writeText(item);
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
        setTooltipTitle('کپی شد')
    }
    const onOpenTooltipChange = (visible) => {
        if (!visible) {
            setTooltipTitle('برای کپی کلیک کنید')
        }
    }

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column mt-5 px-3 h-100`}>
                <div className={`w-100 mt-3`}>
                    <h3 className={`primaryColor fontWeightBold text-center`}>اطلاعات واریز</h3>
                    <div className={`w-100 ss02`}>
                        مبلغ وارد شده بیش از سقف پرداخت آنلاین می‌باشد لذا لطفا از طریق بانک، موبایل‌بانک، خودپرداز یا
                        ...
                        مبلغ {tomanDepositAmount} تومان را به حساب ذیل واریز فرمایید:
                    </div>
                    <div className={`w-100 p-2 mt-3`}>
                        <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                            <div className={`p-2 text-center`}>
                                به نام: <span className={``}>{' '}{bankTransferDepositAccount['owner']}</span>
                            </div>
                        </div>
                        <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                            <div className={`p-2 text-center`}>
                                بانک: <span className={``}>{' '}{bankTransferDepositAccount['bank']}</span>
                            </div>
                        </div>
                        <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                            <div className={`p-2 text-center`}>
                                <Tooltip color={'#10d078'} title={tooltipTitle}
                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                    <span className={`cursor-pointer secondaryColor`}>شماره کارت:{' '}
                                        <span className={`pt-1 `}
                                              onClick={() => onCopyToClipboardClick(bankTransferDepositAccount['cardNo'])}>{bankTransferDepositAccount['cardNo']}</span>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                            <div className={`p-2 text-center`}>
                                <Tooltip color={'#10d078'} title={tooltipTitle}
                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                    <span className={`cursor-pointer secondaryColor`}>شماره شبا:{' '}
                                        <span className={`pt-1 `}
                                              onClick={() => onCopyToClipboardClick(bankTransferDepositAccount['shebaNo'])}>{bankTransferDepositAccount['shebaNo']}</span>
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className={`w-100 mt-3`}>
                        <i className={`fas fa-clipboard text-white fa-lg me-2 `}></i>
                        با لمس هر قسمت، متن آن کپی می‌شود
                    </div>
                </div>
                <div className={`w-100 mb-4`}>
                    <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                            onClick={() => props.onPayClick()}>
                        <i className={`fas fa-check-circle me-2`}></i>
                        واریز شد
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BankTransferDepositToman;