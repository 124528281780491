import React, {useEffect, useState} from 'react';
import type {MenuProps} from 'antd';
import {Button, Collapse, Dropdown, Pagination, Skeleton, Tag} from "antd";
import {getMessages, markMessageAsRead} from "../../../shared/services/services";
import {selectToken, selectUnreadMessage, unreadMessagesAction, useAppDispatch, useAppSelector} from "../../../redux";
import {IMessage} from "../../../shared/interfacesAndTypes/interfaces";

const {Panel} = Collapse;

interface messagesServiceParams {
    page: number,
    limit: number,
    read?: boolean,
    type?: string,
}

const Messages = () => {
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [messages, setMessages] = useState<Array<IMessage>>([]);
    const [page, setPage] = useState(1);
    const [limit,] = useState(10);
    const [total, setTotal] = useState(10);
    const token = useAppSelector(selectToken);
    const [messageReadFilter, setMessageReadFilter] = useState(-1);
    const [messageTypeFilter, setMessageTypeFilter] = useState('');
    const unreadMessages = useAppSelector(selectUnreadMessage);
    const dispatch = useAppDispatch();

    const items: MenuProps['items'] = [
        {
            label: 'وضعیت پیام',
            key: 'read',
            children: [
                {key: 'true', label: 'خوانده شده'},
                {key: 'false', label: 'خوانده نشده'},
            ],
        },
        {
            label: 'نوع پیام',
            key: 'type',
            children: [
                {key: 'private', label: 'خصوصی'},
                {key: 'bulk', label: 'عمومی'},
            ],
        },
    ];
    const renderMessageTypeBadge = (messageType) => {
        if (messageType === 'private') {
            return <span className={`text-white px-2`}>
                <i className="fas fa-lock text-danger me-1"></i>خصوصی
            </span>
        } else {
            return <span className={`text-white px-2`}>
                <i className="fas fa-lock-open text-success me-1"></i>عمومی
            </span>
        }
    }
    const renderMessageReadBadge = (messageRead) => {
        if (messageRead) {
            return <span className={`bg-success text-white px-2 box-shadow`}>
                <i className="fas fa-check-double me-1"></i>خوانده شده
            </span>
        }
        return <span className={`bg-danger text-white px-2 box-shadow`}>
                <i className="fas fa-check me-1"></i>خوانده نشده
            </span>
    }
    const markAsReadFunc = (e) => {
        if (!!e && !!messages[e] && !messages[e]['read']) {
            markMessageAsRead(messages[e]['_id'], token).then(() => {
                let newMessages = [...messages];
                newMessages[e].read = !newMessages[e].read;
                unreadMessages > 0 && dispatch(unreadMessagesAction(unreadMessages - 1));
                setMessages(newMessages)
            })
        }
    }
    const onClick = (data) => {
        switch (data.keyPath[1]) {
            case 'read':
                switch (data.key) {
                    case 'true':
                        if (messageReadFilter === 1) {
                            setMessageReadFilter(-1)
                        } else {
                            setMessageReadFilter(1)
                        }
                        break;
                    case 'false':
                        if (messageReadFilter === 0) {
                            setMessageReadFilter(-1)
                        } else {
                            setMessageReadFilter(0)
                        }
                        break;
                }
                break;
            case 'type':
                switch (data.key) {
                    case 'private':
                        if (messageTypeFilter === 'private') {
                            setMessageTypeFilter('')
                        } else {
                            setMessageTypeFilter('private')
                        }
                        break;
                    case 'bulk':
                        if (messageTypeFilter === 'bulk') {
                            setMessageTypeFilter('')
                        } else {
                            setMessageTypeFilter('bulk')
                        }
                        break;
                }
                break;
        }
    };
    useEffect(() => {
        const params: messagesServiceParams = {page, limit}
        if (messageReadFilter !== -1) {
            params.read = messageReadFilter === 1;
        }
        if (messageTypeFilter !== '' && messageTypeFilter.length > 0) {
            params.type = messageTypeFilter;
        }
        const getMessagesFunc = async (params: messagesServiceParams) => {
            setSkeletonLoading(true)
            let response = await getMessages(params)
            if (!!response && !!response.data) {
                setSkeletonLoading(false)
                setTotal(response.data['allItems'])
                setMessages(response.data['items'])
            } else {
                setSkeletonLoading(false)
            }
        };
        getMessagesFunc(params).catch(console.error);
    }, [limit, messageReadFilter, messageTypeFilter, page])

    return (
        <div className={`h-100 w-100 mt-5 px-3`}>
            <h3 className={`secondaryColor fontWeightBold text-center`}>پیام ها</h3>
            <div className={`d-flex mt-3 align-items-center`}>
                <Dropdown className={`w-50`}
                          placement="bottomLeft"
                          arrow={{pointAtCenter: true}}
                          trigger={['click']}
                          menu={{items, onClick}}>
                    <Button className={`btn btnSecondary d-flex align-items-center justify-content-center w-50`}>
                        <i className="fas fa-filter me-2"></i> فیلتر
                    </Button>
                </Dropdown>
                <div className={`d-flex align-items-center justify-content-center flex-column w-100`}>
                    <Pagination className={`ss02 w-100`}
                                defaultCurrent={page}
                                total={total}
                                responsive
                                showLessItems
                                onChange={(e) => setPage(e)}/>
                </div>
            </div>
            <div className={`w-100 mt-3`}>
                {messageReadFilter !== -1 && <Tag closable
                                                  color="#10d078"
                                                  onClose={() => setMessageReadFilter(-1)}>
                    <span className={`cursor-pointer`} onClick={() => setMessageReadFilter(-1)}>
                        {messageReadFilter === 1 ? 'خوانده شده' : 'خوانده نشده'}
                    </span>
                </Tag>}
                {(messageTypeFilter !== '' && messageTypeFilter.length > 0) && <Tag closable
                                                                                    color="#10d078"
                                                                                    onClose={() => setMessageTypeFilter('')}>
                    <span className={`cursor-pointer`} onClick={() => setMessageTypeFilter('')}>
                        {messageTypeFilter === 'private' ? 'خصوصی' : 'عمومی'}
                    </span>
                </Tag>}
            </div>
            {!skeletonLoading ?
                <Collapse onChange={(e) => markAsReadFunc(e)} accordion
                          className={`bgTertiaryColor box-shadow mt-3 mb-3`}>
                    {messages.map((message, key) => {
                        return <Panel header={
                            <div key={key} className={`mt-2 cursor-pointer`}>
                                <div className={`d-flex align-items-center justify-content-between`}>
                                    <span className={`text-white px-2`}>{message['title']}</span>
                                    <span className={`text-white px-2`}>
                                        {
                                            new Date(message.createdAt).toLocaleString('fa-IR').split(',')[1]
                                            + ' ' +
                                            new Date(message.createdAt).toLocaleString('fa-IR').split(',')[0]
                                        }
                                    </span>
                                </div>
                                <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                                <div className={`d-flex align-items-center justify-content-between text-white`}>
                                    {renderMessageTypeBadge(message['type'])}
                                    {renderMessageReadBadge(message['read'])}
                                </div>
                            </div>} key={key}>

                            {/*<div className={`d-flex flex-column align-items-center card-body justify-content-center`}>*/}
                            {/*{(!!message.imageUrl && message.imageUrl.length > 0) &&*/}
                            {/*    <Image src={message.imageUrl}*/}
                            {/*           alt={message['body']}*/}
                            {/*           width="100%"*/}
                            {/*           height="100%"/>}*/}
                            <div className={`ss02 text-white`}>{message['body']}</div>
                            {/*</div>*/}
                        </Panel>
                    })}
                </Collapse> : <Skeleton active loading={true}></Skeleton>}

        </div>
    );
};

export default Messages;