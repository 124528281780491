import React from 'react';
import style from './home-invite-friend.module.scss';
import {useNavigate} from "react-router";

const HomeInviteFriend = () => {
    const navigate = useNavigate();

    return (
        <div className={`row ${style.mainContainer} p-0 cursor-pointer`} onClick={() => navigate('/invite-friends')}>
            <div className={`col-12 col-md-12 col-lg-8 d-flex justify-content-center flex-column p-3 p-md-5`}>
                <h4>شما هم با دعوت از دوستان در کسب درامد از صرافی شریک شوید !</h4>
                <span className={`fontSizeSmall lh-base`}>
                    به راحتی روزانه از تراکنش‌های دوستان مستقیم و غیرمستقیم خود بصورت مادام العمر، کسب درامد کنید
                </span>
            </div>
            <div
                className="col-12 col-md-12 col-lg-4 p-0 position-relative d-flex align-items-center justify-content-center">
                <img src="/img/home-invite.png" className={`${style.img}`} alt="inviteFriend"/>
            </div>
        </div>
    );
};

export default HomeInviteFriend;
