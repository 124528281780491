import React, {useState} from 'react';
import {Button, Form, InputNumber} from "antd";
import {loginService, sendValidationCodeService} from "../../../shared/services/services";
import {CountdownCircleTimer} from 'react-countdown-circle-timer';
import {
    otpCodeAction,
    selectUserMobile,
    showLoadingAction,
    tokenAction,
    useAppDispatch,
    useAppSelector
} from "../../../redux";
import {otpCodeRegex} from "../../../shared/regex-and-validations";
import {useNavigate} from "react-router";

const ValidateOtpCode = (props) => {
    const [sendOtpAgain, setSendOtpAgain] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userMobile = useAppSelector(selectUserMobile);

    const onLoginClick = async (form) => {
        dispatch(showLoadingAction(true))
        const data = await loginService({phoneNo: userMobile, code: form.code});
        if (!!data && data.statusCode === 406) {
            setSendOtpAgain(true);
            dispatch(showLoadingAction(false))
            return;
        }
        if (!!data && data.statusCode === 200) {
            if (!!data && !!data.data && data.data['next'] === 'signup') {
                dispatch(otpCodeAction(form.code))
                dispatch(showLoadingAction(false))
                props.openSignUp();
            } else if (!!data && !!data.data && data.data['next'] === 'profile') {
                dispatch(tokenAction(data.data['accessToken']));
                dispatch(showLoadingAction(false))
                await navigate('/exchange');
            }
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onSendOtpAgainClick = async () => {
        dispatch(showLoadingAction(true))
        const res = await sendValidationCodeService({mobile: userMobile})
        if (!!res && res.statusCode === 200) {
            setSendOtpAgain(false);
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onFormFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }
    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            return <div className="timer">مهلت ارسال کد به پایان رسید!</div>;
        }
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="text">زمان باقی مانده</div>
                <div className="fa-2x ss02">{remainingTime}</div>
                <div className="text">ثانیه</div>
            </div>
        );
    };
    const onCompleteTimer = () => {
        setSendOtpAgain(true)
        return {shouldRepeat: true}
    }

    return (
        <>
            {sendOtpAgain ?
                <>
                    <div className="timer">مهلت ارسال کد به پایان رسید!</div>
                    <Button type="primary" onClick={() => onSendOtpAgainClick()}
                            className={`btn btnOutlinePrimary px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3 mt-3`}>
                        درخواست مجدد کد
                    </Button>
                </> :
                <>
                    <CountdownCircleTimer
                        duration={180}
                        colors={["#10d078", "#0d6efd", "#dc3545"]}
                        colorsTime={[180, 90, 0]}
                        isPlaying
                        onComplete={() => onCompleteTimer()}>
                        {renderTime}
                    </CountdownCircleTimer>
                    <Form
                        className={`mt-5 w-100`}
                        name="validationSmsComponent"
                        labelCol={{span: 25}}
                        wrapperCol={{span: 25}}
                        onFinish={onLoginClick}
                        onFinishFailed={onFormFailed}
                        autoComplete="off">
                        <h6 className={`text-white ss02`}>کد پیامک شده به شماره <span
                            className={`ss02`}>{userMobile}</span> را وارد کنید</h6>
                        <Form.Item
                            label=""
                            name="code"
                            rules={[
                                {required: true, message: 'لطفا کد پیامک شده را وارد کنید!'},
                                {pattern: otpCodeRegex, message: "لطفا کد را به صورت صحیح وارد کنید"}
                            ]}>
                            <InputNumber bordered={false}
                                         controls={false}
                                         maxLength={4}
                                         className={`inputs border-radius-7 text-dark w-100 ss02`}/>
                        </Form.Item>
                        <Form.Item wrapperCol={{span: 25}}>
                            <Button type="primary" htmlType="submit"
                                    className={`btn btnPrimary px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3`}>
                                ورود یا ثبت نام
                            </Button>
                        </Form.Item>
                    </Form>
                    <Button type="primary" htmlType="submit" onClick={() => props.onBackClick()}
                            className={`btn btnOutlinePrimary px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3`}>
                        ویرایش تلفن همراه
                    </Button>
                </>}
        </>
    );
};

export default ValidateOtpCode;