import axios from "axios";
import {notification} from "antd";

export const baseUrl = 'https://bitblex.com/api/';
export const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
};
export const axiosWrapper = axios.create({
    baseURL: baseUrl,
    headers: headers
})

export const createResponse = (statusCode, data?: any) => {
    return {
        statusCode: statusCode | 200,
        data: data
    };
};

export const errorHandler = (err: any) => {
    if (!!err.response && !!err.response.data && !!err.response.data.statusCode && err.response.data.statusCode === 500) {
        notification['error']({
            message: 'خطا در ارسال پیامک',
            placement: 'bottomRight'
        });
    } else if (!!err.response && !!err.response.data && !!err.response.data.statusCode && err.response.data.statusCode === 401) {
        notification['error']({
            message: 'خطا',
            description: 'خطا در اعتبار سنجی توکن',
            placement: 'bottomRight'
        });
    } else if (!!err && !!err.response && !!err.response.data) {
        if (typeof err.response.data.message === 'object') {
            notification['error']({
                message: err.response.data.message[0],
                placement: 'bottomRight'
            });
        } else {
            notification['error']({
                message: err.response.data.message,
                placement: 'bottomRight'
            });
        }
    }
}
