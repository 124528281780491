import React from 'react';
import {selectFestivalTopic, useAppSelector} from "../../../../redux";
import {IFestival} from "../../../../shared/interfacesAndTypes/interfaces";

const SelectCoin = (props) => {
    const festival = useAppSelector(selectFestivalTopic)
    let festivalArray: IFestival[] = [];
    if (!!festival && festival['festCount'] > 0) {
        festivalArray = Object.entries(festival).filter(item => item[0] !== 'festCount').map(item => item[1])
    }
    return (
        <div className={`h-100 w-100 mt-5 px-3 position-relative`}>
            <h3 className={`secondaryColor fontWeightBold text-center`}>معاملات</h3>
            <h5 className={`text-white mt-3`}>قصد معامله کدام ارز یا توکن را دارید؟</h5>
            {(!!festivalArray && festivalArray.length > 0) &&
                <>
                    <hr className={`mb-2 mt-4 primaryColor`} style={{height: 3}}/>
                    <h5 className={`text-white`}>
                        <i className={`fas fa-gift secondaryColor me-2`}></i>
                        جشنواره های فعال
                    </h5>
                    <ul>
                        {festivalArray.map((fes, index) => {
                            return <li key={index} style={{listStyleType: 'disc'}}>
                                <span className={`text-white mt-3 ss02`}>
                                    تخفیف کارمزد {fes.discountPercent} درصد برای {fes.currency}
                                </span>
                            </li>
                        })}
                    </ul>
                </>
            }
            <hr className={`my-2 primaryColor`} style={{height: 3}}/>
            <div
                className={`card box-shadow bgTertiaryLightColor m-2 d-flex align-items-center justify-content-center cursor-pointer`}
                onClick={() => props.onCoinClick('USDTTRC20')}>
                <div className={`p-2 text-white`}>Tether (USDT)</div>
            </div>
        </div>
    );
};

export default SelectCoin;