import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Form, Input, Modal} from "antd";
import {
    emailRegex,
    farsiRegex,
    validateIranianSheba,
    validateIrBankCard,
    validateIrNationalId
} from "../../../shared/regex-and-validations";
import {signUpService} from "../../../shared/services/services";
import {
    selectJoinRef,
    selectOtpCode,
    selectUserMobile,
    showLoadingAction,
    tokenAction,
    useAppDispatch,
    useAppSelector
} from "../../../redux";
import type {CheckboxChangeEvent} from 'antd/es/checkbox';
import {useNavigate} from "react-router";
import {DatePicker} from "react-advance-jalaali-datepicker";
import useWindowDimensions from "../../../shared/dimensions/dimensions";

const SignUp = () => {
    const navigate = useNavigate();
    const userMobile = useAppSelector(selectUserMobile);
    const otpCode = useAppSelector(selectOtpCode);
    const joinRef = useAppSelector(selectJoinRef);
    const [birthDate, setBirthDate] = useState('')
    const dispatch = useAppDispatch();
    const [acceptRules, setAcceptRules] = useState(false);
    const [form] = Form.useForm();
    const [, forceUpdate] = useState({});
    const [rolesOpen, setRolesOpen] = useState(false);
    const modalStyle: React.CSSProperties = {
        marginTop: '60px',
        marginBottom: '60px',
    }
    const width = useWindowDimensions();
    let modalWidth: number | string;
    if (width > 1400) {
        modalWidth = 960
    } else if (width > 1200) {
        modalWidth = 720
    } else if (width > 992) {
        modalWidth = 540
    } else if (width > 768) {
        modalWidth = 320
    } else {
        modalWidth = '100%'
    }

    const onSignUpClick = async (form) => {
        dispatch(showLoadingAction(true))
        const data = await signUpService({
            "phoneNo": userMobile,
            "code": parseInt(otpCode),
            "nationalId": form.nationalId,
            "name": form.name,
            "email": form.email,
            "birthDate": birthDate,
            "referral": form.referral,
            "bankAcc": {
                "shebaNo": !form.shebaNo.startsWith('IR') ? 'IR' + form.shebaNo : form.shebaNo,
                "cardNo": form.cardNo,
                "bank": form.bank
            }
        });
        if (!!data && !!data.data && data.data['accessToken']) {
            dispatch(tokenAction(data.data['accessToken']));
            setTimeout(async () => {
                await navigate('/intro')
                dispatch(showLoadingAction(false))
            }, 1000)
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const DatePickerInput = (props: any) => {
        return <input autoComplete="false"
                      className={`inputs border-radius-7 text-dark py-2 px-1 w-100  ss02`} {...props} />;
    }
    const change = (unix: any, formatted: any) => {
        setBirthDate(formatted)
    }
    const onFormFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }
    const onChange = (e: CheckboxChangeEvent) => {
        setAcceptRules(e.target.checked);
    };

    useEffect(() => {
        form.setFieldValue('referral', joinRef)
        forceUpdate({})
    }, [form, joinRef]);
    return (
        <>
            <h3 className={`text-white`}>ثبت نام</h3>
            <Form
                className={`mt-2 w-100`}
                name="SignUpComponent"
                labelCol={{span: 25}}
                wrapperCol={{span: 25}}
                onFinish={onSignUpClick}
                onFinishFailed={onFormFailed}
                autoComplete="off"
                form={form}>
                <h6 className={`text-white`}>نام و نام خانوادگی <i
                    className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="name"
                    rules={[
                        {required: true, message: 'لطفا نام را وارد کنید!'},
                        {pattern: farsiRegex, message: "لطفا فقط از حروف فارسی استفاده کنید"}
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark`}/>
                </Form.Item>
                <h6 className={`text-white`}>کد ملی <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="nationalId"
                    rules={[
                        {required: true, message: 'لطفا کد ملی را وارد کنید!'},
                        {
                            message: 'کد ملی نا معتبر می باشد',
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if (validateIrNationalId(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('کد ملی نا معتبر است');
                                }
                            }
                        }
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark ss02`}/>
                </Form.Item>
                <h6 className={`text-white`}>ایمیل <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="email"
                    rules={[
                        {required: true, message: 'لطفا ایمیل را وارد کنید!'},
                        {pattern: emailRegex, message: "لطفا ایمیل را به صورت صحیح وارد کنید"}
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark`}/>
                </Form.Item>
                <h6 className={`text-white`}>تاریخ تولد <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="birthDate"
                    rules={[{required: true, message: 'لطفا تاریخ تولد را وارد کنید!'}]}>
                    <DatePicker
                        inputComponent={DatePickerInput}
                        placeholder="انتخاب تاریخ"
                        format="jYYYY/jMM/jDD"
                        onChange={change}
                        id="birthDate"
                    />
                </Form.Item>
                <h6 className={`text-white`}>کد یا شماره معرف</h6>
                <Form.Item
                    label=""
                    name="referral">
                    <Input allowClear
                           bordered={false}
                           disabled={!!joinRef && joinRef.length > 0}
                           className={`inputs border-radius-7 text-dark ss02`}/>
                </Form.Item>
                <h6 className={`text-white`}>شماره شبا <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="shebaNo"
                    rules={[
                        {required: true, message: 'لطفا شماره شبا را وارد کنید!'},
                        {
                            message: 'شماره شبا نا معتبر است',
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if (validateIranianSheba(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('شماره شبا نا معتبر است');
                                }
                            }
                        }
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark ss02`}/>
                </Form.Item>
                <h6 className={`text-white`}>شماره کارت <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="cardNo"
                    rules={[
                        {required: true, message: 'لطفا شماره کارت را وارد کنید!'},
                        {
                            message: 'شماره کارت نا معتبر است',
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.resolve();
                                }
                                if (validateIrBankCard(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('شماره کارت نا معتبر است');
                                }
                            }
                        }
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark ss02`}/>
                </Form.Item>
                <h6 className={`text-white`}>نام بانک <i className="fas fa-star text-danger fa-2xs lh-base"></i></h6>
                <Form.Item
                    label=""
                    name="bank"
                    rules={[
                        {required: true, message: 'لطفا نام بانک را وارد کنید!'},
                        {pattern: farsiRegex, message: "لطفا فقط از حروف فارسی استفاده کنید"}
                    ]}>
                    <Input allowClear bordered={false} className={`inputs border-radius-7 text-dark`}/>
                </Form.Item>
                <Form.Item
                    label=""
                    name="rules"
                    className={`text-white`}>
                    <Checkbox checked={acceptRules} onChange={onChange}> </Checkbox>
                    <span onClick={() => setRolesOpen(true)}
                          style={{textDecoration: 'underline'}}
                          className={`text-white cursor-pointer`}> قوانین </span>
                    بیت بلکس را مطالعه کرده و می‌پذیرم
                </Form.Item>
                <Form.Item shouldUpdate wrapperCol={{span: 25}}>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={`btn btnPrimary mt-3 px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3`}
                            disabled={!!form.getFieldsError().filter(({errors}) => errors.length).length && !acceptRules}>
                            ثبت نام
                        </Button>
                    )}
                </Form.Item>
            </Form>
            <Modal style={modalStyle}
                   title="قوانین و مقررات"
                   centered
                   open={rolesOpen}
                   onCancel={() => setRolesOpen(false)}
                   width={modalWidth}>
                <div className={`row`}>
                    <div className={`col-12  fontSizeNormal overflow-y`}>
                        <ul>
                            <li style={{listStyleType: 'disc'}}>
                                بیت بلکس، تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی های دیجیتال
                                مانند بیت
                                کوین،
                                لایت کوین و اتریوم با ریال می باشد
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                کاربر گرامی شما باید قبل از استفاده از هر بخش قوانین آن را به دقت مطالعه نمایید؛ دقت
                                کنید که
                                استفاده از این بات معاملاتی به منزله مطالعه کلیه قوانین و موافقت با آن‌ها می‌باشد.
                            </li>
                        </ul>
                        <hr/>
                        <h4 className={`text-white`}>تعهدنامه فعالیت در بستر تبادل دارایی های دیجیتال بیت بلکس</h4>
                        <ul>
                            <li style={{listStyleType: 'disc'}}>
                                کاربران می تواند با استفاده از بخش خرید و فروش نسبت به خرید یا فروش ارزهای موجود اقدام
                                نماید.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                کاربران در ابتدا برای استفاده از خدمات معاملاتی این بات ملزم به شارژ کیف‌پول خود می
                                باشد.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                مدت زمان اعتبار قیمت یا همان نرخ اعلامی محدود است و کاربران ملزم به تایید و تکمیل معامله
                                در این
                                بازه زمانی هستند تا معامله ایشان ثبت و پردازش شود، در غیر این صورت با نرخ مجدد مجاز به
                                ادامه
                                فرایند خواهند بود..
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                کاربران بات معاملاتی بیت‌بلکس می توانند در صورت بروز هرگونه ایراد در طول عملیات خرید یا
                                فروش خود
                                جهت اطلاع و یا رفع مشکل با بخش پشتیبانی بات و کارمندان واحد پشتیبانی ما در ارتباط باشند.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                امکان لغو معامله وجود ندارد، لذا لازم است که کاربر اطمینان کامل را از شرایط حاکم بر
                                معامله
                                درخواستی داشته باشد و سپس آن را تایید نماید..
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                در صورت صلاحدید بیت‌بلکس حق خود را برای تغییر قوانین محفوظ می دارد.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                بیت‌بلکس حق آن را دارد که بر اساس صلاحدید به حذف کاربر یا عدم سرویس دهی اقدام نماید.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                مسولیت اشتباهات صورت گرفته در کلیه عملیات های صورت گرفته و خدمات ارائه شده در این بات که
                                ناشی از
                                عدم اطلاع و یا ثبت اشتباه توسط کاربر باشد بر عهده بیت‌بلکس نخواهد بود.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                کاربران موظف‌اند برای استفاده از خدمات بیت‌بلکس نسبت به احرازهویت خود در اولین فرصت
                                مطابق با
                                قوانین جاری کشور اقدام نمایند.
                            </li>
                            <li style={{listStyleType: 'disc'}}>
                                مطابق دستورالعمل پلیس فتا پس از اولین خرید، رمزارز خریداری شده تا 24 ساعت نزد بیت‌یلکس
                                به امانت
                                نگهداری می‌شود.
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SignUp;