import React, {useEffect} from 'react';
import HomeComponent from "../pages/home/home";
import {useNavigate} from "react-router";
import {isMobile} from 'react-device-detect'
import {useSearchParams} from "react-router-dom";
import HelmetHOC from "./helmet-HOC";


function HomeHOC() {
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !queryParams.fromPwa) {
            if (isMobile && !window.matchMedia('(display-mode: standalone)').matches) {
                navigate('/pwa')
            }
        }
    }, [navigate, searchParams]);

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex"/>
        <HomeComponent/>
    </>;
}

export default HomeHOC;
