import React, {useState} from 'react';
import {notification, Tooltip} from "antd";
import {IProfileProps} from "../../../shared/interfacesAndTypes/interfaces";
import {useAppSelector, userProfileSelector} from "../../../redux";

const InviteFriends = () => {
    const userProfile: IProfileProps = useAppSelector(userProfileSelector)
    const [tooltipTitle, setTooltipTitle] = useState<string>('برای کپی کلیک کنید');

    const onCopyToClipboardClick = (item) => {
        navigator.clipboard.writeText(item);
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
        setTooltipTitle('کپی شد')
    }
    const onOpenTooltipChange = (visible) => {
        if (!visible) {
            setTooltipTitle('برای کپی کلیک کنید')
        }
    }

    return (
        <div className={`h-100 w-100 mt-5 px-3 position-relative`}>
            <h3 className={`secondaryColor fontWeightBold text-center`}>دعوت از دوستان</h3>
            <h6 className={`text-white mt-3 w-100 ss02`}>
                شما روزانه از تراکنش‌های دوستان مستقیم و غیرمستقیم خود تا سقف 30 درصد بصورت مادام العمر هدیه دریافت
                می‌کنید
            </h6>
            <div className={`w-100 d-flex flex-column align-items-center justify-content-between px-2 mt-3`}>
                <div className={`d-flex align-items-center justify-content-between w-100`}>
                    <span className={`text-white`}>دوستان مستقیم</span>
                    <span className={`text-white ss02`}>{userProfile.referral!.lvl1Count} نفر</span>
                </div>
                <div className={`d-flex align-items-center justify-content-between w-100  mt-2`}>
                    <span className={`text-white`}>دوستان غیرمستقیم</span>
                    <span className={`text-white ss02`}>{userProfile.referral!.lvl2Count} نفر</span>
                </div>
                <div className={`d-flex align-items-center justify-content-between w-100  mt-2`}>
                    <span className={`text-white`}>مجموع درآمد شما</span>
                    <span className={`text-white ss02`}>
                        {Number(userProfile.referral!.earningSum).toLocaleString('US')} تومان
                    </span>
                </div>
            </div>
            <hr className={`my-2 primaryColor`} style={{height: 3}}/>
            {!!userProfile.referral && <>
                <div className={`w-100 d-flex flex-column px-2 mt-3`}>
                    <span className={``}>کد دعوت شما:</span>
                    <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                             onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                        <span className={`text-white text-end mt-2`}>
                             <span onClick={() => onCopyToClipboardClick(userProfile.referral!.id)}
                                   className={`cursor-pointer secondaryColor`}>
                                <span className={`fontSizeTiny`}>{userProfile.referral!.id}</span>
                                <i className={`fas fa-clipboard ms-2 cursor-pointer secondaryColor fa-lg`}></i>
                            </span>
                        </span>
                    </Tooltip>
                </div>
                <div className={`w-100 d-flex flex-column px-2 mt-3`}>
                    <span className={``}>لینک عضویت با کد شما در تلگرام:</span>
                    <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                             onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                        <span className={`text-white text-end mt-2`}>
                             <span onClick={() => onCopyToClipboardClick(userProfile.referral!.tgLink)}
                                   className={`cursor-pointer secondaryColor`}>
                                <span className={`fontSizeTiny`}>{userProfile.referral!.tgLink}</span>
                                <i className={`fas fa-clipboard ms-2 cursor-pointer secondaryColor fa-lg`}></i>
                            </span>
                        </span>
                    </Tooltip>
                </div>
                <div className={`w-100 d-flex flex-column px-2 mt-3`}>
                    <span className={``}>لینک عضویت با کد شما در سایت:</span>
                    <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                             onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                        <span className={`text-white text-end mt-2`}>
                            <span onClick={() => onCopyToClipboardClick(userProfile.referral!.siteLink)}
                                  className={`cursor-pointer secondaryColor`}>
                                <span className={`fontSizeTiny`}>{userProfile.referral!.siteLink}</span>
                                <i className={`fas fa-clipboard ms-2 cursor-pointer secondaryColor fa-lg`}></i>
                            </span>
                        </span>
                    </Tooltip>
                </div>
            </>}
            <hr className={`my-2 primaryColor`} style={{height: 3}}/>
            <div
                className={`card box-shadow bgTertiaryLightColor text-white mt-3 w-100 d-flex flex-column align-items-center justify-content-center`}>
                <div className={`card-body`}>
                    <div className={`d-flex align-items-center justify-content-center`}>
                        <i className="fas fa-exclamation-circle fs-3 text-white"></i>
                    </div>
                    <div className={`d-flex flex-column mt-2 ss02`}>
                        <span className={`text-white`}>
                            1- با انتخاب آیکن سمت راست هر لینک مقدار آن کپی می‌شود
                        </span>
                        <span className={`text-white mt-2`}>
                            2- دوستان شما می‌توانند از شماره تلفن‌همراه شما به عنوان کد معرف استفاده کنند
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InviteFriends;