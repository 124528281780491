import React, {useEffect, useState} from 'react';
import {notification, Skeleton, Tooltip} from "antd";
import {depositCrypto} from "../../../../shared/services/services";
import QRCode from 'qrcode'


const DepositUsdt = (props) => {
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [qrCode, setQrCode] = useState<string>('');
    const [tooltipTitle, setTooltipTitle] = useState<string>('برای کپی کلیک کنید');

    const onCopyToClipboardClick = () => {
        navigator.clipboard.writeText(walletAddress);
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
        setTooltipTitle('کپی شد')
    }
    const onOpenTooltipChange = (visible) => {
        if (!visible) {
            setTooltipTitle('برای کپی کلیک کنید')
        }
    }

    useEffect(() => {
        setSkeletonLoading(true)
        depositCrypto({currency: 'USDTTRC20'}).then(response => {
            if (!!response && !!response.data) {
                setWalletAddress(response.data['address']);
                QRCode.toDataURL(response.data['address']).then(qr => setQrCode(qr)).catch(err => console.error(err))
                setSkeletonLoading(false)
            } else {
                setSkeletonLoading(false)
            }
        }).catch(() => setSkeletonLoading(false))
    }, []);

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`primaryColor fontWeightBold text-center`}>واریز تتر</h3>
                <h6 className={`text-white mt-3`}>آدرس کیف پول</h6>
                {!skeletonLoading ? <>
                    <div className={`d-flex align-items-center justify-content-center`}>
                        {(!!qrCode && qrCode.length > 0) && <img src={qrCode} alt="qr pic" width="130" height="130"/>}
                    </div>
                    <Tooltip color={'#10d078'} title={tooltipTitle}
                             onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                        <p className={`text-center text-white mb-0 mt-4 fs-7`}>
                            <i onClick={() => onCopyToClipboardClick()}
                               className={`fas fa-clipboard me-2 cursor-pointer secondaryColor`}></i>
                            <span onClick={() => onCopyToClipboardClick()}
                                  className={`cursor-pointer secondaryColor`}>{walletAddress}</span>
                        </p>
                    </Tooltip>
                </> : <Skeleton active loading={true}></Skeleton>}
                <div
                    className={`card box-shadow bgTertiaryLightColor text-white mt-3 w-100 d-flex flex-column align-items-center justify-content-center`}>
                    <div className={`card-body`}>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            <i className="fas fa-exclamation-circle fs-3 text-white"></i>
                        </div>
                        <div className={`d-flex flex-column mt-2 ss02`}>
                            <span className={`text-white`}>
                                1- برای کپی روی آیکن کنار آدرس کیف پول کلیک کنید
                            </span>
                            <span className={`text-white mt-2`}>
                                2- با انتقال رمزارز خود به آدرس فوق، پس از دریافت تایید شبکه کیف ‌پول شما به صورت خودکار شارژ خواهد شد و به شما اطلاع‌رسانی می‌شود
                            </span>
                            <span className={`text-white mt-2`}>
                                3- شبکه واریز TRON - TRC20 می باشد
                            </span>
                            <span className={`text-white mt-2`}>
                                4- توجه داشته باشید کیف‌ پول فوق هیچ ارتباطی با صرافی‌های ایرانی ندارد
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-100 d-flex justify-content-center mb-3`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center ss02`}>موجودی کیف
                        پول: {Number(props.balances.find(balance => balance.name === 'USDT').amount).toLocaleString('US')} تتر
                    </div>
                </div>
            </div>
            <div className={`d-flex w-100 mb-4`}>
                <button className={`btn btnPrimary w-100 fontWeightBold`}
                        onClick={() => props.onApproveClick()}>تایید
                </button>
            </div>
        </div>

    );
};

export default DepositUsdt;