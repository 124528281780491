import React, {useCallback, useRef, useState} from 'react';
import Webcam from "react-webcam";
import {notification} from "antd";
import {IWebcamProps} from "../../../shared/interfacesAndTypes/interfaces";
import useWindowDimensions from "../../../shared/dimensions/dimensions";

const WebcamCapture = (props: IWebcamProps) => {
    const {webcamMode} = props
    const webcamRef = useRef<Webcam>(null);
    const width = useWindowDimensions();
    const facingMode = width < 768 ? 'environment' : 'user'
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [capturing, setCapturing] = useState(false);

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageBase = webcamRef.current.getScreenshot()
            if (imageBase) {
                props.onCapturedImage?.(imageBase)
            }
        }
    }, [props]);
    const onDataAvailable = useCallback(({data}) => {
        if (data.size > 0) {
            props.onRecordedVideo?.([data])
        }
    }, [props]);
    const startCaptureClick = useCallback(() => {
        setCapturing(true);
        if (webcamRef.current?.stream) {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current?.stream, {mimeType: "video/webm"});
        }
        mediaRecorderRef.current?.addEventListener("dataavailable", onDataAvailable);
        mediaRecorderRef.current?.start();
    }, [onDataAvailable]);
    const onStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current?.stop();
        setCapturing(false);
    }, []);
    const onUserMediaError = () => {
        notification['error']({
            message: 'خطا',
            description: 'لطفا مجوز دسترسی به دوربین را به بیتبلکس بدهید',
            placement: 'bottomRight'
        });
    }

    return (
        <div className={`d-flex flex-column`}>
            {webcamMode === 'getScreenShot' ?
                <>
                    <Webcam audio={false}
                            height="100%"
                            width="100%"
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={{facingMode}}
                            onUserMediaError={() => onUserMediaError()}/>
                    <div className={`d-flex align-items-center justify-content-between`}>
                        <button className={`btn btn-danger mt-2`} onClick={capture}>
                            <i className={`fas fa-camera me-2`}></i>
                            گرفتن عکس
                        </button>
                    </div>
                </> :
                <>
                    <Webcam audio={true}
                            height="100%"
                            width="100%"
                            ref={webcamRef}
                            onUserMediaError={() => onUserMediaError()}/>
                    <div className={`d-flex align-items-center justify-content-between`}>
                        {capturing ?
                            <button className={`btn btnSecondary mt-2`} onClick={onStopCaptureClick}>
                                <i className="fas fa-save me-2"></i>
                                ذخیره ویدئو
                            </button> :
                            <button className={`btn btn-light mt-2`} onClick={startCaptureClick}>
                                <i className={`fas fa-record-vinyl me-2`}></i>
                                ضبط ویدیو
                            </button>}
                    </div>
                </>
            }
        </div>
    );
};

export default WebcamCapture;