import React, {useEffect, useState} from 'react';
import MyWalletTransfer from "./my-wallet-transfer/my-wallet-transfer";
import DepositUsdt from "./deposit-usdt/deposit-usdt";
import DepositToman, {depositTypeEnum} from "./deposit-toman/deposit-toman";
import ApproveWithdrawalUsdt from "./approve-withdrawal-usdt/approve-withdrawal-usdt";
import {IFavWithdrawal, IMyWalletWallet, IWalletBalance} from "../../../shared/interfacesAndTypes/interfaces";
import {getWallet} from "../../../shared/services/services";
import {Skeleton} from "antd";
import OnlineDepositToman from "./online-deposit-toman/online-deposit-toman";
import BankTransferDepositToman from "./bank-transfer-deposit-toman/bank-transfer-deposit-toman";
import ApproveBankTransferDeposit from "./approve-bank-transfer-deposit/approve-bank-transfer-deposit";
import WithdrawalUsdt from "./withdrawal-usdt/withdrawal-usdt";
import WithdrawalToman from "./withdrawal-toman/withdrawal-toman";
import ApproveWithdrawalToman from "./approve-withdrawal-toman/approve-withdrawal-toman";
import {selectDictionary, useAppSelector} from "../../../redux";

const MyWallet = (props: IMyWalletWallet) => {
    const [componentName, setComponentName] = useState('myWallet');
    const [selectedCoin, setSelectedCoin] = useState<IWalletBalance>({amount: '', name: ''});
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [balances, setBalances] = useState<Array<IWalletBalance>>([{amount: "", name: ""}])
    const [USDTWithdrawalAmount, setUSDTWithdrawalAmount] = useState(0);
    const [tomanWithdrawalAmount, setTomanWithdrawalAmount] = useState(0);
    const [selectedWithdrawalTomanFavAccount, setSelectedWithdrawalTomanFavAccount] = useState<IFavWithdrawal>();
    const [selectedWithdrawalCryptoFavAccount, setSelectedWithdrawalCryptoFavAccount] = useState('');
    const [tomanDepositAmount, setTomanDepositAmount] = useState<number>(0);
    const [bankTransferDepositAccount, setBankTransferDepositAccount] = useState({});
    const dictionary = useAppSelector(selectDictionary);

    const onCoinClick = (coin) => {
        setSelectedCoin(coin);
        setComponentName('myWalletTransfer')
    }
    const onDepositClick = (coin) => {
        props.changeBorderTopColor?.('#0d6efd')
        if (coin.name === balances[1].name) {
            setComponentName('depositUSDT')
        } else {
            setComponentName('depositToman')
        }
    }
    const onWithdrawalClick = (coin) => {
        props.changeBorderTopColor?.('#10d078')
        if (coin.name === balances[1].name) {
            setComponentName('withdrawalUSDT')
        } else {
            setComponentName('withdrawalToman')
        }
    }
    const onSetUSDTWithdrawalValue = (data) => {
        setComponentName('approveWithdrawalUSDT');
        setUSDTWithdrawalAmount(data.USDTWithdrawalAmount)
        setSelectedWithdrawalCryptoFavAccount(data.address)
    }
    const onSetPayTypeClick = (data) => {
        if (data['depositType'] === depositTypeEnum.onlinePayment) {
            setComponentName('onlineDepositToman')
        } else {
            setBankTransferDepositAccount(data['depositBankAccountData'])
            setComponentName('bankTransferDepositToman')
        }
        setTomanDepositAmount(data.tomanDepositAmount)
    }
    const onWithdrawalNextClick = (data) => {
        setComponentName('approveWithdrawalToman');
        setTomanWithdrawalAmount(data.tomanWithdrawalAmount)
        setSelectedWithdrawalTomanFavAccount(data.bankAcc)
    }
    const afterApprove = async () => {
        await getWalletFunc()
        setComponentName('myWallet')
    }
    const getWalletFunc = async () => {
        setSkeletonLoading(true)
        await getWallet().then(response => {
            if (!!response && !!response.data) {
                let data = Object.entries(response.data['balances']).map(item => {
                    return {
                        name: item[0],
                        amount: response!.data['balances'][item[0]]
                    }
                })
                setBalances(data);
                setSkeletonLoading(false)
            } else {
                setSkeletonLoading(false)
            }
        }).catch(() => setSkeletonLoading(false))
    };
    useEffect(() => {
        getWalletFunc().catch(console.error);
    }, []);

    let component;
    switch (componentName) {
        case 'myWallet':
            component = <div className={`h-100 w-100 mt-5 px-3 d-flex flex-column`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>کیف پول من</h3>
                {!skeletonLoading ?
                    <div className={`d-flex flex-column align-items-center justify-content-between h-100`}>
                        <div className={`w-100 d-flex flex-column`}>
                            {balances.map((coin, index) => {
                                return <div
                                    className={`d-flex align-items-center justify-content-between p-3 bgTertiaryLightColor mt-4 border-radius-7 cursor-pointer`}
                                    key={index}
                                    onClick={() => onCoinClick(coin)}>
                                    <span
                                        className={`text-white fontWeightBold fa-lg`}>{dictionary.currencies[coin.name]}</span>
                                    <span
                                        className={`text-white ss02`}>{Number(coin.amount).toLocaleString('US')}</span>
                                </div>
                            })}
                        </div>
                        <div className={`w-100 d-flex flex-overflow justify-content-center mb-5`}>
                            <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                                <div className={`p-2 text-center`}>
                                    جهت واریز یا برداشت ارز یا توکن مورد نظر را انتخاب کنید
                                </div>
                            </div>
                        </div>
                    </div> :
                    <Skeleton avatar active loading={true}></Skeleton>
                }
            </div>
            break;
        case 'myWalletTransfer':
            component = <MyWalletTransfer onBackClick={() => setComponentName('myWallet')}
                                          selectedCoin={selectedCoin}
                                          onOpenAuthentication={() => props.onOpenAuthentication?.()}
                                          onWithdrawalClick={(coin) => onWithdrawalClick(coin)}
                                          onDepositClick={(coin) => onDepositClick(coin)}/>
            break;
        case 'depositToman':
            component = <DepositToman onBackClick={() => setComponentName('myWalletTransfer')}
                                      balances={balances}
                                      onPayClick={(data) => onSetPayTypeClick(data)}/>
            break;
        case 'onlineDepositToman':
            component = <OnlineDepositToman onBackClick={() => setComponentName('myWallet')}
                                            tomanDepositAmount={tomanDepositAmount}
                                            onPayClick={(data) => onSetPayTypeClick(data)}/>
            break;
        case 'bankTransferDepositToman':
            component = <BankTransferDepositToman onBackClick={() => setComponentName('myWallet')}
                                                  tomanDepositAmount={tomanDepositAmount}
                                                  bankTransferDepositAccount={bankTransferDepositAccount}
                                                  onPayClick={() => setComponentName('approveBankTransferDeposit')}/>
            break;
        case 'approveBankTransferDeposit':
            component = <ApproveBankTransferDeposit onBackClick={() => setComponentName('myWallet')}
                                                    tomanDepositAmount={tomanDepositAmount}
                                                    bankTransferDepositAccount={bankTransferDepositAccount}
                                                    onPayClick={() => setComponentName('myWallet')}/>
            break;
        case 'depositUSDT':
            component = <DepositUsdt onBackClick={() => setComponentName('myWalletTransfer')}
                                     balances={balances}
                                     onApproveClick={() => setComponentName('myWallet')}/>
            break;
        case 'withdrawalToman':
            component = <WithdrawalToman onBackClick={() => setComponentName('myWalletTransfer')}
                                         balances={balances}
                                         onNextClick={(data) => onWithdrawalNextClick(data)}/>
            break;
        case 'approveWithdrawalToman':
            component = <ApproveWithdrawalToman onBackClick={() => setComponentName('withdrawalToman')}
                                                selectedWithdrawalFavAccount={selectedWithdrawalTomanFavAccount}
                                                tomanWithdrawalAmount={tomanWithdrawalAmount}
                                                onApproveClick={() => afterApprove()}/>
            break;
        case 'withdrawalUSDT':
            component = <WithdrawalUsdt onBackClick={() => setComponentName('myWalletTransfer')}
                                        balances={balances}
                                        onNextClick={(data) => onSetUSDTWithdrawalValue(data)}/>
            break;
        case 'approveWithdrawalUSDT':
            component = <ApproveWithdrawalUsdt onBackClick={() => setComponentName('withdrawalUSDT')}
                                               USDTWithdrawalAmount={USDTWithdrawalAmount}
                                               address={selectedWithdrawalCryptoFavAccount}
                                               onApproveClick={() => afterApprove()}/>
            break;
        default:
            component = <span></span>
            break;
    }
    return component;
};

export default MyWallet;