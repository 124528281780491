import React, {useCallback, useEffect, useState} from 'react';
import {InputNumber, notification, Skeleton} from "antd";
import {
    selectCurrenciesTopic,
    selectDictionary,
    selectFestivalTopic,
    selectToken,
    showLoadingAction,
    useAppDispatch,
    useAppSelector,
    userProfileAction
} from "../../../../redux";
import {createReceipt, getFestRemaining, getWallet} from "../../../../shared/services/services";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import styles from "./but-amount.module.scss";

const BuyAmount = (props) => {
    const dictionary = useAppSelector(selectDictionary);
    const currenciesTopic = useAppSelector(selectCurrenciesTopic);
    const date = new Date().toLocaleString('fa-IR')
    const [buyAmountDefaultValues, setBuyAmountDefaultValues] = useState([
        {index: 1, value: 100, valueTitle: '100'},
        {index: 2, value: 500, valueTitle: '500'},
        {index: 3, value: 1000, valueTitle: '1000'},
        {index: 4, value: 2000, valueTitle: '2000'},
    ]);
    const [buyAmountValue, setBuyAmountValue] = useState<number | null>(null);
    const festival = useAppSelector(selectFestivalTopic)
    const token = useAppSelector(selectToken);
    const dispatch = useAppDispatch();
    const [festRemaining, setFestRemaining] = useState(0);
    const [balances, setBalances] = useState([{amount: "", name: ""}])
    const [skeletonLoading, setSkeletonLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams,] = useSearchParams();

    const onApproveClick = useCallback(async () => {
        if (!buyAmountValue) {
            return;
        }
        if (buyAmountValue === 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا تعداد واحد درخواستی برای خرید را وارد کنید',
                placement: 'bottomLeft'
            });
            return
        }
        const decimalStr = buyAmountValue.toString().split('.')[1];
        if (!!decimalStr && decimalStr.length > 6) {
            notification.error({
                message: 'خطا',
                description: 'تعداد اعشار قابل قبول، تا شش رقم اعشار می باشد',
                placement: 'bottomLeft'
            });
            return
        }
        dispatch(showLoadingAction(true))
        const res = await createReceipt({
            exchangeSide: 'BUY',
            currency: props.selectedCoin,
            value: buyAmountValue,
            feeSide: 'TOMAN'
        })
        dispatch(showLoadingAction(false))
        if (!!res) {
            props.onApproveClick(buyAmountValue)
        }
    }, [buyAmountValue, dispatch, props])
    const onChangeBuyAmountValue = (amount) => {
        setBuyAmountValue(amount)
    }

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !!queryParams.currencyValue) {
            setBuyAmountValue(parseInt(queryParams.currencyValue as string))
            navigate(location.pathname);
        }
    }, [location, navigate, searchParams])

    useEffect(() => {
        if (!!festival && festival['festCount'] > 0 && Object.keys(festival).find(item => item === props.selectedCoin)) {
            getFestRemaining(props.selectedCoin, token).then(response => {
                if (!!response && response.data) {
                    dispatch(userProfileAction(response.data));
                    if (!!response.data) {
                        setFestRemaining(response.data['remaining'])
                    }
                }
            })
        }
    }, [dispatch, festival, props.selectedCoin, token])

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onApproveClick()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onApproveClick]);

    useEffect(() => {
        setSkeletonLoading(true)
        getWallet().then(response => {
            if (!!response && !!response.data) {
                let data = Object.entries(response.data['balances']).map(item => {
                    return {name: item[0], amount: response!.data['balances'][item[0]]}
                })
                setBalances(data);
                let allWalletAmount = {
                    index: 5,
                    value: Math.floor(Number(data.find(balance => balance.name === 'TOMAN')?.amount) / Number(currenciesTopic[props.selectedCoin].currentPrice)),
                    valueTitle: 'تمام موجودی'
                }
                if (!buyAmountDefaultValues.find(val => val.value === allWalletAmount.value)) {
                    setBuyAmountDefaultValues([...buyAmountDefaultValues, allWalletAmount])
                }
                setSkeletonLoading(false)
            }
        }).catch(() => setSkeletonLoading(false))
    }, [buyAmountDefaultValues, currenciesTopic, props.selectedCoin]);

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 start-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column h-100 mt-5 px-3`}>
                <div className={`w-100`}>
                    <h3 className={`secondaryColor fontWeightBold text-center`}>معاملات</h3>
                    {(!!dictionary && dictionary.currencies[props.selectedCoin]) ?
                        <h6 className={`text-white mt-3`}>
                            قصد خرید چند واحد {dictionary.currencies[props.selectedCoin]} را دارید؟
                        </h6> : <Skeleton avatar active loading={true}></Skeleton>}
                    <h6 className={`text-white text-end mt-1`}>{date}<i className={`fas fa-clock ms-2`}></i></h6>
                    {!skeletonLoading ?
                        <div className={`mt-3`}>
                            {buyAmountDefaultValues.map((item, key) => {
                                return <div key={key}
                                            className={`card box-shadow bgTertiaryLightColor m-2 d-flex flex-wrap align-items-center justify-content-center cursor-pointer
                                                                ${buyAmountValue === item.value ? styles.selectedDefaultValue : styles.defaultValue}`}
                                            onClick={() => setBuyAmountValue(item.value)}>
                                    <div className={`py-2 text-white ss02`}>
                                        {!!item.valueTitle ? item.valueTitle : Number(item.value).toLocaleString('US')}
                                    </div>
                                </div>
                            })}
                        </div>
                        : <Skeleton.Input active size={"small"}></Skeleton.Input>}
                    <div className={`row mt-3`}>
                        <div className={`col-md-12`}>
                            <h6 className={`text-white`}>مقدار دلخواه خود را وارد کنید</h6>
                            <div className={`w-100`}>
                                <InputNumber bordered={false}
                                             onChange={onChangeBuyAmountValue}
                                             min={0}
                                             controls={false}
                                             formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                             className={`inputs w-100 border-radius-7 ss02`}
                                             value={buyAmountValue}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-100 mb-4 d-flex flex-column align-items-center justify-content-center`}>
                    <div className={`w-100`}>
                        {(!!festival && Object.keys(festival).find(item => item === props.selectedCoin)) &&
                            <>
                                <i className={`fas fa-gift text-white secondaryColor m-2`}></i>
                                <span className={`text-white mt-3`}>
                                    در جشنواره {festival[props.selectedCoin].discountPercent}
                                    درصد تخفیف کارمزد، از حداکثر {festival[props.selectedCoin].maxExchange}
                                    واحد شما، {festRemaining}
                                    واحد دیگر تا پایان امروز باقی مانده است
                                </span>
                            </>
                        }
                        {(!!balances && balances.length > 0 && Number(balances.find(balance => balance.name === 'TOMAN')?.amount) < currenciesTopic[props.selectedCoin].minExchange) &&
                            <div className={`card box-shadow bgTertiaryLightColor text-white m-2`}>
                                <div className={`p-2 text-center`}>
                                    <i className="fas fa-exclamation-circle text-warning me-1"></i>
                                    <span className={`text-white mt-3`}>
                                    توجه داشته باشید مبلغ شارژ شما از حداقل معامله تتر ({currenciesTopic[props.selectedCoin].minExchange} واحد) کمتر می‌باشد
                                </span>
                                </div>
                            </div>
                        }
                        <div className={`card box-shadow bgTertiaryLightColor text-white m-2`}>
                            <div className={`p-2 text-center`}>
                                قیمت هر واحد:
                                <span className={` ss02`}>
                                    {' '}{Number(currenciesTopic[props.selectedCoin].currentPrice).toLocaleString('US')}
                                </span> تومان
                            </div>
                        </div>
                        <div className={`card box-shadow bgTertiaryLightColor text-white m-2`}>
                            <div className={`p-2 text-center`}>
                                حداقل معامله:
                                <span className={` ss02`}>
                                    {' '}{currenciesTopic[props.selectedCoin].minExchange}
                                </span> واحد
                            </div>
                        </div>
                        <div className={`card box-shadow bgTertiaryLightColor text-white m-2`}>
                            <div className={`p-2 text-center d-flex justify-content-center`} style={{gap: '0.5em'}}>
                                <div className={`d-inline-flex align-items-center ss02`}>موجودی کیف پول:</div>
                                {(!!balances && balances.length > 0) &&
                                    <div className={`d-inline-flex flex-column align-items-start justify-content-end`}>
                                        {!skeletonLoading ?
                                            <>
                                                <div className={`ss02`}>{' '}
                                                    {Number(balances.find(balance => balance.name === 'TOMAN')?.amount).toLocaleString('US')} تومان
                                                </div>
                                                <div className={`ss02`}>{' '}
                                                    {Number(balances.find(balance => balance.name === 'USDT')?.amount).toLocaleString('US')} تتر
                                                </div>
                                            </>
                                            : <Skeleton.Input active size={"small"}></Skeleton.Input>}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                            disabled={buyAmountValue === 0}
                            onClick={() => onApproveClick()}>
                        <i className="fas fa-check-circle me-2"></i>
                        تایید
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BuyAmount;