import React, {useState} from 'react';
import {message, notification, Upload} from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import {showLoadingAction, useAppDispatch} from "../../../../redux";
import {uploadDepositReceipt} from "../../../../shared/services/services";

const ApproveBankTransferDeposit = (props) => {
    let {bankTransferDepositAccount, tomanDepositAmount} = props;

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('تنها امکان آپلود فایل JPG/PNG می باشد');
        }
        const isLt2M = file.size / 1024 / 1024 < 15;
        if (!isLt2M) {
            message.error('حجم فایل بیشتر از 15 مگا بایت می باشد');
        }
        return isJpgOrPng && isLt2M;
    };
    const [imageUrl, setImageUrl] = useState<string>('');
    const [imageOriginalUrl, setImageOriginalUrl] = useState<RcFile>();
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'done') {
            setImageOriginalUrl(info.file.originFileObj);
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setImageUrl(url);
            });
        }
    };
    const uploadButton = <>{imageUrl ?
        <div className={`m-0`}><i className="fas fa-rotate me-2"></i> انتخاب مجدد</div> :
        <div className={`m-0`}><i className="fas fa-upload me-2"></i>بارگزاری فیش واریزی </div>}</>

    const dispatch = useAppDispatch();

    const onPayClick = async () => {
        if (!imageOriginalUrl) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا عکس فیش واریزی را انتخاب کنید',
                placement: 'bottomRight'
            });
            return
        }
        dispatch(showLoadingAction(true));
        let bodyFormData = new FormData();
        bodyFormData.append('receiptImage', imageOriginalUrl!);
        bodyFormData.append('value', tomanDepositAmount);
        bodyFormData.append('shebaNo', bankTransferDepositAccount['shebaNo']);
        if (imageOriginalUrl.size > 20971520) {
            notification['error']({
                message: 'خطا',
                description: 'حجم عکس، نمی تواند بیشتر از 20 مگابایت باشد',
                placement: 'bottomRight'
            });
            return
        }
        let response = await uploadDepositReceipt(bodyFormData)
        if (!!response && response.statusCode === 200) {
            props.onPayClick()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column mt-5 px-3 h-100`}>
                <div className={`w-100 mt-3`}>
                    <h3 className={`primaryColor fontWeightBold text-center`}>فیش واریزی</h3>
                    <div className={`w-100`}>
                        لطفا عکس رسید واریزی خود را ارسال کنید
                    </div>
                    {imageUrl && <img height="300"
                                      width="400"
                                      alt={'capturedImage'}
                                      className={`box-shadow border-1 mt-3`}
                                      src={imageUrl}/>}
                    <div className={`mt-3 d-flex align-items-center`}>
                        {imageUrl && <button
                            className={`btn btn-danger me-2 d-inline-flex align-items-center justify-content-center fontWeightBold`}
                            onClick={() => setImageUrl('')}>
                            <i className={`fas fa-trash`}></i>
                        </button>}
                        <Upload
                            name="avatar"
                            listType="picture"
                            className="avatar-uploader h-100 w-100 text-center"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}>
                            {uploadButton}
                        </Upload>
                    </div>
                </div>
                <div className={`w-100 mb-4`}>
                    <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                        <div className={`p-2 text-center`}>
                            توجه داشته باشید که تصویر کاملا واضح، در کادر دوربین و فاقد خط‌خوردگی باشد
                        </div>
                    </div>
                    <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                            disabled={!imageOriginalUrl}
                            onClick={() => onPayClick()}>
                        <i className={`fas fa-upload me-2`}></i>
                        آپلود فیش
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ApproveBankTransferDeposit;