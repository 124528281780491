import React, {useEffect} from 'react';
import {useNavigate} from "react-router";

const Custom404 = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (navigate) {
            navigate('/')
        }
    }, [navigate]);
    return <div className={`h-100 w-100 d-flex align-items-center justify-content-center fontSize2xlarge`}>
        صفحه مورد نظر پیدا نشد
    </div>;
};

export default Custom404;