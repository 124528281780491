import React, {useEffect, useState} from 'react';
import SelectCoin from "./select-coin/select-coin";
import TransactionType from "./transaction-type/transaction-type";
import BuyAmount from "./buy-amount/buy-amount";
import PreInvoice from "./pre-invoice/pre-invoice";
import SellAmount from "./sell-amount/sell-amount";
import {useSearchParams} from "react-router-dom";


const Transactions = (props) => {
    const [transactionTemplate, setTransactionTemplate] = useState('selectCoin');
    const [selectedCoin, setSelectedCoin] = useState('');
    const [transactionType, setTransactionType] = useState('');
    const [buyAmount, setBuyAmount] = useState(0);
    const [sellAmount, setSellAmount] = useState(0);
    const [searchParams,] = useSearchParams();

    const onCoinClick = (coin) => {
        setTransactionTemplate('transactionType');
        setSelectedCoin(coin)
    }
    const onBackClick = (template) => {
        setTransactionTemplate(template);
    }
    const afterSelectTransactionType = (type) => {
        setTransactionType(type)
        if (type === 'sell') {
            setTransactionTemplate('sellAmount')
        } else {
            setTransactionTemplate('buyAmount')
        }
    }
    const onBuyAmountApproveClick = (buyAmountValue) => {
        setBuyAmount(buyAmountValue)
        setTransactionTemplate('preInvoice');
    }
    const onSellAmountApproveClick = (sellAmountValue) => {
        setSellAmount(sellAmountValue)
        setTransactionTemplate('preInvoice');
    }

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !!queryParams.currency) {
            onCoinClick(queryParams.currency)
        }
    }, [searchParams])

    let transactionComponent;
    switch (transactionTemplate) {
        case 'selectCoin':
            transactionComponent = <SelectCoin onCoinClick={(coin) => onCoinClick(coin)}/>
            break
        case 'transactionType':
            transactionComponent = <TransactionType onBackClick={() => onBackClick('selectCoin')}
                                                    selectedCoin={selectedCoin}
                                                    onOpenAuthentication={() => props.onOpenAuthentication()}
                                                    afterSelectTransactionType={(type) => afterSelectTransactionType(type)}/>
            break
        case 'buyAmount':
            transactionComponent = <BuyAmount onBackClick={() => setTransactionTemplate('transactionType')}
                                              selectedCoin={selectedCoin}
                                              onApproveClick={(buyAmountValue) => onBuyAmountApproveClick(buyAmountValue)}/>
            break
        case 'sellAmount':
            transactionComponent = <SellAmount onBackClick={() => setTransactionTemplate('transactionType')}
                                               selectedCoin={selectedCoin}
                                               onApproveClick={(sellAmountValue) => onSellAmountApproveClick(sellAmountValue)}/>
            break;
        case 'preInvoice':
            transactionComponent = <PreInvoice onBackClick={() => afterSelectTransactionType(transactionType)}
                                               selectedCoin={selectedCoin}
                                               transactionType={transactionType.toUpperCase()}
                                               amount={transactionType === 'sell' ? sellAmount : buyAmount}
                                               onApproveClick={() => setTransactionTemplate('selectCoin')}
                                               onOpenWallet={() => props.onOpenWallet()}/>
            break;
    }
    return transactionComponent;
};

export default Transactions;