import React from 'react';
import {IMyWalletTransfer, IProfileProps} from "../../../../shared/interfacesAndTypes/interfaces";
import {selectDictionary, useAppSelector, userProfileSelector} from "../../../../redux";
import {Button, notification, Space} from "antd";

const MyWalletTransfer = (props: IMyWalletTransfer) => {
    const dictionary = useAppSelector(selectDictionary);
    const userProfile: IProfileProps = useAppSelector(userProfileSelector)
    const [api, contextHolder] = notification.useNotification();

    const onOpenAuthentication = (key) => {
        api.destroy(key)
        props.onOpenAuthentication?.()
    }
    const onDepositClick = () => {
        if (!!userProfile.userLevel?.id && userProfile.userLevel?.id === 4) {
            notification['error']({
                message: '',
                description: 'احرازهویت شما در دست بررسی می‌باشد، لطفا منتظر بمانید',
                placement: 'bottomRight'
            });
        } else if (!!userProfile.userLevel?.id && userProfile.userLevel?.id < 4) {
            const key = `open${Date.now()}`;
            const btn = <Space>
                <Button type="primary" size="small" onClick={() => onOpenAuthentication(key)}>احراز هویت</Button>
            </Space>;
            api.warning({
                message: 'احراز هویت ناقص',
                description: 'جهت واریز تومان لطفا ابتدا احرازهویت خود را تکمیل کنید',
                btn,
                key,
                placement: 'bottomRight'
            });
        } else {
            props.onDepositClick?.(props.selectedCoin)
        }
    }

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            {contextHolder}
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`mt-5 px-3`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>کیف پول من</h3>
                <div
                    className={`d-flex align-items-center justify-content-between p-3 bgTertiaryLightColor mt-4 border-radius-7 cursor-pointer`}>
                    <span className={`fontWeightBold fa-lg`}>{dictionary.currencies[props.selectedCoin.name]}</span>
                    <span className={`text-white ss02`}>{Number(props.selectedCoin.amount).toLocaleString('US')}</span>
                </div>
                <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                        onClick={() => props.onWithdrawalClick?.(props.selectedCoin)}>
                    برداشت {` `} {dictionary.currencies[props.selectedCoin.name]}
                </button>
                <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                        onClick={onDepositClick}>
                    واریز {` `} {dictionary.currencies[props.selectedCoin.name]}
                </button>
            </div>
        </div>
    );
};

export default MyWalletTransfer;