import React, {useState} from 'react';
import styles from './footer.module.scss'
import {Modal} from "antd";
import useWindowDimensions from "../dimensions/dimensions";
import Roles from "../roles/roles";
import {useNavigate} from "react-router";

const Footer = () => {
    const [rolesOpen, setRolesOpen] = useState(false);
    const navigate = useNavigate();

    const width = useWindowDimensions();
    let modalWidth: number | string;
    if (width > 1400) {
        modalWidth = 960
    } else if (width > 1200) {
        modalWidth = 720
    } else if (width > 992) {
        modalWidth = 540
    } else if (width > 768) {
        modalWidth = 320
    } else {
        modalWidth = '100%'
    }

    const modalStyle: React.CSSProperties = {
        marginTop: '60px',
        marginBottom: '60px',
    }
    const navigator = (path) => {
        navigate(path)
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    return (
        <div className={`pt-5 d-flex flex-column align-item-center justify-content-around footer`}>
            <div className={`row`}>
                <div className={`col-md-4`}>
                    <h4 className={`text-white`}> لینک های مرتبط</h4>
                    <ul className={`list-group me-4 mt-3 footer-bg`}>
                        <li className={`list-group-item footer-bg border-0 text-muted cursor-pointer ${styles.listGroupItem}`}
                            onClick={() => navigator('/about-us')}>درباره ما
                        </li>
                        <li className={`list-group-item footer-bg border-0 text-muted cursor-pointer ${styles.listGroupItem}`}
                            onClick={() => navigator('/contact-us')}>تماس با ما
                        </li>
                        <li className={`list-group-item footer-bg border-0 text-muted cursor-pointer ${styles.listGroupItem}`}
                            onClick={() => setRolesOpen(true)}>قوانین و مقررات
                        </li>
                        <li className={`list-group-item footer-bg border-0 text-muted cursor-pointer ${styles.listGroupItem}`}
                            onClick={() => navigator('/faq')}>سوالات متداول
                        </li>
                        <li className={`list-group-item footer-bg border-0 text-muted cursor-pointer ${styles.listGroupItem}`}
                            onClick={() => navigator('/tlg-bot')}>ربات تلگرام
                        </li>
                    </ul>
                </div>
                <div className={`col-md-8 ps-3 fontSizeSmall lh-lg text-semi-muted`}>
                    بیت بلکس با تیمی مجرب که بیش از 18 سال سابقه در عرصه کامپیوتر و بازارهای دیجیتال دارند تلاش کرده،
                    محیطی را برای فعالان حوزه رمز ارز فراهم کند تا بتوانید با امنیت بالا و خارج از هرگونه محدودیت و
                    نگرانی دارایی دیجیتال خود را تبدیل کنید، ما تمام تلاش خود را برای سرویس دهی هرچه بهتر با ارائه سبد
                    محصولات کوچکتر شروع کردیم و نیز بیشترین توجه خود را بر خدمات پشتیبانی مشتریان عزیزمان خواهیم گذاشت .
                </div>
            </div>
            <div className={`text-center ${styles.rights} text-muted py-3 mt-4`}>کلیه حقوق وب سایت، متعلق به بیتبلکس می
                باشد
            </div>
            <Modal style={modalStyle}
                   title="قوانین و مقررات"
                   centered
                   open={rolesOpen}
                   onCancel={() => setRolesOpen(false)}
                   width={modalWidth}>
                <Roles/>
            </Modal>
        </div>
    );
};

export default Footer;
