import React, { useCallback } from 'react';
import style from './support.module.scss';
import { useNavigate } from "react-router";

const Support = () => {

    const navigate = useNavigate();

    const navigator = (path) => {
        navigate(path)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    const openGoftino = useCallback(() => {
        window["Goftino"].open();
    }, []);

    return (
        <div className={`${style.mainContainer} p-4`}>
            <h4 className={`fontWeightBold`}>ارتباط با تیم پشتیبانی بیت بلکس</h4>
            <div className={`d-flex flex-column flex-lg-row align-items-center justify-content-around mt-4 mt-lg-5`}>
                <div className={`row mt-4 mt-lg-0 mx-0 w-100`}>
                    <div className={`d-flex flex-column col-9`}>
                        <span className={`fontWeightBold`}>راهنما و سوالات متداول کاربران </span>
                        <span className={`fontSizeTiny mt-3`}>بسیاری از سوالات شما در بخش سوالات متداول قرار گرفته است</span>
                        <span className={`mt-3`}>
                            <button className={`btn btn-primary py-1 fontSizeSmall`}
                                onClick={() => navigator('/faq')}>
                                سوالات متداول<i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                            </button>
                        </span>
                    </div>
                    <div className={`col-3 mt-3 mt-lg-0`}>
                        <img src="/img/support-answer.png" alt="support-answer" />
                    </div>
                </div>
                <div className={`row mt-4 mt-lg-0 ${style.section2} mx-0 w-100`}>
                    <div className={`d-flex flex-column col-9 pt-4 pt-lg-0`}>
                        <span className={`fontWeightBold`}>پشتیبانی آنلاین</span>
                        <span className={`fontSizeTiny mt-3 ss02`}>همه روزه از ساعت 8 الی 24 پاسخگو شما هستیم</span>
                        <span className={`mt-3`}>
                            <button
                                className={`btn btn-primary py-1 fontSizeSmall`} onClick={openGoftino}>
                                شروع چت آنلاین<i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                            </button>
                        </span>
                    </div>
                    <div className={`col-3 mt-3 mt-lg-0`}>
                        <img src="/img/phone-support.png" alt="support-comment" />
                    </div>
                </div>
                <div className={`row mt-4 mt-lg-0 ${style.section2} mx-0 w-100`}>
                    <div className={`d-flex flex-column col-9 pt-4 pt-lg-0`}>
                        <span className={`fontWeightBold`}>پشتیبانی تلگرام</span>
                        <span className={`fontSizeTiny mt-3 ss02`}>همه روزه از ساعت 8 الی 24 پاسخگو شما هستیم</span>
                        {/* <span className={`fontWeightBold mt-3 ss02`}>
                            021-41478888
                        </span> */}
                    </div>
                    <div className={`col-3 mt-3 mt-lg-0`}>
                        <img src="/img/support-comment.png" alt="support-phone" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
