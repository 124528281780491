import React, {useState} from 'react';
import FavWithdrawalList from "./fav-withdrawal-list/fav-withdrawal-list";
import AddFavWithdrawal from "./add-fav-withdrawal/add-fav-withdrawal";

export enum currencyTypeEnum {
    TOMAN = 'TOMAN',
    CRYPTO = 'CRYPTO'
}

const FavWithdrawals = () => {
    const [componentName, setComponentName] = useState('favWithdrawalList');

    if (componentName === 'favWithdrawalList') {
        return <FavWithdrawalList onAddFavWithdrawalClick={() => setComponentName('addFavWithdrawal')}
                                  selectable={false}/>
    }
    return <AddFavWithdrawal onBackClick={() => setComponentName('favWithdrawalList')}/>
};

export default FavWithdrawals;
