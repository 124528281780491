import React from 'react';
import AboutUs from "../pages/about-us/about-us";
import HelmetHOC from "./helmet-HOC";

const AboutUsHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - درباره ما"/>
        <AboutUs/>
    </>
};

export default AboutUsHOC;