import {createAction} from "@reduxjs/toolkit"
import {IDictionary, IProfileProps} from "../shared/interfacesAndTypes/interfaces";

export const tokenAction = createAction<string>('authentication/saveToken')
export const clearStore = createAction('authentication/clearStore')
export const userMobileAction = createAction<string>('authentication/mobile')
export const otpCodeAction = createAction<string>('authentication/otpCode')
export const joinRefAction = createAction<string>('authentication/joinRef')
export const userProfileAction = createAction<IProfileProps>('global/profile')
export const showLoadingAction = createAction<boolean>('global/loading')
export const dictionaryAction = createAction<IDictionary>('global/dictionary')
export const currenciesTopicAction = createAction<{}>('exchange/currenciesTopic')
export const festivalTopicAction = createAction<{}>('exchange/festivalTopic')
export const unreadMessagesAction = createAction<number>('exchange/unreadMessages')