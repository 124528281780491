import React, {useEffect, useState} from 'react';
import Input from 'antd/lib/input';
import style from './base-section.module.scss';
import {selectCurrenciesTopic, selectIsAuthenticated, useAppSelector} from "../../../redux";
import {InputNumber, notification, Skeleton} from "antd";
import {useNavigate} from "react-router";
import {createSearchParams} from "react-router-dom";
import {irPhoneNoRegex} from "../../../shared/regex-and-validations";

const BaseSection = (props) => {
    const {dictionary} = props;
    const [firstAddon, setFirstAddon] = useState('')
    const [secondAddon, setSecondAddon] = useState('')
    const [firstNumber, setFirstNumber] = useState<number | null>(null)
    const [secondNumber, setSecondNumber] = useState<number | null>(null)
    const currenciesTopic = useAppSelector(selectCurrenciesTopic);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const navigate = useNavigate();
    const [mobile, setMobile] = useState<string | undefined>(undefined)

    const onChangeFirstNumber = (value) => {
        setFirstNumber(value)
        if (firstAddon === dictionary.currencies['toman']) {
            setSecondNumber(value / parseInt(currenciesTopic['USDTTRC20'].currentPrice))
        } else {
            setSecondNumber(value * parseInt(currenciesTopic['USDTTRC20'].currentPrice))
        }
    };
    const onChangeSecondNumber = (value) => {
        setSecondNumber(value)
        if (firstAddon === dictionary.currencies['toman']) {
            setFirstNumber(value * parseInt(currenciesTopic['USDTTRC20'].currentPrice))
        } else {
            setFirstNumber(parseFloat((value / parseInt(currenciesTopic['USDTTRC20'].currentPrice)).toFixed(6)))
        }
    };
    const onTransactionClick = async () => {
        if (isAuthenticated) {
            let query;
            if (firstAddon === dictionary.currencies['USDT'] && !!firstNumber) {
                query = {
                    currency: "USDTTRC20",
                    currencyValue: firstNumber.toString()
                }
            } else if (firstAddon !== dictionary.currencies['USDT'] && !!secondNumber) {
                query = {
                    currency: "USDTTRC20",
                    currencyValue: secondNumber.toString()
                }
            }
            await navigate({
                pathname: "/exchange",
                search: createSearchParams(query).toString()
            })
        } else {
            props.openLoginDrawer()
        }
    }
    const onChangeMobile = (e) => {
        setMobile(e.target.value);
    };
    const onStartClick = async () => {
        if (isAuthenticated) {
            await navigate("/exchange")
        } else {
            if (mobile && mobile.match(irPhoneNoRegex)) {
                props.openLoginDrawer(mobile)
            } else {
                notification['error']({
                    message: '',
                    description: 'لطفا شماره موبایل را به صورت صحیح وارد کنید',
                    placement: 'bottomRight'
                });
            }
        }
    }

    useEffect(() => {
        setFirstAddon(dictionary.currencies['USDT'])
        setSecondAddon(dictionary.currencies['toman'])
        if (currenciesTopic['USDTTRC20']) {
            setSecondNumber(parseInt(currenciesTopic['USDTTRC20'].currentPrice))
        }
    }, [currenciesTopic, dictionary])

    return (
        <div className={`d-block ${style.container}`}>
            <div className={`row d-flex justify-content-between`}>
                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-5 p-2 mt-4 mt-md-0">
                    <div className={`fontWeightBold fontSizeXlarge`}>
                        <div className="text-start">ساده، راحت، مطمئن</div>
                        <div className="text-start">تتر خود را تبدیل کنید</div>
                    </div>
                    <div className="fontSizeNormal mt-3 lh-base text-semi-muted">
                        شما می توانید به راحتی دارایی دیجیتالی خود را به بهترین نرخ تبدیل و از خدمات ویژه ما استفاده
                        کنید
                    </div>
                    <div className="fontSizeMini mt-3 lh-base">
                        همین الان شروع کنید
                    </div>
                    <div className={`${style.startNow} d-flex align-items-center mt-2`}>
                        <i className={`fas fa-mobile-alt text-white`}></i>
                        <Input placeholder={`لطفا شماره موبایل خود را وارد کنید`}
                               className={`${style.startNowInput} text-white`}
                               bordered={false}
                               value={mobile}
                               onChange={onChangeMobile}
                        />
                        <button className={`btn btn-primary py-1 fontSizeSmall`} onClick={onStartClick}>
                            شروع
                            <i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                        </button>
                    </div>
                </div>
                <div className={`col-sm-12 col-md-12 col-lg-5 col-xl-4 mt-3 mt-sm-0`}>
                    <div className={`card box-shadow h-100 ${style.cardExchange}`}>
                        <div className={`card-body d-flex flex-column justify-content-between`}>
                            <Input.Group compact className={`d-flex mt-2`}>
                                {(!!firstAddon && firstAddon.length > 0) ? <InputNumber bordered={false}
                                                                                        className={`inputs border-radius-7 w-100 ss02`}
                                                                                        placeholder={`تعداد مورد نظر را وارد کنید`}
                                                                                        addonAfter={firstAddon}
                                                                                        value={firstNumber}
                                                                                        min={0}
                                                                                        controls={false}
                                                                                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                        onChange={onChangeFirstNumber}/> :
                                    <Skeleton active loading={true}></Skeleton>}
                            </Input.Group>
                            <div className={`d-flex align-items-center mt-2`}>
                                {(!!currenciesTopic && !!currenciesTopic['USDTTRC20']) &&
                                    <span className={`me-auto fontSizeSmall ss02`}>
                                        1 {dictionary.currencies['USDT']} {' '}
                                        =
                                        {' '} {Number(currenciesTopic['USDTTRC20'].currentPrice).toLocaleString('US')} {' '} تومان
                                    </span>}
                            </div>
                            <Input.Group compact className={`d-flex mt-2`}>
                                {(!!secondAddon && secondAddon.length > 0) ? <InputNumber bordered={false}
                                                                                          className={`inputs border-radius-7 font-weight-bold w-100 ss02`}
                                                                                          placeholder={`تعداد مورد نظر را وارد کنید`}
                                                                                          addonAfter={secondAddon}
                                                                                          value={secondNumber}
                                                                                          min={0}
                                                                                          controls={false}
                                                                                          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                                                          onChange={onChangeSecondNumber}/> :
                                    <Skeleton active loading={true}></Skeleton>}
                            </Input.Group>
                            <button onClick={() => onTransactionClick()}
                                    className={`btn btnSecondary d-flex align-items-center justify-content-center mt-3 w-100`}>
                                خرید و فروش
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BaseSection;
