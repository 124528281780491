import React, {useState} from 'react';
import styles from './drawer.module.scss';
import {Property} from "csstype";
import useWindowDimensions from "../dimensions/dimensions";
import {useLocation} from "react-router";

interface Props {
    children?: JSX.Element;
    helpComponent?: JSX.Element;
    borderTopColor?: Property.BorderTopColor;
    showCloseButton?: boolean;
    childrenClassNames?: string;
    helpComponentClassNames?: string;
    childrenPosition: 'right' | 'left';
    closeButtonPosition: 'helpSide' | 'childrenSide' | 'both';

    onCloseClick?(): any;
}

const DrawerComponent = (props: Props) => {
    const {
        children,
        helpComponent,
        borderTopColor,
        showCloseButton,
        childrenClassNames,
        helpComponentClassNames,
        childrenPosition,
        closeButtonPosition
    } = props;
    const width = useWindowDimensions();
    const [closeAnimation, setCloseAnimation] = useState(false)
    const onCloseClick = async () => {
        setCloseAnimation(true)
        await new Promise(r => setTimeout(r, 150))
        props.onCloseClick?.()
    }
    const location = useLocation();

    return (
        <div
            className={`position-absolute h-100 w-100 row p-0 m-0 ${(width > 768 && childrenPosition === 'left' && location.pathname === '/exchange') ? styles.left137 : styles.left0} overflow-y`}
            style={{zIndex: 2000}}>
            {childrenPosition === 'right' &&
                <div
                    className={`col-sm-12 col-md-5 col-lg-4 col-xl-3 ${styles.sideLeft} ${childrenClassNames} position-relative ${styles.rightChildrenOpenAnimation} ${closeAnimation && styles.rightChildrenCloseAnimation}`}
                    style={{borderTopColor: !!borderTopColor ? borderTopColor : '#10d078'}}>
                    {(showCloseButton && (closeButtonPosition === 'both' || closeButtonPosition === 'childrenSide')) &&
                        <i onClick={() => onCloseClick()}
                           className={`${styles.closeIcon} ${styles.left15} fontSizeLarge fas fa-close cursor-pointer`}></i>}
                    {children}
                </div>}
            <div className={`${styles.colSm0} col-md-7 col-lg-8 col-xl-9 p-0  ${helpComponentClassNames} position-relative 
                            ${childrenPosition === 'right' ? styles.leftChildrenOpenAnimation : styles.rightChildrenOpenAnimation}
                            ${(childrenPosition === 'right' && closeAnimation) ? styles.leftChildrenCloseAnimation : ((childrenPosition === 'left' && closeAnimation) && styles.rightChildrenCloseAnimation)}`}>
                {(showCloseButton && (closeButtonPosition === 'both' || closeButtonPosition === 'helpSide')) &&
                    <i onClick={() => onCloseClick()}
                       className={`${styles.closeIcon} ${childrenPosition === 'right' ? styles.right15 : styles.left15} fontSizeLarge fas fa-close cursor-pointer`}></i>}
                <div onClick={() => onCloseClick()}
                     className={`${styles.blur} position-absolute h-100 w-100`}></div>
                {helpComponent}
            </div>
            {childrenPosition === 'left' &&
                <div
                    className={`col-sm-12 col-md-5 col-lg-4 col-xl-3 ${styles.sideLeft} ${childrenClassNames} position-relative ${styles.leftChildrenOpenAnimation} ${closeAnimation && styles.leftChildrenCloseAnimation}`}
                    style={{borderTopColor: !!borderTopColor ? borderTopColor : '#10d078'}}>
                    {(showCloseButton && (closeButtonPosition === 'both' || closeButtonPosition === 'childrenSide')) &&
                        <i onClick={() => onCloseClick()}
                           className={`${styles.closeIcon} ${styles.right15} fontSizeLarge fas fa-close cursor-pointer`}></i>}
                    {children}
                </div>}
        </div>
    );
};

export default DrawerComponent;