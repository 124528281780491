import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, notification, Radio, Space} from "antd";
import {validateAddressFunc} from "../../../../shared/regex-and-validations";
import {selectDictionary, showLoadingAction, useAppDispatch, useAppSelector} from "../../../../redux";
import {WithdrawalAccountTypeEnum} from "../withdrawal-toman/withdrawal-toman";
import FavWithdrawalList from "../../fav-withdrawals/fav-withdrawal-list/fav-withdrawal-list";
import {currencyTypeEnum} from "../../fav-withdrawals/fav-withdrawals";
import {IFavWithdrawal} from "../../../../shared/interfacesAndTypes/interfaces";
import {addFavWithdrawalCrypto} from "../../../../shared/services/services";

const WithdrawalUsdt = (props) => {
    const dictionary = useAppSelector(selectDictionary);
    const [withdrawalAccountType, setWithdrawalAccountType] = useState(WithdrawalAccountTypeEnum.favAccountList);
    const [USDTWithdrawalAmount, setUSDTWithdrawalAmount] = useState(0);
    const [favWithdrawals, setFavWithdrawals] = useState<Array<IFavWithdrawal>>([{
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    }]);
    const [favWithdrawal, setFavWithdrawal] = useState<IFavWithdrawal>({
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    });
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();
    const dispatch = useAppDispatch();
    const [showRadios, setShowRadios] = useState(true);

    const onNotificationCancelAndNextClick = useCallback(() => {
        notification.destroy()
        props.onNextClick({USDTWithdrawalAmount, address: form.getFieldsValue().walletAddress})
    }, [USDTWithdrawalAmount, form, props])
    const onNotificationApproveAndNextClick = useCallback(async () => {
        dispatch(showLoadingAction(true))
        let res = await addFavWithdrawalCrypto({address: form.getFieldsValue().walletAddress})
        if (!!res && res.statusCode === 200) {
            props.onNextClick({USDTWithdrawalAmount, address: res.data['address']})
            notification.destroy()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }, [USDTWithdrawalAmount, dispatch, form, props])
    const openNotification = useCallback(() => {
        const key = `open${Date.now()}`;
        const btn = <Space>
            <Button type="link" size="small" onClick={() => onNotificationCancelAndNextClick()}>
                عدم ذخیره و ادامه
            </Button>
            <Button type="primary" size="small" onClick={() => onNotificationApproveAndNextClick()}>
                ذخیره و ادامه
            </Button>
        </Space>;
        api.open({
            message: 'کیف پول جدید',
            description:
                'آدرس کیف پول وارد شده در لیست حساب های برگزیده شما وجود ندارد.آیا تمایل دارید به حساب برگزیده شما اضافه شود؟',
            btn,
            key,
            placement: 'bottomRight'
        });
    }, [api, onNotificationApproveAndNextClick, onNotificationCancelAndNextClick]);
    const onNextClick = useCallback((e?) => {
        if (USDTWithdrawalAmount === 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا میزان ارز درخواستی را وارد کنید',
                placement: 'bottomRight'
            });
            return;
        }
        let usdtBalance = props.balances.find(balance => balance.name === 'USDT');
        if (parseInt(usdtBalance.amount) < USDTWithdrawalAmount) {
            notification['error']({
                message: 'مبلغ درخواستی از مبلغ کیف پول شما بیشتر است',
                description: `موجودی کیف پول: ${Number(usdtBalance.amount).toLocaleString('US')}`,
                placement: 'bottomRight'
            });
            return
        }
        if (withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList) {
            if (favWithdrawal.address.length === 0) {
                notification['error']({
                    message: 'خطا',
                    description: 'لطفا آدرس کیف پول مورد نظر جهت برداشت را انتخاب کنید',
                    placement: 'bottomRight'
                });
            } else {
                props.onNextClick({USDTWithdrawalAmount, address: favWithdrawal.address})
            }
            return
        }
        if (withdrawalAccountType === WithdrawalAccountTypeEnum.addNewFavAccount) {
            if (!!favWithdrawals && !favWithdrawals.find(item => item.address === favWithdrawal.address)) {
                openNotification();
            } else {
                props.onNextClick({USDTWithdrawalAmount, address: e.address})
            }
            return;
        }
    }, [USDTWithdrawalAmount, favWithdrawal.address, favWithdrawals, openNotification, props, withdrawalAccountType])
    const onLoadFavWithdrawals = (data) => {
        setFavWithdrawals(data);
        if (data.length === 0) {
            setShowRadios(false)
            setWithdrawalAccountType(WithdrawalAccountTypeEnum.addNewFavAccount)
        } else {
            setShowRadios(true)
            setWithdrawalAccountType(WithdrawalAccountTypeEnum.favAccountList)
        }
    }
    const onChangeUSDTWithdrawalAmount = (amount) => {
        if (!!amount) {
            setUSDTWithdrawalAmount(amount)
        } else {
            setUSDTWithdrawalAmount(0)
        }
    };
    const onFormFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ? onNextClick() : form.submit()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [form, onNextClick, withdrawalAccountType]);

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>برداشت تتر</h3>
                <h6 className={`text-white mt-3`}>میزان ارز درخواستی</h6>
                <div className={`d-inline-flex w-100`}>
                    <InputNumber bordered={false}
                                 placeholder='مقدار'
                                 onChange={(amount) => onChangeUSDTWithdrawalAmount(amount)}
                                 controls={false}
                                 min={0}
                                 addonAfter={dictionary.currencies['USDT']}
                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 className={`inputs w-100 border-radius-7 ss02`}/>
                </div>
                {showRadios && <div className={`mt-3 w-100`}>
                    <Radio.Group className={`w-100`}
                                 onChange={(e) => setWithdrawalAccountType(e.target.value)}
                                 value={withdrawalAccountType}>
                        <Radio className={`text-white me-2`}
                               value={WithdrawalAccountTypeEnum.favAccountList}>آدرس برگزیده</Radio>
                        <Radio className={`text-white`}
                               value={WithdrawalAccountTypeEnum.addNewFavAccount}>آدرس جدید</Radio>
                    </Radio.Group>
                </div>}
                {withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ?
                    <FavWithdrawalList favType={currencyTypeEnum.CRYPTO}
                                       onLoadFavWithdrawals={(data) => onLoadFavWithdrawals(data.filter(item => item.type === currencyTypeEnum.CRYPTO))}
                                       onSelectFavWithdrawal={(favWithdrawal) => setFavWithdrawal(favWithdrawal)}
                                       selectable={true}/> :
                    <>
                        <h6 className={`text-white mt-3`}>آدرس کیف پول مورد نظر برای برداشت را وارد کنید</h6>
                        <Form className={`mt-3 w-100`}
                              form={form}
                              name="tronBlockchainNetwork"
                              onFinish={onNextClick}
                              onFinishFailed={onFormFailed}
                              labelCol={{span: 25}}
                              wrapperCol={{span: 25}}
                              autoComplete="off">
                            <Form.Item
                                label=""
                                name="walletAddress"
                                rules={[
                                    {required: true, message: 'لطفا آدرس کیف پول را وارد کنید!'},
                                    {
                                        message: 'آدرس کیف پول نا معتبر است',
                                        validator: async (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (!!value && value.length === 34) {
                                                dispatch(showLoadingAction(true))
                                                const validateAddress = await validateAddressFunc(value);
                                                if (validateAddress && validateAddress.result) {
                                                    dispatch(showLoadingAction(false))
                                                    return Promise.resolve()
                                                } else {
                                                    dispatch(showLoadingAction(false))
                                                    form.setFields([{
                                                        name: 'walletAddress',
                                                        errors: ['آدرس کیف پول نا معتبر است']
                                                    }])
                                                    return Promise.reject('آدرس کیف پول نا معتبر است');
                                                }
                                            } else {
                                                form.setFields([{
                                                    name: 'walletAddress',
                                                    errors: ['طول رشته آدرس کیف پول نا معتبر است']
                                                }])
                                                return Promise.reject('طول رشته آدرس کیف پول نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false}
                                       className={`inputs w-100 border-radius-7 `}/>
                            </Form.Item>
                        </Form>
                    </>}
            </div>
            <div className={`w-100 d-flex justify-content-center mb-2`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center`}>مقدار و کیف ‌پول مقصد را مشخص کنید</div>
                </div>
            </div>
            <div className={`w-100 d-flex justify-content-center mb-3`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center ss02`}>موجودی کیف
                        پول: {Number(props.balances.find(balance => balance.name === 'USDT').amount).toLocaleString('US')} تتر
                    </div>
                </div>
            </div>
            <div className={`d-flex w-100 mb-4`}>
                {contextHolder}
                <button className={`btn btnPrimary w-100 fontWeightBold`}
                        onClick={() => withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ? onNextClick() : form.submit()}>
                    مرحله بعد
                </button>
            </div>
        </div>
    );
};

export default WithdrawalUsdt;