import {createSelector} from '@reduxjs/toolkit';
import {RootState} from "./store";

export const selectToken = (state: RootState) => state.token.token;
export const selectIsAuthenticated = (state: RootState) => state.token.hasToken;
export const selectUserMobile = (state: RootState) => state.userMobile.userMobile;
export const selectOtpCode = (state: RootState) => state.otpCode.otpCode;
export const selectJoinRef = (state: RootState) => state.joinRef.joinRef;
export const selectUserProfile = (state: RootState) => state.userProfile.profile;
export const selectShowLoading = (state: RootState) => state.showLoading.showLoading;
export const selectDictionary = (state: RootState) => state.dictionary;
export const selectCurrenciesTopic = (state: RootState) => state.currenciesTopic.currencies;
export const selectFestivalTopic = (state: RootState) => state.festivalTopic.festivalTopic;
export const selectUnreadMessage = (state: RootState) => state.unreadMessages.unreadMessage;

export const tokenSelector = createSelector(selectToken, state => state);
export const userMobileSelector = createSelector(selectUserMobile, state => state);
export const userProfileSelector = createSelector(selectUserProfile, state => state);
export const showLoadingSelector = createSelector(selectShowLoading, state => state);
export const dictionarySelector = createSelector(selectDictionary, state => state);
