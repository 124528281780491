import React, {useEffect, useState} from 'react';
import {selectDictionary, selectToken, useAppSelector} from "../../../redux";
import {getTransactionById} from "../../../shared/services/services";
import {Skeleton} from "antd";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

const TransactionStatusModal = () => {
    const [transActionStatus, setTransActionStatus] = useState({});
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const token = useAppSelector(selectToken);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !!queryParams.txPopupId) {
            getTransactionById(queryParams.txPopupId, token).then(response => {
                if (!!response && response.data) {
                    setTransActionStatus(response.data)
                    setSkeletonLoading(false)
                } else {
                    setSkeletonLoading(false)
                }
            }).catch(r => console.log(r))
            navigate(location.pathname);
        }
    }, [location, navigate, searchParams, token])

    const dictionary = useAppSelector(selectDictionary);
    const transactionTypes = [{name: 'deposit'}, {name: 'withdrawal'}, {name: 'exchange'}];
    const statuses = [{code: 1}, {code: 0}, {code: -1}, {code: -2}];
    const renderTransactionTypeBadge = (historyType) => {
        let transactionBadge;
        switch (historyType) {
            case transactionTypes[0].name:
                transactionBadge = <span
                    className={`bg-success text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[0].name]}</span>
                break;
            case transactionTypes[1].name:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[1].name]}</span>
                break;
            case transactionTypes[2].name:
                transactionBadge = <span
                    className={`bg-secondary text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[2].name]}</span>
                break;
            case transactionTypes[3].name:
                transactionBadge = <span
                    className={`bg-secondary text-white px-2 box-shadow`}>{dictionary.transaction[transactionTypes[3].name]}</span>
                break;
        }
        return transactionBadge;
    }
    const renderTransactionStatusBadge = (historyStatus) => {
        let transactionBadge;
        switch (historyStatus) {
            case statuses[0].code:
                transactionBadge = <span
                    className={`bg-success text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[0].code]}</span>
                break;
            case statuses[1].code:
                transactionBadge = <span
                    className={`bg-warning text-dark px-2 box-shadow`}>{dictionary.transaction['status'][statuses[1].code]}</span>
                break;
            case statuses[2].code:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[2].code]}</span>
                break;
            case statuses[3].code:
                transactionBadge = <span
                    className={`bg-danger text-white px-2 box-shadow`}>{dictionary.transaction['status'][statuses[3].code]}</span>
                break;
        }
        return transactionBadge;
    }
    const onExpandTransactionClick = (transaction) => {
        let template;
        switch (transaction.type) {
            case transactionTypes[0].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز: </span>
                            <span className={`fontSizeMini`}>{dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار: </span>
                            <span
                                className={`fontSizeMini `}>{Number(transaction['value']).toLocaleString('US')}</span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span className={`fontSizeMini`}>{transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini `}>{transaction['_id']}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه خارجی: </span>
                            <span className={`fontSizeMini `}>{transaction['txId']}</span>
                        </div>
                    </div>
                </div>
                break;
            case transactionTypes[1].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار: </span>
                            <span
                                className={`fontSizeMini `}>{Number(transaction['value']).toLocaleString('US')}</span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span className={`fontSizeMini`}>{transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini `}>{transaction['_id']}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه خارجی: </span>
                            <span className={`fontSizeMini `}>{transaction['txId']}</span>
                        </div>
                    </div>
                </div>
                break;
            case transactionTypes[2].name:
                template = <div className={`card box-shadow bgTertiaryColor`}>
                    <div className={`card-body`}>
                        <div className={`text-white`}>
                            <i className={`fas fa-shield me-2`}></i>
                            <span className={`fontSizeNormal`}>نوع تراکنش: </span>
                            {renderTransactionTypeBadge(transaction.type)}
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-cash-register me-2`}></i>
                            <span className={`fontSizeNormal`}>نرخ تبدیل: </span>
                            <span
                                className={`fontSizeMini `}>{Number(transaction['exchangeRate']).toLocaleString('US')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز پرداختی: </span>
                            <span className={`fontSizeMini`}>
                                <span className={``}>{transaction['pairValue']}</span>
                                {dictionary.currencies[transaction['pairCurrency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-coins me-2`}></i>
                            <span className={`fontSizeNormal`}>ارز دریافتی: </span>
                            <span
                                className={`fontSizeMini`}><span
                                className={``}>{Number(transaction['value']).toLocaleString('US')}</span> {dictionary.currencies[transaction['currency']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-weight-hanging me-2`}></i>
                            <span className={`fontSizeNormal`}>مقدار کارمزد: </span>
                            <span className={`fontSizeMini`}>
                                <span
                                    className={``}>{transaction['fee']['value']}</span> {dictionary.currencies[transaction['fee']['currency']]}
                            </span>
                        </div>
                        <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                        <div className={`text-white`}>
                            <i className={`fas fa-building-columns me-2`}></i>
                            <span className={`fontSizeNormal`}>منبع: </span>
                            <span
                                className={`fontSizeMini`}>{dictionary.transaction['source'][transaction['source']]}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>وضعیت: </span>
                            {renderTransactionStatusBadge(transaction.status)}
                        </div>
                        {transaction.status === -1 && <div className={`text-white`}>
                            <i className={`fas fa-question me-2`}></i>
                            <span className={`fontSizeNormal`}>دلیل لغو: </span>
                            {transaction.rejectReason}
                        </div>}
                        <div className={`text-white`}>
                            <i className={`fas fa-calendar-plus me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ ایجاد: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['createdAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-pen-to-square me-2`}></i>
                            <span className={`fontSizeNormal`}>تاریخ بروزرسانی: </span>
                            <span
                                className={`fontSizeMini`}>{new Date(transaction['updatedAt']).toLocaleDateString('fa-IR')}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-comment me-2`}></i>
                            <span className={`fontSizeNormal`}>توضیحات: </span>
                            <span className={`fontSizeMini`}>{transaction.description}</span>
                        </div>
                        <div className={`text-white`}>
                            <i className={`fas fa-hashtag me-2`}></i>
                            <span className={`fontSizeNormal`}>شناسه داخلی: </span>
                            <span className={`fontSizeMini`}>{transaction._id}</span>
                        </div>
                    </div>
                </div>
                break;
        }
        return template;
    }
    return <>
        {!skeletonLoading ?
            <div> {onExpandTransactionClick(transActionStatus)}</div> :
            <Skeleton active loading={true}></Skeleton>
        }
    </>

};

export default TransactionStatusModal;