import React, { useEffect, useState } from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js'
import {
    selectCurrenciesTopic,
    selectDictionary,
    selectIsAuthenticated,
    showLoadingAction,
    useAppDispatch,
    useAppSelector
} from "../../../redux";
import { ChartOptions } from "chart.js/dist/types";
import style from './pricing.module.scss';
import { Line } from "react-chartjs-2";
import { getChartData } from "../../../shared/services/services";
import { createSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import ChartModal from "../../../shared/chart-modal/chart-modal";
import { Modal } from "antd";
import useWindowDimensions from "../../../shared/dimensions/dimensions";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)
const Pricing = (props) => {
    const [chartLabels, setChartLabels] = useState<Array<string>>([]);
    const [chartFilterLabels, setChartFilterLabels] = useState<Array<string>>([]);
    const [chartData, setChartData] = useState([]);
    const [chartState, setChartState] = useState<any>({
        labels: [""],
        datasets: [{
            label: 'قیمت',
            fill: true,
            backgroundColor: '#000',
            data: []
        }]
    });
    const lineConfig: ChartOptions<"line"> = {
        responsive: true,
        maintainAspectRatio: false,
        layout: { padding: { left: 8, right: 8 } },
        interaction: {
            intersect: false,
            mode: 'index',
            axis: 'xy'
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                displayColors: false,
                titleFont: {
                    family: 'dana VF',
                    size: 15,
                    lineHeight: 1.2,
                    weight: 'bold'
                },
                bodyFont: {
                    family: 'dana VF',
                    size: 15,
                    lineHeight: 1.2,
                    weight: 'bold'
                },
                backgroundColor: '#1a202c',
                borderColor: '#424454',
                borderWidth: 2,
                callbacks: {
                    title: function (title) {
                        return chartLabels[title[0].dataIndex];
                    },
                    label: function (context) {
                        let label = context.label || '';
                        if (label.length === 0) {
                            return `قیمت: ${context.formattedValue} تومان`;
                        }
                    }
                },
            }
        },
        scales: {
            x: {
                display: false
            },
            y: {
                display: false
            }
        }
    };
    const navigate = useNavigate();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const dispatch = useAppDispatch();
    const currenciesTopic = useAppSelector(selectCurrenciesTopic);
    const dictionary = useAppSelector(selectDictionary);
    const [chartModalOpen, setChartModalOpen] = useState(false);

    const width = useWindowDimensions();
    let modalWidth: number | string;
    if (width > 1400) {
        modalWidth = 960
    } else if (width > 1200) {
        modalWidth = 720
    } else if (width > 992) {
        modalWidth = 540
    } else if (width > 768) {
        modalWidth = 320
    } else {
        modalWidth = '100%'
    }

    const onChartButtonClick = async () => {
        if (isAuthenticated) {
            await navigate({
                pathname: "/exchange",
                search: createSearchParams({ currency: "USDTTRC20" }).toString()
            })
        } else {
            props.openLoginDrawer()
        }
    }

    useEffect(() => {
        getChartData('USDTTRC20', '1day').then(response => {
            if (!!response && response.data) {
                setChartLabels(response.data['fullLabels'])
                setChartFilterLabels(response.data['visibleLabels'])
                setChartData(response.data['allPrices'])
                dispatch(showLoadingAction(false))
            } else {
                dispatch(showLoadingAction(false))
            }
        })
    }, [dispatch]);

    useEffect(() => {
        let element = document.getElementById("pricingLineChart") as HTMLCanvasElement
        let ctx = element.getContext('2d');

        let gradient = ctx!.createLinearGradient(0, 25, 0, 400);
        let lineColor;
        if (chartData[0] < chartData[chartData.length - 1]) {
            lineColor = '#00cd98';
        } else {
            lineColor = '#BA4647';
        }
        gradient.addColorStop(1, "rgba(27, 33, 38, 0.15)");

        setChartState({
            labels: chartFilterLabels,
            datasets: [{
                label: 'قیمت',
                data: chartData,
                fill: true,
                backgroundColor: 'transparent',
                borderColor: lineColor,
                lineTension: 0,
                borderWidth: 2,
                pointRadius: 3,
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                pointHoverBackgroundColor: lineColor,
                pointHoverBorderColor: lineColor,
            }]
        })
    }, [chartData, chartLabels, chartFilterLabels]);

    return (
        <div className={`position-relative`}>
            <div className={`${style.changeText}`}>24 ساعت گذشته</div>
            <div className={`w-100 d-none d-lg-flex ${style.mainContainer}`}>
                <div className={`${style.backCard} w-100`}>
                    <div className={`${style.card} w-100 d-flex flex-row justify-content-between`}>
                        <div className={`d-flex align-items-center`}>
                            <img src="/img/tether.png" height={58} width={58} alt="" />
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <span className={`fontWeightBold`}>تتر</span>
                            <span className={` mt-2`}>Tether</span>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <span className={`fontSizeTiny`}>قیمت لحظه ای</span>
                            <span className={`fontWeightBold mt-2 ss02`}>
                                {parseInt(currenciesTopic['USDTTRC20']?.currentPrice).toLocaleString('US')}
                            </span>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <span className={`fontSizeTiny`}>تغییرات</span>
                            <span
                                className={`fontWeightBold mt-1 ss02 bg-danger border-radius-7 py-1 px-2`}>0.03%-</span>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <span className={`fontSizeTiny`}>بالاترین قیمت</span>
                            <span className={`fontWeightBold mt-2 ss02`}>53.100 تومان</span>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <span className={`fontSizeTiny`}>پایین ترین قیمت</span>
                            <span className={`fontWeightBold mt-2 ss02`}>52.625 تومان</span>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0`}>
                            <Line id={`pricingLineChart`}
                                data={chartState}
                                options={lineConfig}
                                height={78} />
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <button className={`btn ${style.ExBtn} w-100`} onClick={() => onChartButtonClick()}>
                                خرید/فروش
                            </button>
                            <button className={`btn ${style.ExBtn} mt-2`} onClick={() => setChartModalOpen(true)}>
                                مشاهده نمودار
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-100 d-flex d-lg-none flex-column justify-content-between ${style.mainContainer}`}>
                <div className={`${style.backCard}`}>
                    <div className={`${style.card}`}>
                        <div className={`d-flex justify-content-between`}>
                            <div className={`d-flex align-items-center`}>
                                <img src="/img/tether.png" height={58} width={58} alt="" />
                            </div>
                            <div
                                className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                                <span className={`fontWeightBold`}>اخرین نرخ تتر</span>
                                <span className={` mt-2`}>Tether</span>
                            </div>
                            <div
                                className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                                <span className={`fontSizeTiny`}>قیمت لحظه ای</span>
                                <span className={`fontWeightBold mt-2 ss02`}>
                                    {parseInt(currenciesTopic['USDTTRC20']?.currentPrice).toLocaleString('US')}
                                </span>
                            </div>
                            <div
                                className={`d-flex flex-column mt-3 mt-md-0 align-items-center justify-content-center`}>
                                <span className={`fontSizeTiny`}>تغییرات</span>
                                <span
                                    className={`fontWeightBold mt-1 ss02 bg-danger border-radius-7 py-1 px-2`}>0.03%-</span>
                            </div>
                        </div>
                        <div className={`d-flex flex-column mt-3 mt-md-0`}>
                            <Line id={`pricingLineChart`}
                                data={chartState}
                                options={lineConfig}
                                height={78} />
                        </div>
                        <div className={`d-flex w-100 mt-3 mt-md-0 align-items-center justify-content-center`}>
                            <button className={`btn ${style.ExBtn} w-50 me-1`} onClick={() => onChartButtonClick()}>
                                خرید/فروش
                            </button>
                            <button className={`btn ${style.ExBtn} w-50 me-1`} onClick={() => setChartModalOpen(true)}>
                                مشاهده نمودار
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title={dictionary.currencies['USDT']}
                centered
                open={chartModalOpen}
                onCancel={() => setChartModalOpen(false)}
                width={modalWidth}>
                <ChartModal />
            </Modal>
        </div>
    );
};

export default Pricing;
