import React, {useEffect, useState} from 'react';
import Navbar from "../../shared/navbar/navbar";
import BaseSection from "./base-section/base-section";
import Pricing from "./pricing/pricing";
import WhyBitBlex from "./why-bitblex/why-bitblex";
import styles from './home.module.scss'
import {getDictionary} from "../../shared/services/services";
import {
    dictionaryAction,
    joinRefAction,
    selectDictionary,
    selectIsAuthenticated,
    useAppDispatch,
    useAppSelector,
} from "../../redux";
import {IDictionary} from "../../shared/interfacesAndTypes/interfaces";
import {useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";
import YourHelp from "./your-help/your-help";
import HomeInviteFriend from "./home-invite-friend/home-invite-friend";
import Articles from "./articles/articles";
import Support from "./support/support";
import EventEmitter from "../../shared/event-emitter/event-emitter";

const HomeComponent = (props) => {
    const [, forceUpdate] = useState({});
    const dispatch = useAppDispatch();
    const dictionary = useAppSelector(selectDictionary);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }
    const openLoginDrawer = async (mobile?) => {
        if (isAuthenticated) {
            await navigate('/exchange')
        } else {
            EventEmitter.emit('openLoginDrawer', mobile ? mobile : undefined)
        }
    }

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !!queryParams.joinRef && !isAuthenticated) {
            dispatch(joinRefAction(queryParams.joinRef as string))
            EventEmitter.emit('setOpenDrawer', true)
            EventEmitter.emit('setDrawerTemplate', 'login')
        }
    }, [dispatch, isAuthenticated, searchParams])

    useEffect(() => {
        forceUpdate({})
        const getDictionaryFunc = async () => {
            let response = await getDictionary()
            if (!!response && !!response.data) {
                dispatch(dictionaryAction(response.data as IDictionary));
            }
        }
        getDictionaryFunc().catch(console.error);
    }, [dispatch, isAuthenticated]);

    return (<>
        <div className={`${styles.topLeftGradiant}`}></div>
        <div className={`${styles.topRightGradiant}`}></div>
        <img src="/img/home-shape1.png" className={`${styles.shape1}`} alt="home-shape1.png"/>
        <img src="/img/home-shape2.png" className={`${styles.shape2}`} alt="home-shape2.png"/>
        <div className={`${styles.whyBitBlexGradiant1}`}></div>
        <div className={`${styles.whyBitBlexGradiant2}`}></div>
        <div className={`${styles.helpGradiant}`}></div>
        <div className={`container position-relative mb-5`}>
            <Navbar onLoginClick={() => resetDrawer(true)} onLogoutClick={() => props.onLogoutClick()}/>
            <BaseSection dictionary={dictionary} openLoginDrawer={(mobile) => openLoginDrawer(mobile)}/>
            <Pricing openLoginDrawer={() => openLoginDrawer()}/>
            <WhyBitBlex showBottom={true}/>
            <YourHelp/>
            <HomeInviteFriend/>
            <Articles/>
            <Support/>
        </div>
    </>);
};

export default HomeComponent;
