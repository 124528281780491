import Big from "big.js";

export const tgContactRegex = /^(\+|00)?989\d{9}$/;
export const irPhoneNoRegex = /^09\d{9}$/;
export const farsiRegex = /^[\u0600-\u06FF\s]+$/;
export const dataChannelRegex = /^https:\/\/t.me\/c\/1628008929\/[0-9]+$/;
export const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
// export const commaSeparateRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
export const numericRegex = /^[0-9]+$/g;
export const otpCodeRegex = /^[0-9]{4}$/;
export const numericWithDecimalRegex = /^[0-9]+(.[0-9]+)?$/g;
export const numericWithOptionalPercentRegex = /^[0-9]+(.[0-9]+)?%{0,1}$/g;
export const engWordsAndNums = /^((\d+\w*)|(\d*\w+)){1}$/g;
// export const farsiWordsAndNums = '^' + combineRegExps('[\u06F0-\u06F9]', ['[\u06A9\u06AF\u06C0\u06CC\u060C',
//     '\u062A\u062B\u062C\u062D\u062E\u062F',
//     '\u063A\u064A\u064B\u064C\u064D\u064E',
//     '\u064F\u067E\u0670\u0686\u0698\u200C',
//     '\u0621-\u0629\u0630-\u0639\u0641-\u0654]'].join('')) + '+$';
export const txHashRegex = /^[a-f\d]{64}$/i;
export const mongodbIdRegex = /^[a-f\d]{24}$/i;

export const numericDecimalLimitRegex = (limit: string) => new RegExp(`^\\d+(\\.\\d{1,${limit}})?$`);

export const validateIrNationalId = (id: string): boolean => {
    if (id.length !== 10) return false;
    let checkSum = 0;
    for (let i = 10; i >= 2; i--) {
        const el = +id[10 - i];
        checkSum += el * i;
    }
    const divideRemaining = checkSum % 11;
    if (divideRemaining < 2) {
        return +id[9] === divideRemaining;
    } else {
        return +id[9] === 11 - divideRemaining;
    }
};

export const validateIrBankCard = (cardNumber: string): boolean => {
    if (cardNumber.length !== 16) return false;
    let checkSum = 0;
    for (let i = 16; i >= 1; i--) {
        const index = 16 - i;
        const el = +cardNumber[index];
        const multiplyBy = index % 2 === 0 ? 2 : 1;
        const multiplication = el * multiplyBy;
        checkSum += multiplication > 9 ? multiplication - 9 : multiplication;
    }
    return checkSum % 10 === 0;
};

export const validateIranianSheba = (shebaNumber: string): boolean => {
    if (!shebaNumber.startsWith('IR')) {
        shebaNumber = 'IR' + shebaNumber;
    }
    shebaNumber = shebaNumber.toUpperCase();
    if (shebaNumber.includes("IR")) {
        if (shebaNumber.length !== 26) return false;
        const first4 = shebaNumber.slice(0, 4);
        const last22 = shebaNumber.slice(4, shebaNumber.length);
        shebaNumber = last22 + first4.replace("IR", "1827");
    } else {
        return false;
    }

    return new Big(shebaNumber).mod(97).toNumber() === 1;
};

export async function validateAddressFunc(value) {
    return await fetch('https://api.shasta.trongrid.io/wallet/validateaddress', {
        method: 'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body: JSON.stringify({address: value})
    }).then(response => response.json()).then(response => {
        return response
    })
}