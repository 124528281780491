import React, {useState} from 'react';
import {Form, Input, Radio} from "antd";
import {
    farsiRegex,
    validateAddressFunc,
    validateIranianSheba,
    validateIrBankCard
} from "../../../../shared/regex-and-validations";
import {currencyTypeEnum} from "../fav-withdrawals";
import {IAddFavWithdrawals} from "../../../../shared/interfacesAndTypes/interfaces";
import {showLoadingAction, useAppDispatch} from "../../../../redux";
import {addFavWithdrawalCrypto, addFavWithdrawalToman} from "../../../../shared/services/services";

const AddFavWithdrawal = (props: IAddFavWithdrawals) => {
    const [creditWithdrawalType, setCreditWithdrawalType] = useState(currencyTypeEnum.TOMAN);
    const dispatch = useAppDispatch();

    const onAddFavWithdrawal = async (e: any) => {
        dispatch(showLoadingAction(true))
        let res;
        if (creditWithdrawalType === currencyTypeEnum.TOMAN) {
            res = await addFavWithdrawalToman(e)
        } else {
            dispatch(showLoadingAction(true))
            res = await addFavWithdrawalCrypto({address: e.address})
        }
        if (!!res && res.statusCode === 200) {
            props.onBackClick?.()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onAddFavWithdrawalFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }
    const [form] = Form.useForm();

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>افزودن</h3>
                <h5 className={`text-white mt-3`}>اطلاعات حساب برگزیده خود را وارد کنید</h5>
                <div className={`mt-3 w-100`}>
                    <Radio.Group className={`w-100`}
                                 onChange={(e) => setCreditWithdrawalType(e.target.value)}
                                 value={creditWithdrawalType}>
                        <Radio className={`text-white me-2`}
                               value={currencyTypeEnum.TOMAN}>شبکه بانکی ایران</Radio>
                        <Radio className={`text-white`}
                               value={currencyTypeEnum.CRYPTO}>شبکه ترون (TRC20)</Radio>
                    </Radio.Group>
                </div>
                {creditWithdrawalType === currencyTypeEnum.TOMAN ?
                    <>
                        <Form
                            className={`mt-3 w-100`}
                            name="iranBankNetwork"
                            form={form}
                            labelCol={{span: 25}}
                            wrapperCol={{span: 25}}
                            onFinish={(e) => onAddFavWithdrawal(e)}
                            onFinishFailed={onAddFavWithdrawalFailed}
                            autoComplete="off">
                            <h6 className={`text-white`}>شماره شبا</h6>
                            <Form.Item
                                label=""
                                name="shebaNo"
                                rules={[
                                    {required: true, message: 'لطفا شماره شبا را وارد کنید!'},
                                    {
                                        message: 'شماره شبا نا معتبر است',
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (!!value && validateIranianSheba(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('شماره شبا نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                            <h6 className={`text-white`}>شماره کارت</h6>
                            <Form.Item
                                label=""
                                name="cardNo"
                                rules={[
                                    {required: true, message: 'لطفا شماره کارت را وارد کنید!'},
                                    {
                                        message: 'شماره کارت نا معتبر است',
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (validateIrBankCard(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('شماره کارت نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                            <h6 className={`text-white`}>نام بانک</h6>
                            <Form.Item
                                label=""
                                name="bank"
                                rules={[
                                    {required: true, message: 'لطفا نام بانک را وارد کنید!'},
                                    {pattern: farsiRegex, message: "لطفا فقط از حروف فارسی استفاده کنید"}
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                        </Form>
                    </> :
                    <>
                        <Form
                            className={`mt-3 w-100`}
                            name="tronBlockchainNetwork"
                            form={form}
                            labelCol={{span: 25}}
                            wrapperCol={{span: 25}}
                            onFinish={(e) => onAddFavWithdrawal(e)}
                            onFinishFailed={onAddFavWithdrawalFailed}
                            autoComplete="off">
                            <h6 className={`text-white`}>ادرس کیف پول</h6>
                            <Form.Item
                                label=""
                                name="address"
                                rules={[
                                    {required: true, message: 'لطفا آدرس کیف پول را وارد کنید!'},
                                    {
                                        message: 'آدرس کیف پول نا معتبر است',
                                        validator: async (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            const validateAddress = await validateAddressFunc(value);
                                            if (validateAddress && validateAddress.result) {
                                                return Promise.resolve()
                                            } else {
                                                return Promise.reject('آدرس کیف پول نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                        </Form>
                    </>}
            </div>
            <div className={`w-100 d-flex justify-content-center`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center`}>
                        برای حساب‌های بانکی ایران نام صاحب حساب باید حتما با نام شخص شما یکی باشد
                    </div>
                </div>
            </div>
            <div className={`d-flex w-100 mb-4`}>
                <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                        onClick={() => form.submit()}>
                    ثبت
                </button>
            </div>
        </div>
    );
};

export default AddFavWithdrawal;
