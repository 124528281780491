import React from 'react';
import style from './article.module.scss';

const Article = (props) => {
    return (
        <div className={`${style.mainContainer} cursor-pointer`}
             onClick={() => window.open(props.link, '_blank', 'noopener,noreferrer')}>
            <img src={`${props.image}`} className={`${style.img}`} alt={props.title}/>
            <div className={`text-semi-muted lh-base mt-3`}>{props.description}</div>
        </div>
    );
};

export default Article;
