import React, {useState} from 'react';
import styles from './intro.module.scss'
import {useNavigate} from "react-router";
import {Steps} from 'antd';

const IntroComponent = () => {
    const [introTemplateNumber, setIntroTemplateNumber] = useState(0);
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const items = [0, 1, 2].map(item => ({key: item, title: ''}));

    const onNextClick = (index) => {
        setIntroTemplateNumber(index)
        setCurrent(index)
    }
    const onPrevClick = (index) => {
        setIntroTemplateNumber(index)
        setCurrent(index)
    }
    const onGoToExchangeClick = () => {
        localStorage.setItem('showTour', JSON.stringify({showTour: "true"}));
        navigate('/exchange')
    }

    let introComponent;
    switch (introTemplateNumber) {
        case 0:
            introComponent = <>
                <div className={`col-12 col-md-7 d-flex justify-content-center flex-column`}>
                    <h2 className={`primaryColor`}>امنیت را تجربه کنید</h2>
                    <h5 className={`text-white mt-1`}>آرامش شما مهم است!</h5>
                    <p className={`text-white`}>
                        مهم ترین دغدغه ما در سامانه بیتبلکس، حفاظت از دارایی ها و تامین امنیت معاملات کاربران
                        است. برای تحقق این موضوع تمام تلاشمان را به کار گرفته ایم و جدید ترین تکنولوژی های
                        رمزنگاری را به خدمت درآورده تا حس امنیت خاطر را به شما هدیه دهیم.
                    </p>
                    <div>
                        <button
                            className={`btn btnPrimary px-4 mt-5 fontWeightBold d-flex align-items-center justify-content-center`}
                            onClick={() => onNextClick(1)}>
                            <i className={`fas fa-arrow-left me-2`}></i>
                            بعدی
                        </button>
                    </div>
                </div>
                <div className={`col-12 col-md-5 d-flex align-items-center justify-content-center h-100`}>
                    <img className={`w-100 h-100`}
                         src="/img/security.png"
                         alt="user comment pic"
                    />
                </div>
            </>
            break
        case 1:
            introComponent = <>
                <div className={`col-12 col-md-7 d-flex justify-content-center flex-column`}>
                    <h2 className={`primaryColor`}>ساده، سریع و در لحظه!</h2>
                    <h5 className={`text-white mt-1`}>چون وقت شما ارزشمند است!</h5>
                    <p className={`text-white`}>
                        مهم‌ترین دغدغه ما در سامانه بیتبلکس، حفاظت از دارایی‌ها و
                        تامین امنیت معاملات کاربران است. برای تحقق این موضوع تمام
                        تلاشمان را به‌کار گرفته‌ایم و جدیدترین تکنولوژی‌های
                        رمزنگاری را به‌خدمت درآورده تا حس امنیت خاطر را به شما
                        هدیه دهیم.
                    </p>
                    <div className={`d-flex`}>
                        <button
                            className={`btn btnPrimary px-4 mt-5 fontWeightBold d-flex align-items-center justify-content-center`}
                            onClick={() => onPrevClick(0)}>
                            <i className={`fas fa-arrow-right me-2`}></i>
                            قبلی
                        </button>
                        <button
                            className={`btn btnPrimary px-4 mt-5 ms-2 fontWeightBold d-flex align-items-center justify-content-center`}
                            onClick={() => onNextClick(2)}>
                            <i className={`fas fa-arrow-left me-2`}></i>
                            بعدی
                        </button>
                    </div>
                </div>
                <div className={`col-12 col-md-5 d-flex align-items-center justify-content-center h-100`}>
                    <img className={`w-100 h-100`}
                         src="/img/fast.png"
                         alt="user comment pic"
                    />
                </div>
            </>
            break
        case 2:
            introComponent = <>
                <div className={`col-12 col-md-7 d-flex justify-content-center flex-column`}>
                    <h2 className={`primaryColor`}> همیشه در کنارتان هستیم!</h2>
                    <h5 className={`text-white mt-1`}>چون برای ما مهم هستید!</h5>
                    <p className={`text-white`}>
                        سوالات زیادی دارید؟ جای هیچ نگرانی نیست، تیم متمرکز و
                        حرفه‌ای پشتیبانی به صورت ۲۴ ساعته حامی و در کنار شماست تا
                        سوالات و درخواست‌هایتان که در قالب تماس تلفنی، تیکت یا
                        چت آنلاین مطرح می‌شوند را به بهترین نحو پاسخگو باشد.
                    </p>
                    <div className={`d-flex`}>
                        <button
                            className={`btn btnPrimary px-4 mt-5 fontWeightBold d-flex align-items-center justify-content-center`}
                            onClick={() => onPrevClick(1)}>
                            <i className={`fas fa-arrow-right me-2`}></i>
                            قبلی
                        </button>
                        <button
                            className={`btn btn-primary px-4 mt-5 ms-2 fontWeightBold d-flex align-items-center justify-content-center`}
                            onClick={() => onGoToExchangeClick()}>
                            <i className={`fas fa-check-circle me-2`}></i>
                            ورود به صفحه معاملات
                        </button>
                    </div>
                </div>
                <div className={`col-12 col-md-5 d-flex align-items-center justify-content-center h-100`}>
                    <img className={`w-100 h-100`}
                         src="/img/support.png"
                         alt="user comment pic"
                    />
                </div>
            </>
            break
    }

    return <>
        <div className={`row vh-100 justify-content-center align-items-center`}>
            <div className={`col-11 col-sm-10 col-md-10 col-lg-10 col-xl-6`}>
                <div className={`card bgTertiaryLightColor border-radius-7 ${styles.introCard}`}>
                    <div className={`card-body d-flex align-items-center flex-column justify-content-around`}>
                        <div className={`row h-100 w-100`}>
                            {introComponent}
                        </div>
                        <Steps current={current} items={items}/>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default IntroComponent;
