import React, {useCallback, useEffect, useState} from 'react';
import {InputNumber, notification} from "antd";
import styles from "../my-wallet.module.scss";
import {depositToman} from "../../../../shared/services/services";
import {showLoadingAction, useAppDispatch} from "../../../../redux";

export enum depositTypeEnum {
    onlinePayment = 'onlinePayment',
    bankTransfer = 'bankTransfer'
}

const DepositToman = (props) => {
    const tomanDefaultValues = [
        {index: 1, value: 5000000},
        {index: 2, value: 25000000},
        {index: 3, value: 50000000},
        {index: 4, value: 100000000},
    ]
    const [tomanDepositAmount, setTomanDepositAmount] = useState<number | null>(null);
    const dispatch = useAppDispatch();

    const onSetPayTypeClick = useCallback(async () => {
        if (!tomanDepositAmount) {
            return
        }
        if (tomanDepositAmount <= 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا مقدار واریزی را به صورت صحیح وارد کنید',
                placement: 'bottomRight'
            });
            return;
        }
        if (tomanDepositAmount <= 100000000) {
            props.onPayClick({tomanDepositAmount, depositType: depositTypeEnum.onlinePayment})
        } else {
            dispatch(showLoadingAction(true))
            let response = await depositToman({value: tomanDepositAmount})
            if (!!response && response.statusCode === 200) {
                props.onPayClick({
                    tomanDepositAmount,
                    depositType: depositTypeEnum[response.data['type']],
                    depositBankAccountData: response.data['bankAcc']
                })
                dispatch(showLoadingAction(false))
            } else {
                dispatch(showLoadingAction(false))
            }
        }
    }, [dispatch, props, tomanDepositAmount])
    const onChangeTomanDepositAmount = (amount) => {
        setTomanDepositAmount(amount)
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onSetPayTypeClick()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onSetPayTypeClick]);

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`primaryColor fontWeightBold text-center`}>واریز تومان</h3>
                <div className={`mt-3`}>
                    {tomanDefaultValues.map((item, key) => {
                            return <div key={key}
                                        className={`card box-shadow bgTertiaryLightColor m-2 d-flex flex-wrap align-items-center justify-content-center cursor-pointer
                                                        ${tomanDepositAmount === item.value ? styles.selectedDefaultValue : styles.defaultValue}`}
                                        onClick={() => setTomanDepositAmount(item.value)}>
                                <div className={`py-2 text-white`}>
                                    <span className={`ss02`}>{Number(item.value).toLocaleString('US')}</span> تومان
                                </div>
                            </div>
                        }
                    )}
                </div>
                <div className={`row mt-3`}>
                    <div className={`col-md-12`}>
                        <h6 className={`text-white`}>مبلغ شارژ را از گزینه‌های بالا انتخاب و یا مقدار دلخواه خود را به
                            تومان وارد کنید</h6>
                        <div className={`w-100`}>
                            <InputNumber bordered={false}
                                         className={`inputs w-100 border-radius-7 ss02`}
                                         onChange={onChangeTomanDepositAmount}
                                         min={0}
                                         placeholder="مبلغ"
                                         controls={false}
                                         addonAfter="تومان"
                                         formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                         value={tomanDepositAmount}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-100 d-flex justify-content-center mb-3`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center ss02`}>موجودی کیف
                        پول: {Number(props.balances.find(balance => balance.name === 'TOMAN').amount).toLocaleString('US')} تومان
                    </div>
                </div>
            </div>
            <div className={`d-flex w-100 mb-4`}>
                <button className={`btn btnPrimary w-100 fontWeightBold`}
                        onClick={() => onSetPayTypeClick()}>پرداخت
                </button>
            </div>
        </div>
    );
};

export default DepositToman;