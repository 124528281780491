import React, {useCallback, useEffect, useState} from 'react';
import {getWallet, withdrawalOtpRequest, withdrawalTomanService} from "../../../../shared/services/services";
import {InputNumber, notification} from "antd";
import {IFavWithdrawal, IProfileProps} from "../../../../shared/interfacesAndTypes/interfaces";
import {showLoadingAction, useAppDispatch, useAppSelector, userProfileSelector} from "../../../../redux";

const ApproveWithdrawalToman = (props) => {

    const [otpCode, setOtpCode] = useState(0);
    const [favWithdrawals, setFavWithdrawals] = useState<Array<IFavWithdrawal>>([{
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    }]);
    const sendWithdrawalOtp = async () => {
        await withdrawalOtpRequest()
    };
    const getWalletFunc = async () => {
        let response = await getWallet()
        if (!!response && !!response.data) {
            setFavWithdrawals(response.data['favWithdrawal']);
        }
    }
    useEffect(() => {
        sendWithdrawalOtp();
        getWalletFunc();
    }, []);
    const dispatch = useAppDispatch();
    const onApproveClick = useCallback(async () => {
        if (otpCode === 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا کد ارسال شده در پیامک را وارد کنید',
                placement: 'bottomRight'
            });
            return
        }
        dispatch(showLoadingAction(true))
        let data;
        if (!favWithdrawals.find(item => item.shebaNo === props.selectedWithdrawalFavAccount.shebaNo)) {
            data = {
                otp: otpCode,
                value: props.tomanWithdrawalAmount,
                "bankAcc": {
                    "shebaNo": props.selectedWithdrawalFavAccount.shebaNo,
                    "cardNo": props.selectedWithdrawalFavAccount.cardNo,
                    "bank": props.selectedWithdrawalFavAccount.bank
                }
            }
        } else {
            data = {
                otp: otpCode,
                value: props.tomanWithdrawalAmount,
                favShebaNo: props.selectedWithdrawalFavAccount.shebaNo
            }
        }
        let response = await withdrawalTomanService(data)
        if (!!response && response.statusCode === 200) {
            notification['success']({
                message: 'تراکنش با موفقیت انجام شد',
                description: '',
                placement: 'bottomRight'
            });
            props.onApproveClick()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }, [dispatch, favWithdrawals, otpCode, props])
    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onApproveClick()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onApproveClick]);

    const userProfile: IProfileProps = useAppSelector(userProfileSelector)
    const onChangeOtpCode = (amount) => {
        setOtpCode(amount)
    }
    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column h-100 mt-5 px-3`}>
                <div className={`w-100`}>
                    <h3 className={`secondaryColor fontWeightBold text-center`}>برداشت تومان</h3>
                    <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                        <div className={`p-2 text-center`}>
                            مقدار:
                            <span className={`ss02`}>
                                {' '}{Number(props.tomanWithdrawalAmount).toLocaleString('US')}
                            </span> تومان
                        </div>
                    </div>
                    <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                        <div className={`p-2 text-center`}>
                            به حساب:
                            <span className={` fs-7`}>
                                {' '}{props.selectedWithdrawalFavAccount.shebaNo}
                            </span>
                        </div>
                    </div>
                    <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                        <div className={`p-2 text-center`}>
                            به نام:
                            <span className={``}>
                                {' '}{userProfile.name}
                            </span>
                        </div>
                    </div>
                    <InputNumber bordered={false}
                                 placeholder="کد اعتبارسنجی"
                                 onChange={onChangeOtpCode}
                                 controls={false}
                                 min={0}
                                 maxLength={4}
                                 className={`inputs w-100 border-radius-7 ss02 mt-3`}/>
                    <div
                        className={`card box-shadow bgTertiaryLightColor text-white mt-3 w-100 d-flex flex-column align-items-center justify-content-center`}>
                        <div className={`card-body`}>
                            <div className={`d-flex align-items-center justify-content-center`}>
                                <i className="fas fa-exclamation-circle fs-3 text-white"></i>
                            </div>
                            <div className={`d-flex flex-column mt-2 ss02`}>
                                <span className={`text-white`}>
                                    1- نام صاحب حساب ‌بانکی با نام حساب‌ کاربری شما باید یکسان باشد
                                </span>
                                <span className={`text-white mt-2`}>
                                    2- توجه داشته باشید که واریز دارایی شما در روزهای کاری از ساعت ۱۰ الی ۱۷ و بر اساس سیستم واریز پایا کشور می باشد
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-100 mb-4`}>
                    <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                            onClick={() => onApproveClick()}>تایید
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ApproveWithdrawalToman;