import React from 'react';
import {IProfileProps} from "../../../shared/interfacesAndTypes/interfaces";
import {Skeleton} from "antd";
import {selectUserProfile, useAppSelector, userProfileSelector} from "../../../redux";

const Profile = () => {
    const profile: IProfileProps = useAppSelector(selectUserProfile);
    console.log(profile)
    if (!!profile && !!profile.name && !!profile.email && !!profile.referral?.id) {
        return <div className={`h-100 w-100 px-3 position-relative`}>
            <h3 className={`secondaryColor fontWeightBold text-center`}>
                <i className={`fas fa-camera-retro me-2`}></i>
                اطلاعات هویتی
            </h3>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                    <span className={`text-white text-start`}><i className={`fas fa-user me-2`}></i>نام</span>
                    <span className={`text-white text-end`}>{profile.name}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-barcode me-2`}></i>
                    کدملی
                </span>
                    <span className={`text-white text-end ss02`}>{profile.nationalCode}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-cake-candles me-2`}></i>
                    تاریخ تولد
                </span>
                    <span className={`text-white text-end ss02`}>{profile.birthDate}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-mobile me-2`}></i>
                    تلفن همراه
                </span>
                    <span className={`text-white text-end ss02`}>{profile.phoneNo}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-at me-2`}></i>
                    ایمیل
                </span>
                    <span className={`text-white text-end `}>{profile.email}</span>
                </div>
            </div>
            <h3 className={`secondaryColor fontWeightBold text-center mt-5`}>
                <i className={`fas fa-user-gear me-2`}></i>
                اطلاعات کاربری
            </h3>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-layer-group me-2`}></i>
                    سطح احراز هویت
                </span>
                    <span className={`text-white text-end`}>{profile.userLevel && profile.userLevel.title}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-id-card me-2`}></i>
                    تلگرام
                </span>
                    <span className={`text-white text-end`}>{profile.tgActive ? 'فعال' : 'غیرفعال'}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-calendar me-2`}></i>
                    تاریخ ثبت‌ نام
                </span>
                    <span className={`text-white text-end ss02`}>{profile.registerDate}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-calendar-check me-2`}></i>
                    تاریخ تایید هویت
                </span>
                    <span className={`text-white text-end ss02`}>{profile.verifyDate}</span>
                </div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                <div className={`d-flex align-items-center justify-content-between p-2`}>
                <span className={`text-white text-start`}>
                    <i className={`fas fa-user-group me-2`}></i>
                    کد معرف
                </span>
                    <span className={`text-white text-end `}>{profile.referral && profile.referral.id}</span>
                </div>
            </div>
        </div>
    } else {
        return <Skeleton avatar active loading={true}></Skeleton>
    }
}

export default Profile;