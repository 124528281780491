import React, {useCallback, useEffect, useState} from 'react';
import styles from "../fav-withdrawals.module.scss";
import {currencyTypeEnum} from "../fav-withdrawals";
import {IFavWithdrawal, IFavWithdrawals} from "../../../../shared/interfacesAndTypes/interfaces";
import {deleteFavWithdrawalCrypto, deleteFavWithdrawalToman, getWallet} from "../../../../shared/services/services";
import {notification, Popconfirm, Skeleton, Tooltip} from "antd";
import {Property} from "csstype";

const FavWithdrawalList = (props: IFavWithdrawals) => {
    const [skeletonLoading, setSkeletonLoading] = useState(false);
    const [favWithdrawals, setFavWithdrawals] = useState<Array<IFavWithdrawal>>([{
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    }]);
    const [selectedFavWithdrawal, setSelectedFavWithdrawal] = useState<IFavWithdrawal>({
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    });
    const [tooltipTitle, setTooltipTitle] = useState<string>('برای کپی کلیک کنید');

    const getWalletFunc = useCallback(async () => {
        setSkeletonLoading(true)
        let response = await getWallet()
        if (!!response && !!response.data) {
            setSkeletonLoading(false)
            setFavWithdrawals(response.data['favWithdrawal']);
            return response.data['favWithdrawal']
        }
    }, [])
    const onDeleteFavWithdrawal = async (item) => {
        setSkeletonLoading(true)
        let response;
        if (item.type === currencyTypeEnum.TOMAN) {
            response = await deleteFavWithdrawalToman(item.cardNo)
        } else {
            response = await deleteFavWithdrawalCrypto(item.address)
        }
        if (!!response && response.statusCode === 200) {
            await getWalletFunc().then((favWithdrawal) => props.onLoadFavWithdrawals?.(favWithdrawal)).catch(console.error);
        } else {
            setSkeletonLoading(false)
        }
    }
    const onSelectFavWithdrawal = (item) => {
        setSelectedFavWithdrawal(item)
        props.onSelectFavWithdrawal?.(item)
    }
    const onCopyToClipboardClick = (item) => {
        navigator.clipboard.writeText(item);
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
        setTooltipTitle('کپی شد')
    }
    const onOpenTooltipChange = (visible) => {
        if (!visible) {
            setTooltipTitle('برای کپی کلیک کنید')
        }
    }
    const renderBankName = (item) => {
        let transactionBadge;
        if (!props.selectable) {
            switch (item.verified) {
                case true:
                    transactionBadge = <Tooltip placement={"topLeft"} color={'#10d078'} title='کارت تایید شده'>
                        <span className={`secondaryColor fontWeightBold cursor-pointer`}>{item.bank}</span>
                    </Tooltip>
                    break;
                case false:
                    transactionBadge = <Tooltip placement={"topLeft"} color={'#dc3545'} title='کارت تایید نشده'>
                        <span className={`text-danger fontWeightBold cursor-pointer`}>{item.bank}</span>
                    </Tooltip>
                    break;
            }
        } else {
            transactionBadge = <span className={`text-white fontWeightBold`}>{item.bank}</span>
        }
        return transactionBadge;
    }
    const calcBackgroundImage = (cardNo): Property.BackgroundImage => {
        let cardGradient: Property.BackgroundImage = `linear-gradient(43deg, #2a2a2a, rgb(74, 74, 74))`
        let bankCardNo = cardNo.slice(0, 6);
        switch (parseInt(bankCardNo)) {
            case 627760:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #0d8847)`
                break;
            case 603799:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #c3ba91)`
                break;
            case 610433:
            case 991975:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #b02b27)`
                break;
            case 628023:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #e85722)`
                break;
            case 603770:
            case 639217:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #216819)`
                break;
            case 603769:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #c3dbec)`
                break;
            case 627412:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #ba50c1)`
                break;
            case 627381:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #423921)`
                break;
            case 505785:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #8e43a3)`
                break;
            case 622106:
            case 639194:
            case 627884:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #9b2e31)`
                break;
            case 627353:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #1ebcc5)`
                break;
            case 589463:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #d2cecf)`
                break;
            case 621986:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #2e7cdc)`
                break;
            case 589210:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #d77d32)`
                break;
            case 502806:
                cardGradient = `linear-gradient(43deg, #2a2a2a, #e31a22)`
                break;
        }
        return cardGradient;
    }
    const calcBankLogo = (cardNo) => {
        let rootAddress = "/img/";
        let bankCardNo = cardNo.slice(0, 6);
        switch (parseInt(bankCardNo)) {
            case 627760:
                rootAddress += 'postBank.png'
                break;
            case 603799:
                rootAddress += 'meli.png'
                break;
            case 610433:
            case 991975:
                rootAddress += 'mellat.png'
                break;
            case 628023:
                rootAddress += 'maskan.png'
                break;
            case 603770:
            case 639217:
                rootAddress += 'keshavarzi.png'
                break;
            case 603769:
                rootAddress += 'saderat.png'
                break;
            case 627412:
                rootAddress += 'eghtesad.png'
                break;
            case 627381:
                rootAddress += 'ansar.png'
                break;
            case 505785:
                rootAddress += 'iranzamin.png'
                break;
            case 622106:
            case 639194:
            case 627884:
                rootAddress += 'parsian.png'
                break;
            case 627353:
                rootAddress += 'tejarat.png'
                break;
            case 589463:
                rootAddress += 'refah.png'
                break;
            case 621986:
                rootAddress += 'saman.png'
                break;
            case 589210:
                rootAddress += 'sepah.png'
                break;
            case 502806:
                rootAddress += 'shahr.png'
                break;
        }
        return rootAddress;
    }

    useEffect(() => {
        getWalletFunc().then((favWithdrawal) => props.onLoadFavWithdrawals?.(favWithdrawal)).catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getWalletFunc])

    return <>
        <div className={`h-100 w-100 ${!props.selectable && 'mt-5'} position-relative`}>
            {!props.selectable && <h3 className={`secondaryColor fontWeightBold text-center`}>حساب برگزیده</h3>}
            {skeletonLoading ? <Skeleton avatar active loading={true}></Skeleton> :
                <>
                    {favWithdrawals.length > 0 ?
                        <div className={`mt-3`}>
                            {favWithdrawals.map((item, key) => {
                                if (item.type === currencyTypeEnum.TOMAN && (!props.favType || (!!props.favType && props.favType === currencyTypeEnum.TOMAN && item.verified))) {
                                    return <div key={key}
                                                onClick={() => onSelectFavWithdrawal(item)}
                                                style={{backgroundImage: calcBackgroundImage(item.cardNo)}}
                                                className={`card box-shadow ${styles.creditCard} ${(!!props.favType && props.favType === currencyTypeEnum.TOMAN && selectedFavWithdrawal.cardNo === item.cardNo) && styles.selectedFavWithdrawal} border-radius-7 w-100 p-2 mt-2`}>
                                        <div className={`card-body`}>
                                            <div
                                                className={`fontWeightBold d-flex justify-content-between align-items-center`}>
                                                <span className={`d-flex align-items-center justify-content-center`}>
                                                    <div
                                                        className={`${styles.bankLogoContainer} d-flex align-items-center justify-content-center me-2`}>
                                                        <img src={calcBankLogo(item.cardNo)}
                                                             alt={item.bank}
                                                             height={50}
                                                             width={50}
                                                             className={`image`}/>
                                                    </div>
                                                    {renderBankName(item)}
                                                </span>
                                                {/*{!props.selectable && <Popconfirm
                                                    placement="right"
                                                    title="آیا تمایل به حذف حساب برگزیده مورد نظر دارید؟"
                                                    okText="بله"
                                                    cancelText="خیر"
                                                    onConfirm={() => onDeleteFavWithdrawal(item)}>
                                                    <i key={key}
                                                       className={`fas fa-trash text-danger fontSizeNormal cursor-pointer`}></i>
                                                </Popconfirm>}*/}
                                            </div>
                                            {!props.selectable ?
                                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                                    <div onClick={() => onCopyToClipboardClick(item.cardNo)}
                                                         className={`d-flex align-items-center justify-content-between ${styles.cardNumbers} mt-2 cursor-pointer ss02`}>
                                                        <div className="section">{item.cardNo.slice(12, 16)}</div>
                                                        <div className="section">{item.cardNo.slice(8, 12)}</div>
                                                        <div className="section">{item.cardNo.slice(4, 8)}</div>
                                                        <div className="section">{item.cardNo.slice(0, 4)}</div>
                                                    </div>
                                                </Tooltip> :
                                                <div
                                                    className={`d-flex align-items-center justify-content-between ${styles.cardNumbers} mt-2 cursor-pointer ss02`}>
                                                    <div className="section">{item.cardNo.slice(12, 16)}</div>
                                                    <div className="section">{item.cardNo.slice(8, 12)}</div>
                                                    <div className="section">{item.cardNo.slice(4, 8)}</div>
                                                    <div className="section">{item.cardNo.slice(0, 4)}</div>
                                                </div>
                                            }
                                            {!props.selectable ?
                                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                                    <div onClick={() => onCopyToClipboardClick(item.shebaNo)}
                                                         className={`text-end mt-2 cursor-pointer fs-6`}>
                                                        {item.shebaNo}
                                                    </div>
                                                </Tooltip> :
                                                <div className={`text-end fs-6 mt-2`}>{item.shebaNo}</div>
                                            }
                                        </div>
                                    </div>
                                } else if (item.type === currencyTypeEnum.CRYPTO && (!props.favType || (!!props.favType && props.favType === currencyTypeEnum.CRYPTO))) {
                                    return <div key={key}
                                                onClick={() => onSelectFavWithdrawal(item)}
                                                className={`${styles.creditCard} ${styles.backgroundGradiant} ${(!!props.favType && props.favType === currencyTypeEnum.CRYPTO && selectedFavWithdrawal.cardNo === item.cardNo) && styles.selectedFavWithdrawal} border-radius-7 w-100 p-2 mt-2`}>
                                        <div className={`card-body`}>
                                            <div
                                                className={`fontWeightBold d-flex justify-content-between align-items-center`}>
                                                <span className={`fontWeightBold`}>آدرس کیف پول</span>
                                                {!props.selectable && <Popconfirm
                                                    placement="right"
                                                    title="آیا تمایل به حذف حساب برگزیده مورد نظر دارید؟?"
                                                    okText="بله"
                                                    cancelText="خیر"
                                                    onConfirm={() => onDeleteFavWithdrawal(item)}>
                                                    <i key={key}
                                                       className={`fas fa-trash text-danger fontSizeNormal cursor-pointer`}></i>
                                                </Popconfirm>}
                                            </div>
                                            {!props.selectable ?
                                                <Tooltip placement={"topLeft"} color={'#10d078'} title={tooltipTitle}
                                                         onOpenChange={(visible) => onOpenTooltipChange(visible)}>
                                                    <div onClick={() => onCopyToClipboardClick(item.address)}
                                                         className={`text-end mt-2 fontSizeSmall cursor-pointer`}>
                                                        {item.address}
                                                    </div>
                                                </Tooltip> :
                                                <div className={`text-end mt-2`}>
                                                    {!!item.address && item.address.slice(0, 8)}...{!!item.address && item.address.slice(28, 34)}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            })}
                        </div> :
                        <div className={`mt-2 text-center text-white`}>
                            هیچ حساب برگزیده ای تعریف نشده است
                        </div>}
                    {!props.selectable && <div className={`d-flex justify-content-center mt-3`}>
                        <div
                            className={`${styles.addCardBtn} d-flex align-items-center justify-content-center cursor-pointer`}
                            onClick={() => props.onAddFavWithdrawalClick?.()}>
                            <i className={`fas fa-plus fontSizeXlarge`}></i>
                        </div>
                    </div>}
                </>}
        </div>
        {!props.selectable && <div className={`w-100 d-flex justify-content-center flex-column mb-4`}>
            <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                <div className={`p-2 text-center w-100`}>برای کپی، روی هر قسمت کلیک کنید</div>
            </div>
            <div className={`card box-shadow bgTertiaryLightColor w-100 mt-2`}>
                <div className={`p-2 text-center w-100`}>تعریف حساب‌های برگزیده باعث تسهیل واریز و برداشت های شما
                    می‌شود
                </div>
            </div>
        </div>}
    </>;
};

export default FavWithdrawalList;