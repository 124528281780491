import React, {useCallback, useEffect, useState} from 'react';
import Profile from "../profile/profile";
import {
    selectToken,
    showLoadingAction,
    useAppDispatch,
    useAppSelector,
    userProfileAction,
    userProfileSelector
} from "../../../redux";
import {getRegisteredWord, uploadDocs} from "../../../shared/services/services";
import {Modal, notification} from "antd";
import {IProfileProps} from "../../../shared/interfacesAndTypes/interfaces";
import WebcamCapture from "../webcam-capture/webcam-capture";
import useWindowDimensions from "../../../shared/dimensions/dimensions";

const Authentication = (props) => {
    const [drawerTemplate, setDrawerTemplate] = useState('approve-profile');
    const [registerWords, setRegisterWords] = useState('');
    const [videoString, setVideoString] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [webcamMode, setWebcamMode] = useState<'getScreenShot' | 'captureVideo'>('getScreenShot');
    const [webcamOpen, setWebcamOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState('');
    const [recordedVideo, setRecordedVideo] = useState<Array<Blob>>();

    const dispatch = useAppDispatch();
    const token = useAppSelector(selectToken);
    const userProfile: IProfileProps = useAppSelector(userProfileSelector)
    const width = useWindowDimensions();
    let modalWidth: number | string;
    if (width > 1400) {
        modalWidth = 960
    } else if (width > 1200) {
        modalWidth = 720
    } else if (width > 992) {
        modalWidth = 540
    } else if (width > 768) {
        modalWidth = 320
    } else {
        modalWidth = '100%'
    }

    const onVerifyData = async () => {
        if (!capturedImage) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا عکس کارت ملی را ارسال کنید',
                placement: 'bottomRight'
            });
            return
        }
        if (!recordedVideo) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا ویدیو سلفی را ارسال کنید',
                placement: 'bottomRight'
            });
            return
        }
        dispatch(showLoadingAction(true));
        let bodyFormData = new FormData();
        const blob = b64toBlob(capturedImage.split(',')[1], 'image/png');
        let videoBlob = new Blob(recordedVideo, {type: "video/mp4"})
        if (videoBlob.size > 20971520) {
            notification['error']({
                message: 'خطا',
                description: 'حجم ویدیو، نمی تواند بیشتر از 20 مگابایت باشد',
                placement: 'bottomRight'
            });
            return
        }
        bodyFormData.append('idImage', blob, 'idImage.png');
        bodyFormData.append('selfieVideo', videoBlob, 'idImage.mp4');
        bodyFormData.append('regWords', registerWords);
        let response = await uploadDocs(bodyFormData, token)
        if (!!response && response.statusCode === 200) {
            dispatch(userProfileAction(response.data))
            notification['success']({
                message: 'درخواست احرازهویت شما دریافت شد و به زودی نتیجه بررسی آن به شما اعلام می‌شود',
                description: '',
                placement: 'bottomRight'
            });
            props.onVerifyData()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays: any[] = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }
    const getRegisterWordsFunc = useCallback(async () => {
        let response = await getRegisteredWord()
        if (!!response && !!response.data) {
            setRegisterWords(response.data.map((item, index) => item + (index === registerWords.length - 1 ? '' : ' - ')))
        }
    }, [registerWords.length])
    const openWebCamModal = (webcamMode: 'getScreenShot' | 'captureVideo') => {
        setWebcamMode(webcamMode);
        if (webcamMode === 'getScreenShot') {
            setModalTitle('عکس کارت ملی')
        } else {
            setModalTitle('ویدئو سلفی')
        }
        setWebcamOpen(true);
    }
    const onCapturedImage = (image) => {
        setCapturedImage(image)
        setWebcamOpen(false);
    }
    const onRecordedVideo = (recordedChunks) => {
        setRecordedVideo(recordedChunks)
        setWebcamOpen(false);
    }

    let drawerComponent;
    switch (drawerTemplate) {
        case 'approve-profile':
            drawerComponent =
                <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-between px-2 mt-3`}>
                    <div className={`w-100 d-flex flex-column`}>
                        <h6 className={`text-white mb-3`}>در صورت تایید اطلاعات روی دکمه مرحله بعد کلیک کنید</h6>
                        <div className={`d-flex h-100`}>
                            <Profile/>
                        </div>
                    </div>
                    <div className={`w-100 mb-4`}>
                        <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                                onClick={() => setDrawerTemplate('upload-files')}>مرحله بعد
                        </button>
                    </div>
                </div>
            break
        case 'upload-files':
            drawerComponent =
                <div className={`w-100 h-100 d-flex flex-column align-items-center justify-content-between mt-1`}>
                    <div className={`d-flex flex-column h-100 w-100`}>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            {(!!capturedImage && capturedImage.length > 0) ?
                                <img height="200" width="200" alt={'capturedImage'} className={`box-shadow border-1`}
                                     src={capturedImage}/> :
                                <h6 className={`secondaryColor fontWeightBold text-white mb-0`}>
                                    لطفا عکس کارت شناسایی خود را ارسال کنید
                                </h6>
                            }
                        </div>
                        <div className={`w-100 d-inline-flex mt-2`}>
                            {(!!capturedImage && capturedImage.length > 0) &&
                                <button
                                    className={`btn btn-danger me-2 d-inline-flex align-items-center justify-content-center fontWeightBold`}
                                    onClick={() => setCapturedImage('')}>
                                    <i className={`fas fa-trash`}></i>
                                </button>
                            }
                            <button
                                className={`btn btnPrimary d-inline-flex align-items-center justify-content-center w-100 fontWeightBold`}
                                onClick={() => openWebCamModal('getScreenShot')}>
                                <i className={`fas fa-camera me-2`}></i>
                                گرفتن عکس
                            </button>
                        </div>
                        <hr className={`my-3 primaryColor`} style={{height: 3}}/>
                        {(!!recordedVideo && recordedVideo.length > 0 && !!videoString && videoString.length > 0) ?
                            <div className={`d-flex align-items-center justify-content-center`}>
                                <video src={videoString} id="video-replay" height="200" width="200" controls
                                       autoPlay></video>
                            </div> :
                            <>
                                <h6 className={`secondaryColor fontWeightBold text-white mb-0 lh-base`}>
                                    لطفا در قالب یک ویدئو به صورت سلفی، کارت شناسایی خود را برای چند ثانیه به صورت واضح
                                    مقابل دوربین نمایش داده و سپس در ادامه متن زیر را خوانده و برای ما ارسال کنید
                                </h6>
                                <span className={`text-white fontSizeNormal w-100 ss02 mt-3 lh-base`}>
                                    اینجانب{' '}
                                    <span className={``}>{userProfile.name} </span>
                                    {' '} به کد ملی {' '}
                                    <span className={``}>{userProfile.nationalCode}</span>
                                    {' '} متولد {' '}
                                    <span className={``}>{userProfile.birthDate}</span>
                                    {' '} قوانین و شرایط بیت‌ بلکس را مطالعه کردم و تعهدات لازم را می‌پذیرم. همچنین متعهد می‌شوم حساب بانکی و مدارک این جانب در اجاره کسی نیست، تراکنش های انجام شده مربوط به خودم می باشد و رمزارزها را برای وبسایت های غیرقانونی استفاده نمی‌کنم؛ در غیر این صورت کلیه مسئولیت های حقوقی و کیفری مستقیما بر عهده اینجانب است.
                                </span>
                                <div className={`secondaryColor fontSizeNormal text-white mt-3 mb-0 lh-base`}>
                                    همچنین کلمات اعتبارسنجی من عبارتند از:
                                </div>
                                <span className={`fontSizeNormal lh-base`}>{registerWords}</span>
                            </>
                        }
                        <div className={`w-100 d-inline-flex mt-4`}>
                            {(!!recordedVideo && recordedVideo.length > 0) && <button
                                className={`btn btn-danger me-2 d-inline-flex align-items-center justify-content-center fontWeightBold`}
                                onClick={() => setRecordedVideo([])}>
                                <i className={`fas fa-trash`}></i>
                            </button>}
                            <button
                                className={`btn btnPrimary d-inline-flex align-items-center justify-content-center w-100 fontWeightBold`}
                                onClick={() => openWebCamModal('captureVideo')}>
                                <i className={`fas fa-record-vinyl me-2`}></i>
                                ضبط ویدیو
                            </button>
                        </div>
                    </div>
                    <div className={`w-100 mb-3 mt-2`}>
                        <button disabled={!capturedImage || capturedImage.length === 0}
                                className={`btn btnPrimary w-100 fontWeightBold`}
                                onClick={() => onVerifyData()}> تایید نهایی اطلاعات
                        </button>
                    </div>
                </div>
            break
    }

    useEffect(() => {
        getRegisterWordsFunc().catch(r => console.log(r))
    }, [getRegisterWordsFunc])

    useEffect(() => {
        if (!!recordedVideo) {
            const blob = new Blob(recordedVideo, {type: "video/webm"});
            const url = URL.createObjectURL(blob);
            setVideoString(url)
        }
    }, [recordedVideo])

    return (
        <>
            <div className={`h-100 w-100 mt-5 px-3 position-relative d-flex flex-column`}>
                <div className={`w-100 d-flex flex-column`}>
                    <h3 className={`secondaryColor fontWeightBold text-center`}>احراز هویت</h3>
                    <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                </div>
                <div className={`d-flex flex-column w-100 h-100`}>
                    {drawerComponent}
                </div>
            </div>
            {webcamOpen && <Modal title={modalTitle}
                                  centered
                                  open={webcamOpen}
                                  onCancel={() => setWebcamOpen(false)}
                                  width={modalWidth}>
                <div style={{zIndex: 4}} className={`h-100 w-100 d-flex align-items-center justify-content-center`}>
                    <WebcamCapture webcamMode={webcamMode}
                                   onCapturedImage={(image) => onCapturedImage(image)}
                                   onRecordedVideo={(recordedChunks) => onRecordedVideo(recordedChunks)}/>
                </div>
            </Modal>}
        </>
    )
};

export default Authentication;