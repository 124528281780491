import React, {useCallback, useEffect, useState} from 'react';
import Navbar from "../../shared/navbar/navbar";
import styles from "./contact-us.module.scss";
import {Button, Form, Input} from "antd";
import {emailRegex, farsiRegex, irPhoneNoRegex} from "../../shared/regex-and-validations";
import {showLoadingAction, useAppDispatch} from "../../redux";
import {contactUs} from "../../shared/services/services";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import {useNavigate} from "react-router";

const {TextArea} = Input;


const ContactUs = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [, forceUpdate] = useState({});

    useEffect(() => {
        forceUpdate({})
    }, [form]);

    const openGoftino = useCallback(() => {
        window["Goftino"].open();
    }, []);

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }

    const onApprove = async () => {
        // if (formError > 0) {
        //     form.getFieldsError().filter(({errors}) => errors.length).forEach(error => {
        //         notification['error']({
        //             message: 'خطا',
        //             description: error.errors[0],
        //             placement: 'bottomRight'
        //         });
        //     })
        //     return
        // }
        dispatch(showLoadingAction(true))
        const res = await contactUs(form.getFieldsValue())
        if (!!res && res.statusCode === 200) {
            dispatch(showLoadingAction(false))
            form.resetFields()
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onFormFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    }
    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`container h-100 d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div
                    className={`${styles.container} container h-100 d-flex flex-column align-items-center justify-content-center`}>
                    <div className={`row w-100 d-flex justify-content-around mt-5 h-100`}>
                        <div className={`col-12 col-lg-6 h-100`}>
                            <h4 className={`fontWeightBold`}>تماس با ما</h4>
                            <div className={`d-flex flex-column justify-content-around mt-3`}>
                                <div className={`${styles.borderDash}`}>
                                    <div className={`fontWeightBold secondaryColor`}>
                                        <img src="/img/support-comment.png" alt="support-comment" className={`me-2`}/>
                                        پشتیبانی آنلاین
                                    </div>
                                    <div className={`fontSizeTiny mt-3 ss02`}>
                                        همه روزه از ساعت 8 الی 24 در کنار شما هستیم
                                    </div>
                                    <div className={`mt-3`}>
                                        <button className={`btn btn-primary py-1 fontSizeSmall`} onClick={openGoftino}>
                                            شروع چت آنلاین<i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                                        </button>
                                    </div>
                                </div>
                                <div className={`${styles.borderDash}`}>
                                    <div className={`fontWeightBold secondaryColor`}>
                                        <img src="/img/phone-support.png" alt="support-comment" className={`me-2`}/>
                                        پشتیبانی تلگرام
                                    </div>
                                    <div
                                        className={`fontSizeTiny mt-3 ss02`}>همه روزه از ساعت 8 الی 24 پاسخگو شما هستیم
                                    </div>
                                    {/* <div className={`mt-3 ss02`}>
                                        ۴۱۴۷۸۸۸۸ - ۰۲۱
                                    </div> */}
                                </div>
                                <div className={`${styles.borderDash}`}>
                                    <div className={`fontWeightBold secondaryColor`}>
                                        <img src="/img/support-answer.png" alt="support-comment" className={`me-2`}/>
                                        راهنما و سوالات متداول کاربران
                                    </div>
                                    <div className={`fontSizeTiny mt-3`}>
                                        بسیاری از سوالات شما در بخش سوالات متداول قرار گرفته است
                                    </div>
                                    <div className={`mt-3`}>
                                        <button className={`btn btn-primary py-1 fontSizeSmall`}
                                                onClick={() => navigate('/faq')}>
                                            سوالات متداول
                                            <i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`col-12 col-lg-6 ${styles.form}`}>
                            <p className={`text-semi-muted lh-lg`}>
                                در صورت داشتن هرگونه سوال در مورد بیتبلکس، می توانید از کانال ارتباطی زیر با ما در
                                ارتباط
                                باشید.
                            </p>
                            <div className={`w-100`}>
                                <Form className={`mt-2 w-100`}
                                      name="contactUs"
                                      labelCol={{span: 25}}
                                      wrapperCol={{span: 25}}
                                      onFinish={onApprove}
                                      onFinishFailed={onFormFailed}
                                      autoComplete="off"
                                      form={form}>
                                    <h6 className={`text-white`}>نام و نام خانوادگی</h6>
                                    <Form.Item
                                        label=""
                                        name="name"
                                        rules={[
                                            {required: true, message: 'لطفا نام و نام خانوادگی را وارد کنید!'},
                                            {pattern: farsiRegex, message: "لطفا فقط از حروف فارسی استفاده کنید"}
                                        ]}>
                                        <Input allowClear bordered={false}
                                               className={`bg-dark ${styles.input} border-radius-7 text-white`}/>
                                    </Form.Item>
                                    <h6 className={`text-white`}>شماره موبایل</h6>
                                    <Form.Item
                                        label=""
                                        name="phoneNo"
                                        rules={[
                                            {required: true, message: 'لطفا شماره موبایل را وارد کنید!'},
                                            {
                                                pattern: irPhoneNoRegex,
                                                message: "لطفا شماره موبایل را به صورت صحیح وارد کنید"
                                            }
                                        ]}>
                                        <Input allowClear bordered={false}
                                               className={`bg-dark ${styles.input} border-radius-7 text-white ss02 dana-font`}/>
                                    </Form.Item>
                                    <h6 className={`text-white`}>ایمیل</h6>
                                    <Form.Item
                                        label=""
                                        name="email"
                                        rules={[
                                            {required: true, message: 'لطفا ایمیل را وارد کنید!'},
                                            {pattern: emailRegex, message: "لطفا ایمیل را به صورت صحیح وارد کنید"}
                                        ]}>
                                        <Input allowClear bordered={false}
                                               className={`bg-dark ${styles.input} border-radius-7 text-white`}/>
                                    </Form.Item>
                                    <h6 className={`text-white`}>متن پیام</h6>
                                    <Form.Item
                                        label=""
                                        name="message"
                                        rules={[
                                            {required: true, message: 'لطفا متن پیام را وارد کنید!'},
                                            {
                                                pattern: /^[\u0600-\u06FF\s\u06F0-\u06F90-9]+$/,
                                                message: "لطفا متن پیام را به صورت صحیح وارد کنید"
                                            }
                                        ]}>
                                        <TextArea bordered={false}
                                                  className={`bg-dark ${styles.input} border-radius-7 text-white`}
                                                  rows={4}/>
                                    </Form.Item>
                                    <Form.Item shouldUpdate labelAlign={"left"} wrapperCol={{span: 24}}>
                                        {() => (
                                            <div className={`d-flex justify-content-end`}>
                                                <Button type="primary"
                                                        htmlType="submit"
                                                        className={`mt-3 px-4 d-flex align-items-center justify-content-center `}
                                                        disabled={!!form.getFieldsError().filter(({errors}) => errors.length).length}>
                                                    ارسال پیام
                                                </Button>
                                            </div>
                                        )}
                                    </Form.Item>
                                </Form>
                            </div>
                            {/*<div className={`${styles.borderTop} ps-3`}>*/}
                            {/*<div className={`mt-2 d-flex justify-content-end`}>
                                    <button className={`btn btn-primary py-1 fontSizeSmall`}
                                        onClick={() => onApprove()}>
                                        ارسال پیام
                                        <i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                                    </button>
                                </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
