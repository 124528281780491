import React from 'react';
import Faq from "../pages/FAQ/FAQ";
import HelmetHOC from "./helmet-HOC";

const FaqHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - سوالات متداول"/>
        <Faq/>
    </>
};

export default FaqHOC;