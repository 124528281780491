import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {PersistGate} from "redux-persist/integration/react";
import {ConfigProvider} from "antd";
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import fa from 'antd/lib/locale/fa_IR';
import './styles/style.scss'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import HomeHOC from "./hoc/home-HOC";
import ExchangeHoc from "./hoc/exchange-HOC";
import IntroHoc from "./hoc/intro-HOC";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Custom404 from "./hoc/404-HOC";
import PwaHoc from "./hoc/pwa-HOC";
import Faq from "./pages/FAQ/FAQ";
import ContactUsHOC from "./hoc/contact-us-HOC";
import ErrorPage from "./pages/error-page/error-page";
import ChatWebView from "./pages/chat-webview/chat";
import AboutUsHOC from "./hoc/about-us-HOC";
import FaqHOC from "./hoc/faq-HOC";
import InviteFriendsHOC from "./hoc/invite-friends-HOC";
import Main from "./pages/main/main";
import UsageHelpHOC from "./hoc/usage-help-HOC";
import TlgBotHOC from "./hoc/tlg-bot-HOC";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main/>,
        errorElement: <ErrorPage/>,
        children: [
            {index: true, element: <HomeHOC/>},
            {path: "contact-us", element: <ContactUsHOC/>},
            {path: "about-us", element: <AboutUsHOC/>},
            {path: "faq", element: <FaqHOC/>},
            {path: "invite-friends", element: <InviteFriendsHOC/>},
            {path: "usage-help", element: <UsageHelpHOC/>},
            {path: "tlg-bot", element: <TlgBotHOC/>},
        ]
    },
    {path: "/pwa", element: <PwaHoc/>},
    {path: "/exchange", element: <ExchangeHoc/>},
    {path: "/intro", element: <IntroHoc/>},
    {path: "/faq-webview", element: <Faq/>},
    {path: "/chat-webview", element: <ChatWebView/>},
    {path: "/404", element: <Custom404/>},
    {path: "/*", element: <Custom404/>},
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ConfigProvider direction="rtl" locale={fa} componentSize={"large"}>
                    <RouterProvider router={router}/>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


