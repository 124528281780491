import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import {
  dictionaryAction,
  selectDictionary,
  selectUnreadMessage,
  showLoadingAction,
  unreadMessagesAction,
  useAppDispatch,
  useAppSelector,
  userProfileAction,
} from "../../redux";
import ChartModal from "../../shared/chart-modal/chart-modal";
import useWindowDimensions from "../../shared/dimensions/dimensions";
import Navbar from "../../shared/navbar/navbar";
import DrawerComponent from "../../shared/drawer/drawer";
import { getProfile } from "../../shared/services/services";
import { Breadcrumb, Layout, Modal } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import styles from "./exchange.module.scss";
import InviteFriends from "./invite-friends/invite-friends";
import FavWithdrawals from "./fav-withdrawals/fav-withdrawals";
import Transactions from "./tranactions/transactions";
import TransactionStatusModal from "./transaction-status-modal/transaction-status-modal";
import History from "./history/history";
import Profile from "./profile/profile";
import Messages from "./messages/messages";
import MyWallet from "./my-wallet/my-wallet";
import Authentication from "./authentication/authentication";
import { useSearchParams } from "react-router-dom";
import { useTour } from "@reactour/tour";
import { IDictionary } from "../../shared/interfacesAndTypes/interfaces";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);
const { Sider } = Layout;
let steps = [
  {
    selector: ".dashboardSideMenu",
    content:
      "دسترسی به صفحه اصلی معاملات با کلیک بر روی این دکمه انجام می شود.",
  },
  {
    selector: ".myWalletSideMenu",
    content:
      "موجودی کیف پول ( تتر و تومان ) خود را می توانید با کلیک بر روی این دکمه مشاهده کنید. همچنین از طریق همین بخش قادر به برداشت یا واریز وجه(ارز) به کیف پول خود هستید.",
  },
  {
    selector: ".favAccountSideMenu",
    content:
      "از طریق این دکمه، می توانید حساب های برگزیده خود را مشاهده کنید. در حال حاضر امکان تعریف حساب های برگزیده در شبکه بانکی ایران و شبکه ترون وجود دارد.",
  },
  {
    selector: ".inviteFriendSideMenu",
    content:
      "از طریق این بخش می توانید اطلاعات دوستان دعوت شده توسط شما به بیت بلکس را مشاهده کنید.",
  },
  {
    selector: ".historySideMenu",
    content:
      "تاریخچه تراکنش های انجام شده از طریق این منو قابل مشاهده می باشد.",
  },
  {
    selector: ".authentication",
    content: "از طریق این دکمه، وارد فرآیند احراز هویت شوید",
  },
  {
    selector: ".profileSideMenu",
    content:
      'شما میتوانید با کلیک بر روی این دکمه اطلاعات حساب کاربری خود در بیت بلکس را مشاهده کنید. همچنین میتوانید در صورت نیاز به مشاهده مجدد تور آموزشی با کلیک بر روی دکمه "مشاهده تور" در این منو، مجددا فرآیند مشاهده تور آموزشی را طی کنید.',
  },
  {
    selector: ".messageSideMenu",
    content:
      "تمامی پیام های ارسال شده از طریق مدیریت بیت بلکس و همچنین اطلاع رسانی ها از طریق این بخش قابل مشاهده است.",
  },
  {
    selector: ".exchangeButton",
    content:
      "برای شروع معامله روی این دکمه کلیک کنید. پس از کلیک بر روی این دکمه به صفحه معاملات خواهید رفت و با انتخاب نوع تراکنش می توانید ارز خود را معامله کنید.",
  },
];
const ExchangeComponent = (props) => {
  const dispatch = useAppDispatch();
  const dictionary = useAppSelector(selectDictionary);
  const unreadMessages = useAppSelector(selectUnreadMessage);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedMenu, setSelectedMenu] = useState("dashboard");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerTemplate, setDrawerTemplate] = useState("myWallet");
  const [drawerBorderTopColor, setDrawerBorderTopColor] = useState("#0d6efd");
  const [isSideBreakpointActive, setIsSideBreakpointActive] = useState(false);
  const [sideCollapse, setSideCollapse] = useState(false);
  const [closeButtonPosition, setCloseButtonPosition] = useState<
    "both" | "childrenSide" | "helpSide"
  >("childrenSide");
  const [isShowIncreaseUserLevel, setIsShowIncreaseUserLevel] = useState(false);
  const [transActionStatusModalOpen, setTransActionStatusModalOpen] = useState(
    false,
  );
  const [authenticationModalOpen, setAuthenticationModalOpen] = useState(false);
  const [openTransactionsDrawer, setOpenTransactionsDrawer] = useState(false);
  const [usdtValue, setUSDTValue] = useState(0);
  const [tomanValue, setTomanValue] = useState(0);
  const [blockedUSDTValue, setBlockedUSDTValue] = useState(0);
  const [blockedTomanValue, setBlockedTomanValue] = useState(0);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();

  const width = useWindowDimensions();
  let modalWidth: number | string;
  if (width > 1400) {
    modalWidth = 960;
  } else if (width > 1200) {
    modalWidth = 720;
  } else if (width > 992) {
    modalWidth = 540;
  } else if (width > 768) {
    modalWidth = 320;
  } else {
    modalWidth = "100%";
  }

  let drawerComponent;
  switch (drawerTemplate) {
    case "myWallet":
      drawerComponent = (
        <MyWallet
          changeBorderTopColor={(color) => setDrawerBorderTopColor(color)}
          onOpenAuthentication={() => onSideMenuClick("authentication")}
        />
      );
      break;
    case "favAccount":
      drawerComponent = <FavWithdrawals />;
      break;
    case "inviteFriends":
      drawerComponent = <InviteFriends />;
      break;
    case "history":
      drawerComponent = <History />;
      break;
    case "profile":
      drawerComponent = (
        <div
          className={`mt-5 d-flex flex-column justify-content-between h-100 w-100`}
        >
          <Profile />
          <button
            className={`btn btnPrimary w-100 mb-3`}
            onClick={() => onOpenTourClick()}
          >
            تور آموزشی
          </button>
        </div>
      );
      break;
    case "authentication":
      drawerComponent = (
        <Authentication onVerifyData={() => onVerifyAuthentication()} />
      );
      break;
    case "messages":
      drawerComponent = <Messages />;
      break;
  }
  const onSideMenuClick = (selectedMenuName) => {
    setOpenDrawer(true);
    setAuthenticationModalOpen(false);
    setSelectedMenu(selectedMenuName);
    setDrawerTemplate(selectedMenuName);
    if (selectedMenuName !== "history") {
      setCloseButtonPosition("childrenSide");
    }
    setSideCollapseStatus();
  };
  const onDashboardClick = () => {
    setOpenDrawer(false);
    setSelectedMenu("dashboard");
    setSideCollapseStatus();
  };
  const onOpenWallet = () => {
    setOpenTransactionsDrawer(false);
    onSideMenuClick("myWallet");
  };
  const onSideBreakpoint = (status) => {
    setIsSideBreakpointActive(status);
    setSideCollapseStatus();
  };
  const setSideCollapseStatus = () => {
    if (!isSideBreakpointActive) {
      setSideCollapse(false);
      return;
    }
    if (!sideCollapse) {
      setSideCollapse(true);
    }
  };
  const onVerifyAuthentication = () => {
    onDashboardClick();
    dispatch(dictionaryAction(dictionary as IDictionary));
    navigate(location.pathname);
  };
  const onOpenTourClick = () => {
    onDashboardClick();
    if (setSteps) {
      setSteps(steps);
    }
    setCurrentStep(0);
    setIsOpen(true);
  };

  useEffect(() => {
    const queryParams: { [key: string]: string } = {};
    searchParams.forEach((value, key) => queryParams[key] = value);
    if (!!queryParams && !!queryParams.txPopupId) {
      setTransActionStatusModalOpen(true);
    }
    if (!!queryParams && !!queryParams.currency) {
      setOpenTransactionsDrawer(true);
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(showLoadingAction(true));
    getProfile().then((response) => {
      if (!!response && response.data) {
        setUSDTValue(parseInt(response.data["balances"]["USDT"]));
        setTomanValue(parseInt(response.data["balances"]["TOMAN"]));
        dispatch(userProfileAction(response.data));
        dispatch(unreadMessagesAction(response["data"]["unreadMsg"]));
        if (
          !!response.data && !!response.data["userLevel"] &&
          !!response.data["userLevel"].id
        ) {
          if (response.data["userLevel"].id === 3) {
            setIsShowIncreaseUserLevel(true);
            if (setSteps) {
              setSteps(steps);
            }
          } else {
            if (response.data["userLevel"].id >= 4) {
              steps = steps.filter((step) =>
                step.selector !== ".authentication"
              );
              if (setSteps) {
                setSteps(steps);
              }
            } else if (response.data["userLevel"].id < 4) {
              setIsOpen(true);
            }
            setIsShowIncreaseUserLevel(false);
            setAuthenticationModalOpen(false);
          }
        }
        dispatch(showLoadingAction(false));
      } else {
        dispatch(showLoadingAction(false));
      }
    }).catch(() => {
      dispatch(showLoadingAction(false));
    });
  }, [dispatch, dictionary, setIsOpen, setSteps]);

  useEffect(() => {
    if (props.openMessage) {
      setOpenTransactionsDrawer(false);
      setOpenDrawer(true);
      setSelectedMenu("messages");
      setDrawerTemplate("messages");
    }
  }, [props]);

  useEffect(() => {
    const local = localStorage.getItem("showTour");
    if (local && !!JSON.parse(local) && JSON.parse(local)["showTour"]) {
      setIsOpen(true);
      localStorage.removeItem("showTour");
    }
  }, [setIsOpen]);

  return (
    <Layout
      className={`w-100 ${styles.homeContainer}`}
      style={{ height: !isSideBreakpointActive ? "100vh" : "" }}
    >
      {openTransactionsDrawer
        ? (
          <DrawerComponent
            showCloseButton={true}
            borderTopColor={drawerBorderTopColor}
            onCloseClick={() => setOpenTransactionsDrawer(false)}
            helpComponent={<span></span>}
            childrenClassNames={`d-flex flex-column align-items-center justify-content-center`}
            helpComponentClassNames={`d-flex flex-column align-items-center justify-content-center`}
            childrenPosition={"right"}
            closeButtonPosition={"childrenSide"}
          >
            <Transactions
              onOpenAuthentication={() => onSideMenuClick("authentication")}
              onOpenWallet={() => onOpenWallet()}
            />
          </DrawerComponent>
        )
        : <div className={`d-none`}></div>}
      <Layout
        className={`${styles.layout} position-relative 
                            ${!openTransactionsDrawer && "overflow-hidden"}
                            ${
          (isSideBreakpointActive && openTransactionsDrawer) && "d-none"
        }`}
      >
        {openDrawer
          ? (
            <DrawerComponent
              showCloseButton={true}
              borderTopColor={drawerBorderTopColor}
              onCloseClick={() => onDashboardClick()}
              helpComponent={<span></span>}
              childrenClassNames={`d-flex flex-column align-items-center justify-content-center`}
              helpComponentClassNames={`d-flex flex-column align-items-center justify-content-center`}
              childrenPosition={"left"}
              closeButtonPosition={closeButtonPosition}
            >
              {drawerComponent}
            </DrawerComponent>
          )
          : <div className={`d-none`}></div>}
        <div className={`container h-100 d-flex flex-column`}>
          <Navbar
            onEllipsisButtonClick={() => setSideCollapse(!sideCollapse)}
            onLogoutClick={() => navigate("/")}
          />
          <div className={`w-100 d-flex flex-column mt-2`}>
            <Breadcrumb
              className={`container position-relative d-flex flex-row-reverse`}
              separator=">"
            >
              <Breadcrumb.Item
                className={`text-muted cursor-pointer `}
                onClick={() => navigate("/")}
              >
                خانه
              </Breadcrumb.Item>
              <Breadcrumb.Item className={`text-white`}>
                معاملات
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div
            className={`${styles.container} container h-100 d-flex flex-column align-items-center justify-content-center`}
          >
            <div className={`row d-flex w-100`}>
              <div className={`col-12`}>
                <ChartModal />
              </div>
            </div>
            <div className={`row d-flex w-100 mt-4`}>
              <div
                className={`col-12 col-sm-12 col-md-12 col-lg-10 d-flex align-items-center justify-content-center flex-lg-row flex-column`}
              >
                <div
                  className={`card box-shadow text-white w-100 me-lg-1 me-0 my-lg-0 my-1 border-0`}
                  style={{ backgroundColor: "#1a202c" }}
                >
                  <div
                    className={`card-body d-flex flex-lg-col flex-row align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex justify-content-between flex-column`}
                    >
                      <span className={`text-white lh-1 ${styles.priceLabel}`}>
                        موجودی تتر
                      </span>
                      <span className={`text-center fs-5 ss02 d-flex mt-2`}>
                        {usdtValue} {dictionary.currencies["USDT"]}
                      </span>
                    </div>
                    <div
                      className={`d-flex justify-content-between flex-column align-items-end`}
                    >
                      <span className={`text-white lh-1 ${styles.priceLabel}`}>
                        موجودی قفل شده
                      </span>
                      <span className={`text-center fs-5 ss02 d-flex mt-2`}>
                        {blockedUSDTValue} {dictionary.currencies["USDT"]}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`card box-shadow text-white w-100 me-lg-1 me-0 my-lg-0 my-1 border-0`}
                  style={{ backgroundColor: "#1a202c" }}
                >
                  <div
                    className={`card-body d-flex flex-lg-col flex-row align-items-center justify-content-between`}
                  >
                    <div
                      className={`d-flex justify-content-between flex-column`}
                    >
                      <span className={`text-white lh-1 ${styles.priceLabel}`}>
                        موجودی تومان
                      </span>
                      <span className={`text-center fs-5 ss02 d-flex mt-2`}>
                        {Number(tomanValue).toLocaleString("US")}{" "}
                        {dictionary.currencies["TOMAN"]}
                      </span>
                    </div>
                    <div
                      className={`d-flex justify-content-between flex-column align-items-end`}
                    >
                      <span className={`text-white lh-1 ${styles.priceLabel}`}>
                        موجودی قفل شده
                      </span>
                      <span className={`text-center fs-5 ss02 d-flex mt-2`}>
                        {Number(blockedTomanValue).toLocaleString("US")}{" "}
                        {dictionary.currencies["TOMAN"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`col-12 col-sm-12 col-md-12 col-lg-2 d-flex flex-column align-items-center`}
              >
                <button
                  onClick={() => onSideMenuClick("myWallet")}
                  className={`btn btnPrimary w-100 h-100`}
                >
                  شارژ کیف پول
                </button>
                <button
                  className={`btn btnSecondary w-100 mt-1 h-100 exchangeButton`}
                  onClick={() => setOpenTransactionsDrawer(true)}
                >
                  انجام معامله
                </button>
              </div>
            </div>
          </div>
        </div>
        {isShowIncreaseUserLevel && (
          <div
            className={`position-absolute ${styles.authenticationNotice} p-3 d-flex 
                                                         flex-column flex-lg-row justify-content-center
                                                         align-items-center bgTertiaryLightColor border-radius-7
                                                         animate__animated animate__backInUp animate__delay-3s`}
          >
            <p className={`text-white me-2 m-0 mb-2 mb-lg-0`}>
              برای خرید و فروش در سامانه می‌بایست ابتدا فرآیند احراز هویت را
              انجام دهید.
            </p>
            <button
              className={`btn btnSecondary`}
              onClick={() => onSideMenuClick("authentication")}
            >
              <i className={`fas fa-check-circle text-white me-2`}></i>{" "}
              <span className={`fa-sm lh-base`}>احراز هویت</span>
            </button>
          </div>
        )}
      </Layout>
      <Sider
        className={`${styles.side} h-100`}
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={sideCollapse}
        collapsible={isSideBreakpointActive}
        width={125}
        trigger={null}
        onBreakpoint={(status) => onSideBreakpoint(status)}
        onCollapse={() => setSideCollapseStatus()}
      >
        <div
          className={`d-flex flex-column justify-content-between h-100 overflow-y`}
          style={{ overflowX: "hidden" }}
        >
          <div className={`mt-3`}>
            <div
              className={`${
                selectedMenu === "dashboard" ? "secondaryColor" : "text-muted"
              } d-flex dashboardSideMenu flex-column
                                     justify-content-center align-items-center cursor-pointer ${styles.sideItem}`}
              onClick={() => onDashboardClick()}
            >
              <i className={`fas fa-arrow-right-arrow-left fontSizeXlarge`} />
              <span className={`mt-1`}>پیشخوان</span>
            </div>
            <div
              className={`${
                selectedMenu === "myWallet" ? "secondaryColor" : "text-muted"
              } d-flex myWalletSideMenu flex-column
                                     justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem} myWalletSideMenu`}
              onClick={() => onSideMenuClick("myWallet")}
            >
              <i className={`fas fa-wallet fontSizeXlarge`} />
              <span className={`mt-1`}>کیف پول</span>
            </div>
            <div
              className={`${
                selectedMenu === "favAccount" ? "secondaryColor" : "text-muted"
              } d-flex favAccountSideMenu
                                     flex-column justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem}`}
              onClick={() => onSideMenuClick("favAccount")}
            >
              <i className={`fas fa-money-bills fontSizeXlarge`} />
              <span className={`mt-1`}>حساب برگزیده</span>
            </div>
            <div
              className={`${
                selectedMenu === "inviteFriends"
                  ? "secondaryColor"
                  : "text-muted"
              } d-flex inviteFriendSideMenu
                                    flex-column justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem}`}
              onClick={() => onSideMenuClick("inviteFriends")}
            >
              <i className={`fas fa-user-plus fontSizeXlarge`} />
              <span className={`mt-1`}>دعوت از دوستان</span>
            </div>
            <div
              className={`${
                selectedMenu === "history" ? "secondaryColor" : "text-muted"
              } d-flex historySideMenu flex-column
                                    justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem}`}
              onClick={() => onSideMenuClick("history")}
            >
              <i className={`fas fa-clock-rotate-left fontSizeXlarge`} />
              <span className={`mt-1`}>تاریخچه</span>
            </div>
          </div>
          <div className={`mb-3`}>
            {isShowIncreaseUserLevel &&
              (
                <div
                  className={`${
                    selectedMenu === "authentication"
                      ? "secondaryColor"
                      : "text-muted"
                  }
                                                                 d-flex flex-column justify-content-center align-items-center mt-4
                                                                 cursor-pointer authentication ${styles.sideItem}
                                                                 animate__animated animate__heartBeat animate__delay-3s
                                                                 animate__infinite animate__slow`}
                  onClick={() => onSideMenuClick("authentication")}
                >
                  <i className={`fas fa-check-circle fontSizeXlarge`} />
                  <span className={`mt-1`}>احراز هویت</span>
                </div>
              )}
            <div
              className={`${
                selectedMenu === "profile" ? "secondaryColor" : "text-muted"
              } d-flex profileSideMenu flex-column
                                    justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem}`}
              onClick={() => onSideMenuClick("profile")}
            >
              <i className={`fas fa-circle-user fontSizeXlarge`} />
              <span className={`mt-1`}>پروفایل</span>
            </div>
            <div
              className={`${
                selectedMenu === "messages" ? "secondaryColor" : "text-muted"
              } messageSideMenu position-relative
                                    d-flex flex-column justify-content-center align-items-center mt-4 cursor-pointer ${styles.sideItem}`}
              onClick={() => onSideMenuClick("messages")}
            >
              {unreadMessages > 0 &&
                (
                  <span
                    className={`${styles.msgCount} position-absolute d-flex flex-column justify-content-center align-items-center ss02`}
                  >
                    {unreadMessages}
                  </span>
                )}
              <i className="fas fa-comment-alt fontSizeXlarge" />
              <span className={`mt-1`}>پیام ها</span>
            </div>
            {isSideBreakpointActive && (
              <div
                className={`d-flex flex-column justify-content-center align-items-center mt-2 cursor-pointer ${styles.sideItem}`}
                onClick={() => setSideCollapse(true)}
              >
                <hr
                  className={`mb-2 primaryColor w-100`}
                  style={{ height: 3 }}
                />
                <i className="fas fa-arrow-left fontSizeXlarge" />
              </div>
            )}
          </div>
        </div>
      </Sider>
      <Modal
        style={{ marginTop: "30px", marginBottom: "30px" }}
        title="نتیجه تراکنش"
        centered
        open={transActionStatusModalOpen}
        onCancel={() => setTransActionStatusModalOpen(false)}
        width={modalWidth}
      >
        <TransactionStatusModal />
      </Modal>
      <Modal
        style={{ margin: "5px" }}
        title={"احراز هویت"}
        centered
        closable={false}
        open={authenticationModalOpen}
        onCancel={() => setAuthenticationModalOpen(false)}
        width={720}
      >
        <div
          className={`fontSizeMini d-flex flex-column justify-content-between align-items-center`}
        >
          <div
            className={`w-100 d-flex flex-column justify-content-between align-items-center`}
          >
            <i className="fas fa-fingerprint fa-4x text-success"></i>
            <p className={`text-white mt-4`}>
              برای خرید و فروش در سامانه می‌بایست ابتدا فرآیند احراز هویت را
              انجام دهید.
            </p>
          </div>
          <div className={`${styles.borderTop} ps-3`}>
            <div className={`mt-2 d-flex justify-content-end`}>
              <button
                className={`btn btnSecondary`}
                onClick={() => onSideMenuClick("authentication")}
              >
                <i className={`fas fa-check-circle text-white me-2`}></i>
                <span className={`fa-sm lh-base`}>ورود به صفحه احراز هویت</span>
              </button>
              <button
                className={`btn btn-danger ms-2`}
                onClick={() => setAuthenticationModalOpen(false)}
              >
                <i className={`fas fa-times-circle text-white me-2`}></i>{" "}
                <span className={`fa-sm lh-base`}>فعلا نه!</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default ExchangeComponent;
