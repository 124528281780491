import React, {useCallback, useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, notification, Radio, Space} from "antd";
import {selectDictionary, showLoadingAction, useAppDispatch, useAppSelector} from "../../../../redux";
import {addFavWithdrawalToman} from "../../../../shared/services/services";
import FavWithdrawalList from "../../fav-withdrawals/fav-withdrawal-list/fav-withdrawal-list";
import {IFavWithdrawal} from "../../../../shared/interfacesAndTypes/interfaces";
import {farsiRegex, validateIranianSheba, validateIrBankCard} from "../../../../shared/regex-and-validations";
import {currencyTypeEnum} from "../../fav-withdrawals/fav-withdrawals";

export enum WithdrawalAccountTypeEnum {
    favAccountList = 'favAccountList',
    addNewFavAccount = 'addNewFavAccount'
}

const WithdrawalToman = (props) => {
    const dictionary = useAppSelector(selectDictionary);
    const [tomanWithdrawalAmount, setTomanWithdrawalAmount] = useState(0);
    const [favWithdrawal, setFavWithdrawal] = useState<IFavWithdrawal>({
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    });
    const dispatch = useAppDispatch();
    const [favWithdrawals, setFavWithdrawals] = useState<Array<IFavWithdrawal>>([{
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    }]);
    const [withdrawalAccountType, setWithdrawalAccountType] = useState(WithdrawalAccountTypeEnum.favAccountList);
    const [showRadios, setShowRadios] = useState(true);
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const onNotificationCancelAndNextClick = useCallback(() => {
        const formValues = form.getFieldsValue();
        notification.destroy()
        props.onNextClick({
            tomanWithdrawalAmount, bankAcc: {
                shebaNo: formValues.shebaNo,
                cardNo: formValues.cardNo,
                bank: formValues.bank
            }
        })
    }, [form, props, tomanWithdrawalAmount])
    const onNotificationApproveAndNextClick = useCallback(async () => {
        dispatch(showLoadingAction(true))
        let res = await addFavWithdrawalToman(form.getFieldsValue())
        if (!!res && res.statusCode === 200) {
            props.onNextClick({
                tomanWithdrawalAmount, bankAcc: {
                    shebaNo: res.data['shebaNo'],
                    cardNo: res.data['cardNo'],
                    bank: res.data['bank']
                }
            })
            notification.destroy()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }, [dispatch, form, props, tomanWithdrawalAmount])
    const openNotification = useCallback(() => {
        const key = `open${Date.now()}`;
        const btn = (
            <Space>
                <Button type="link" size="small" onClick={() => onNotificationCancelAndNextClick()}>
                    عدم ذخیره و ادامه
                </Button>
                <Button type="primary" size="small" onClick={() => onNotificationApproveAndNextClick()}>
                    ذخیره و ادامه
                </Button>
            </Space>
        );
        api.open({
            message: 'حساب برگزیده جدید',
            description:
                'اطلاعات حساب وارد شده در لیست حساب های برگزیده شما وجود ندارد.آیا تمایل دارید به حساب برگزیده شما اضافه شود؟',
            btn,
            key,
            placement: 'bottomRight'
        });
    }, [api, onNotificationApproveAndNextClick, onNotificationCancelAndNextClick]);
    const onNextClick = useCallback((e?) => {
        if (tomanWithdrawalAmount === 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا مبلغ درخواستی را وارد کنید',
                placement: 'bottomRight'
            });
            return
        }
        let tomanBalance = props.balances.find(balance => balance.name === 'TOMAN');
        if (parseInt(tomanBalance.amount) < tomanWithdrawalAmount) {
            notification['error']({
                message: 'مبلغ درخواستی از مبلغ کیف پول شما بیشتر است',
                description: `موجودی کیف پول: ${Number(tomanBalance.amount).toLocaleString('US')}`,
                placement: 'bottomRight'
            });
            return
        }
        if (withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList) {
            if (favWithdrawal.shebaNo.length === 0) {
                notification['error']({
                    message: 'خطا',
                    description: 'لطفا حساب برگزیده جهت برداشت را انتخاب کنید',
                    placement: 'bottomRight'
                });
            } else {
                props.onNextClick({
                    tomanWithdrawalAmount, bankAcc: {
                        shebaNo: favWithdrawal.shebaNo,
                        cardNo: favWithdrawal.cardNo,
                        bank: favWithdrawal.bank
                    }
                })
            }
            return
        }
        if (withdrawalAccountType === WithdrawalAccountTypeEnum.addNewFavAccount) {
            if (!favWithdrawals.find(item => item.shebaNo === favWithdrawal.shebaNo)) {
                openNotification();
            } else {
                props.onNextClick({
                    tomanWithdrawalAmount, bankAcc: {
                        shebaNo: e.shebaNo,
                        cardNo: e.cardNo,
                        bank: e.bank
                    }
                })
            }
            return;
        }
    }, [favWithdrawal.bank, favWithdrawal.cardNo, favWithdrawal.shebaNo, favWithdrawals, openNotification, props, tomanWithdrawalAmount, withdrawalAccountType])
    const onLoadFavWithdrawals = (data) => {
        setFavWithdrawals(data);
        if (data.length === 0) {
            setShowRadios(false)
            setWithdrawalAccountType(WithdrawalAccountTypeEnum.addNewFavAccount)
        } else {
            setShowRadios(true)
            setWithdrawalAccountType(WithdrawalAccountTypeEnum.favAccountList)
        }
    }
    const onChangeTomanWithdrawalAmount = (amount) => {
        if (!!amount) {
            setTomanWithdrawalAmount(amount)
        } else {
            setTomanWithdrawalAmount(0)
        }
    };

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ? onNextClick() : form.submit()
            }
        };
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [form, onNextClick, withdrawalAccountType]);

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>برداشت تومان</h3>
                <h6 className={`text-white mt-3`}>مبلغ برداشت</h6>
                <div className={`d-inline-flex w-100`}>
                    <InputNumber bordered={false}
                                 placeholder="مبلغ"
                                 onChange={(amount) => onChangeTomanWithdrawalAmount(amount)}
                                 controls={false}
                                 min={0}
                                 addonAfter={dictionary.currencies['TOMAN']}
                                 formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 className={`inputs w-100 border-radius-7 ss02`}/>
                </div>
                {showRadios && <div className={`mt-3 w-100`}>
                    <Radio.Group className={`w-100`}
                                 onChange={(e) => setWithdrawalAccountType(e.target.value)}
                                 value={withdrawalAccountType}>
                        <Radio className={`text-white me-2`}
                               value={WithdrawalAccountTypeEnum.favAccountList}>حساب برگزیده</Radio>
                        <Radio className={`text-white`}
                               value={WithdrawalAccountTypeEnum.addNewFavAccount}>حساب جدید</Radio>
                    </Radio.Group>
                </div>}
                {withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ?
                    <FavWithdrawalList favType={currencyTypeEnum.TOMAN}
                                       onLoadFavWithdrawals={(data) => onLoadFavWithdrawals(data)}
                                       onSelectFavWithdrawal={(favWithdrawal) => setFavWithdrawal(favWithdrawal)}
                                       selectable={true}/> :
                    <>
                        <h6 className={`text-white mt-3`}>اطلاعات حساب مورد نظر برای برداشت را وارد کنید</h6>
                        <Form className={`mt-3 w-100`}
                              form={form}
                              name="iranBankNetwork"
                              onFinish={onNextClick}
                              labelCol={{span: 25}}
                              wrapperCol={{span: 25}}
                              autoComplete="off">
                            <h6 className={`text-white`}>شماره شبا</h6>
                            <Form.Item
                                label=""
                                validateFirst={true}
                                name="shebaNo"
                                rules={[
                                    {required: true, message: 'لطفا شماره شبا را وارد کنید!'},
                                    {
                                        message: 'شماره شبا نا معتبر است',
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (!!value && validateIranianSheba(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('شماره شبا نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                            <h6 className={`text-white`}>شماره کارت</h6>
                            <Form.Item
                                label=""
                                validateFirst={true}
                                name="cardNo"
                                rules={[
                                    {required: true, message: 'لطفا شماره کارت را وارد کنید!'},
                                    {
                                        message: 'شماره کارت نا معتبر است',
                                        validator: (_, value) => {
                                            if (!value) {
                                                return Promise.resolve();
                                            }
                                            if (validateIrBankCard(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject('شماره کارت نا معتبر است');
                                            }
                                        }
                                    }
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                            <h6 className={`text-white`}>نام بانک</h6>
                            <Form.Item
                                label=""
                                validateFirst={true}
                                name="bank"
                                rules={[
                                    {required: true, message: 'لطفا نام بانک را وارد کنید!'},
                                    {pattern: farsiRegex, message: "لطفا فقط از حروف فارسی استفاده کنید"}
                                ]}>
                                <Input allowClear bordered={false} className={`inputs border-radius-7`}/>
                            </Form.Item>
                        </Form>
                    </>
                }
            </div>
            <div className={`w-100 d-flex justify-content-center mb-2`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center`}>مبلغ برداشت و حساب مقصد را مشخص کنید</div>
                </div>
            </div>
            <div className={`w-100 d-flex justify-content-center mb-3`}>
                <div className={`card box-shadow bgTertiaryLightColor w-100`}>
                    <div className={`p-2 text-center ss02`}>موجودی کیف
                        پول: {Number(props.balances.find(balance => balance.name === 'TOMAN').amount).toLocaleString('US')} تومان
                    </div>
                </div>
            </div>
            <div className={`d-flex w-100 mb-4`}>
                {contextHolder}
                <button className={`btn btnPrimary w-100 fontWeightBold`}
                        onClick={() => withdrawalAccountType === WithdrawalAccountTypeEnum.favAccountList ? onNextClick() : form.submit()}>
                    مرحله بعد
                </button>
            </div>
        </div>
    );
};

export default WithdrawalToman;