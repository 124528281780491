import {createReducer} from '@reduxjs/toolkit';
import {
    currenciesTopicAction,
    dictionaryAction,
    festivalTopicAction,
    joinRefAction,
    otpCodeAction,
    showLoadingAction,
    tokenAction,
    unreadMessagesAction,
    userMobileAction,
    userProfileAction
} from './actions';
import {
    currenciesTopicInitialState,
    dictionaryInitialState,
    festivalTopicInitialState,
    showLoadingInitialState,
    tokenInitialState,
    userMobileInitialState,
    userProfileInitialState
} from "./initial-states";

export const tokenReducer = createReducer(tokenInitialState, builder => {
    builder.addCase(tokenAction, (state, action) => {
        state.token = action.payload;
        state.hasToken = action.payload.length !== 0;
    });
});
export const userMobileReducer = createReducer(userMobileInitialState, builder => {
    builder.addCase(userMobileAction, (state, action) => {
        state.userMobile = action.payload;
    });
});
export const otpCodeReducer = createReducer({otpCode: '0'}, builder => {
    builder.addCase(otpCodeAction, (state, action) => {
        state.otpCode = action.payload;
    });
});
export const joinRefReducer = createReducer({joinRef: ''}, builder => {
    builder.addCase(joinRefAction, (state, action) => {
        state.joinRef = action.payload;
    });
});
export const userProfileReducer = createReducer(userProfileInitialState, builder => {
    builder.addCase(userProfileAction, (state, action) => {
        state.profile = action.payload;
    });
});
export const showLoadingReducer = createReducer(showLoadingInitialState, builder => {
    builder.addCase(showLoadingAction, (state, action) => {
        state.showLoading = action.payload;
    });
});
export const dictionaryReducer = createReducer(dictionaryInitialState, builder => {
    builder.addCase(dictionaryAction, (state, action) => {
        state.currencies = action.payload.currencies;
        state.math = action.payload.math;
        state.platform = action.payload.platform;
        state.adminLevel = action.payload.adminLevel;
        state.userLevel = action.payload.userLevel;
        state.transaction = action.payload.transaction;
    });
});
export const currenciesTopicReducer = createReducer(currenciesTopicInitialState, builder => {
    builder.addCase(currenciesTopicAction, (state, action) => {
        state.currencies = action.payload;
    });
});
export const festivalTopicReducer = createReducer(festivalTopicInitialState, builder => {
    builder.addCase(festivalTopicAction, (state, action) => {
        state.festivalTopic = action.payload;
    });
});
export const unreadMessagesReducer = createReducer({unreadMessage: 0}, builder => {
    builder.addCase(unreadMessagesAction, (state, action) => {
        state.unreadMessage = action.payload;
    });
});