import React, {useEffect, useState} from 'react';
import style from './articles.module.scss';
import {getBlogPosts} from "../../../shared/services/services";
import Article from "./article/article";

const Articles = () => {

    const [articles, setArticles] = useState<any[]>([])


    useEffect(() => {
        getBlogPosts().then(response => {
            if (!!response && response.data) {
                setArticles(response.data)
            }
        })
    }, []);

    return (
        <>
            <div className={`d-flex justify-content-between align-items-center ${style.mainContainer}`}>
                <h4 className={`fontWeightBold`}>برترین مقالات بیت بلکس</h4>
                <button className={`btn btnTertiary py-1 fontSizeSmall`}
                        onClick={() => window.open('https://blog.bitblex.com', '_blank', 'noopener,noreferrer')}>
                    همه مقالات
                    <i className={`fas fa-arrow-left ms-4 fontSizeTiny`}></i>
                </button>
            </div>
            <div className={`row w-100 mt-3`}>
                {articles.map((article, key) => {
                    return <div key={key} className={`col-md-4 p-0`}>
                        <Article image={article.image}
                                 link={article.link}
                                 description={article.description}
                                 title={article.title}/>
                    </div>
                })}
            </div>
        </>
    );
};

export default Articles;
