import React from 'react';
import InviteFriends from "../pages/Invite-friends/Invite-friends";
import HelmetHOC from "./helmet-HOC";

const InviteFriendsHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - دعوت از دوستان"/>
        <InviteFriends/>
    </>
};

export default InviteFriendsHOC;