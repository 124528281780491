import React from 'react';
import UsageHelp from "../pages/usage-help/usage-help";
import HelmetHOC from "./helmet-HOC";

const UsageHelpHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - آموزش"/>
        <UsageHelp/>
    </>
};

export default UsageHelpHOC;