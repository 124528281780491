import {notification} from "antd";
import {axiosWrapper, createResponse, errorHandler} from "./base-service";
import {DurationTypes} from "../interfacesAndTypes/interfaces";

export const sendValidationCodeService = async (componentData) => {
    try {
        const res = await axiosWrapper.post('auth/reqOtp', {phoneNo: componentData.mobile})
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const loginService = async (componentData) => {
    try {
        const res = await axiosWrapper.post('auth/verifyOtp', componentData)
        const data = await res.data;
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        if (!!data.next && data.next === 'profile') {
            axiosWrapper.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
        }
        return createResponse(data.statusCode, {accessToken: 'Bearer ' + data.accessToken, next: data.next});
    } catch (err: any) {
        errorHandler(err)
    }
};

export const signUpService = async (componentData) => {
    try {
        const res = await axiosWrapper.post('auth/signup', componentData);
        const data = await res.data;
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        if (!!data.accessToken) {
            axiosWrapper.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
        }
        return createResponse(data.statusCode, {accessToken: 'Bearer ' + data.accessToken});
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getRegisteredWord = async () => {
    try {
        const res = await axiosWrapper.get('profile/regWords');
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getProfile = async () => {
    try {
        const res = await axiosWrapper.get('profile');
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getWallet = async () => {
    try {
        const res = await axiosWrapper.get('wallet');
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const addFavWithdrawalToman = async (componentData) => {
    try {
        const data = {
            shebaNo: componentData.shebaNo,
            cardNo: componentData.cardNo,
            bank: componentData.bank,
        }
        if (!data.shebaNo.startsWith('IR')) {
            data.shebaNo = 'IR' + data.shebaNo;
        }
        const res = await axiosWrapper.post('wallet/favorite/toman', data)
        notification['success']({
            message: 'حساب جدید با موفقیت اضافه شد',
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const deleteFavWithdrawalToman = async (cardNo) => {
    try {
        const res = await axiosWrapper.delete('wallet/favorite/toman', {data: {cardNo}})
        notification['success']({
            message: 'حساب مورد نظر با موفقیت حذف شد',
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const addFavWithdrawalCrypto = async (componentData) => {
    try {
        const data = {
            network: 'tron',
            address: componentData.address,
        }
        const res = await axiosWrapper.post('wallet/favorite/crypto', data)
        notification['success']({
            message: 'حساب جدید با موفقیت اضافه شد',
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const deleteFavWithdrawalCrypto = async (address) => {
    try {
        const res = await axiosWrapper.delete('wallet/favorite/crypto', {data: {address}})
        notification['success']({
            message: 'حساب مورد نظر با موفقیت حذف شد',
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const depositToman = async (componentData) => {
    try {
        let data = {value: componentData.value}
        if (componentData.cardNo) {
            data['cardNo'] = componentData.cardNo
        }
        const res = await axiosWrapper.post('wallet/deposit/toman', data)
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const uploadDepositReceipt = async (componentData) => {
    try {
        axiosWrapper.defaults.headers.common["Content-Type"] = "multipart/form-data";
        const res = await axiosWrapper.post("wallet/deposit/receipt", componentData)
        axiosWrapper.defaults.headers.common["Content-Type"] = "application/json";
        notification['success']({message: res.data.message, placement: 'bottomRight'});
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const depositCrypto = async (componentData) => {
    try {
        const res = await axiosWrapper.post('wallet/deposit/crypto', {currency: componentData.currency})
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getDictionary = async () => {
    try {
        const res = await axiosWrapper.get('general/dictionary');
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const withdrawalOtpRequest = async () => {
    try {
        const res = await axiosWrapper.get('wallet/withdrawal/otp')
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const withdrawalTomanService = async (componentData) => {
    try {
        const res = await axiosWrapper.post('wallet/withdrawal/toman', componentData)
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const withdrawalCryptoService = async (componentData) => {
    try {
        const res = await axiosWrapper.post('wallet/withdrawal/crypto', componentData)
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getTransactions = async (params) => {
    try {
        const res = await axiosWrapper.get('transactions', {params});
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getTransactionById = async (id, token) => {
    try {
        const res = await axiosWrapper.get(`transactions/${id}`)
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const createReceipt = async (componentData) => {
    try {
        const res = await axiosWrapper.post('exchange/receipt', componentData)
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const confirmReceipt = async (componentData) => {
    try {
        const res = await axiosWrapper.post('exchange/confirm', componentData)
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const contactUs = async (componentData) => {
    try {
        const res = await axiosWrapper.post('general/contactUs', componentData)
        notification['success']({
            message: res.data.message,
            placement: 'bottomRight'
        });
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getFestRemaining = async (currency, token) => {
    try {
        const res = await axiosWrapper.get(`exchange/festRemaining/${currency}`)
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getMessages = async (params) => {
    try {
        const res = await axiosWrapper.get('messages', {params});
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const markMessageAsRead = async (id, token) => {
    try {
        const res = await axiosWrapper.get(`messages/read/${id}`)
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        // errorHandler(err)
    }
};

export const getChartData = async (currency, duration: DurationTypes) => {
    try {
        const res = await axiosWrapper.get(`general/chart/${currency}/${duration}`)
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        // errorHandler(err)
    }
};

export const uploadDocs = async (componentData, token) => {
    try {
        axiosWrapper.defaults.headers.common["Content-Type"] = "multipart/form-data";
        const res = await axiosWrapper.post("profile/uploadDocs", componentData)
        axiosWrapper.defaults.headers.common["Content-Type"] = "application/json";
        return createResponse(res.data.statusCode, res.data);
    } catch (err: any) {
        errorHandler(err)
    }
};

export const getBlogPosts = async () => {
    try {
        const res = await axiosWrapper.get(`general/blogPosts`)
        const data = await res.data;
        return createResponse(data.statusCode, data);
    } catch (err: any) {
        errorHandler(err)
    }
};