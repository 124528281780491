import React, {useState} from 'react';
import Navbar from "../../shared/navbar/navbar";
import styles from "./usage-help.module.scss";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import {Input} from "antd";
import TechnicalToolsGuide from "../technical-tools-guide/technical-tools-guide";

const UsageHelp = () => {

    const [searchValue, setSearchValue] = useState<string>('')

    const fastAccess = [
        {question: 'چگونه در کمترین زمان، تومان را به تتر تبدیل کنم؟'},
        {question: 'چگونه در کمترین زمان، تومان را به تتر تبدیل کنم؟'},
        {question: 'آیا کسب درآمد در دعوت از دوستان محدودیت دارد؟'},
        {question: 'آیا کسب درآمد در دعوت از دوستان محدودیت دارد؟'},
        {question: 'آیا دعوت از دوستان کلاهبرداری است؟'},
        {question: 'آیا دعوت از دوستان کلاهبرداری است؟'}
    ]

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }
    const onChangeSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`${styles.gradiant2}`}></div>
            <div className={`${styles.centerGradiant2}`}></div>
            <div className={`container d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div className={`${styles.container} container`}>
                    <div className={`row d-flex justify-content-between lh-lg fw-light`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-4 d-flex justify-content-center flex-column ss02 fontSizeSmall`}>
                            <h4 className={`fontWeightBold`}>چطور می توانیم کمکتان کنیم؟</h4>
                            <div className={`mt-3`}>
                                در این بخش می توانید راهنمای استفاده از سایت را مشاهده نمایید.
                                می توانید موضوع مد نظر خود را جتسجو یا از لیست مطالب به بخش مورد نظر خود وارد شوید
                            </div>
                            <div className={`${styles.startNow} d-flex align-items-center mt-2`}>
                                <Input placeholder={`جستجو در راهنمای سایت...`}
                                       className={`border-o text-white`}
                                       bordered={false}
                                       value={searchValue}
                                       onChange={onChangeSearchValue}
                                />
                                <button className={`btn btn-primary py-1 fontSizeSmall`}>
                                    <i className={`fas fa-arrow-left fontSizeTiny`}></i>
                                </button>
                            </div>
                        </div>
                        <div className={`col-xl-6 d-none d-xl-block position-relative`}>
                            <img src="/img/help-image.png" alt="help"/>
                        </div>
                    </div>
                    <h5 className={`fontWeightBold d-inline-block mt-4`}>دسترسی سریع</h5>
                    <div className={`row`}>
                        {fastAccess.map((item, key) => {
                            return <div key={key} className={`col-12 col-md-12 col-lg-6 my-2`}>
                                <div className={`card box-shadow ${styles.fastAccess} ss02 cursor-pointer`}>
                                    <div className={`card-body d-flex justify-content-between align-items-center`}>
                                        <span>{item.question}</span>
                                        <i className={`fas fa-arrow-left fa-lg lh-base ms-4`}></i>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    <TechnicalToolsGuide/>
                </div>
            </div>
        </>
    )
}

export default UsageHelp;