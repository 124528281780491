import React from 'react';
import ContactUs from "../pages/contact-us/contact-us";
import HelmetHOC from "./helmet-HOC";

const ContactUsHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - تماس با ما"/>
        <ContactUs/>
    </>;
};

export default ContactUsHOC;