import React, {useCallback, useEffect, useState} from "react";
import Login from "../home/login/login";
import ValidateOtpCode from "../home/validate-otp-code/validate-otp-code";
import SignUp from "../home/sign-up/sign-up";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import DrawerComponent from "../../shared/drawer/drawer";
import {Outlet} from "react-router-dom";
import Footer from "../../shared/footer/footer";
import styles from './main.module.scss';
import Loader from "../../shared/loader/loader";
import {
    currenciesTopicAction,
    festivalTopicAction,
    selectShowLoading,
    useAppDispatch,
    useAppSelector
} from "../../redux";
import {io, Socket} from "socket.io-client";

let socket: Socket;

const MainHOC = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [mobile, setMobile] = useState<string | undefined>(undefined)
    const [drawerTemplate, setDrawerTemplate] = useState('login');
    const showLoading = useAppSelector(selectShowLoading);
    const dispatch = useAppDispatch();

    const resetDrawer = (status) => {
        setOpenDrawer(status);
        setMobile(undefined)
        setDrawerTemplate('login')
    }
    const openLoginDrawer = async (mobile?) => {
        if (mobile) {
            setMobile(mobile)
        } else {
            setMobile(undefined)
        }
        setDrawerTemplate('login')
        setOpenDrawer(true);
    }
    const getDrawerComponent = () => {
        let drawerComponent: JSX.Element = <></>;
        if (drawerTemplate === 'login') {
            drawerComponent = <Login mobile={mobile} validationCodeSent={() => setDrawerTemplate('validationCode')}/>
        } else if (drawerTemplate === 'validationCode') {
            drawerComponent = <ValidateOtpCode openSignUp={() => setDrawerTemplate('signup')}
                                               onBackClick={() => setDrawerTemplate('login')}/>
        } else if (drawerTemplate === 'signup') {
            drawerComponent = <SignUp/>
        }
        return drawerComponent
    }

    const socketListenerAndEmitters = useCallback(() => {
        console.log('socketListenerAndEmitters')
        //currencies
        socket.emit("currenciesTopic", 0, (response) => {
            dispatch(currenciesTopicAction(response));
        });
        socket.on("currenciesEvent", (response) => {
            dispatch(currenciesTopicAction(response));
        });

        // festivals
        socket.emit("festivalsTopic", 0, (response) => {
            dispatch(festivalTopicAction(response));
        });
        socket.on("festivalsEvent", (response) => {
            dispatch(festivalTopicAction(response));
        });
    }, [dispatch])
    const testSocketConnection = () => {
        socket.emit("identity", () => {
        });
    }

    useEffect(() => {
        socket = io('wss://bitblex.com', {autoConnect: true, path: '/ws/'});
        testSocketConnection();
        const id = setInterval(() => {
            testSocketConnection()
        }, 5000);
        socketListenerAndEmitters();
        return () => clearInterval(id);
    }, [socketListenerAndEmitters]);
    useEffect(() => {
        const resetDrawerListener = EventEmitter.addListener('resetDrawer', resetDrawer)
        const openLoginDrawerListener = EventEmitter.addListener('openLoginDrawer', openLoginDrawer)
        const setOpenDrawerListener = EventEmitter.addListener('setOpenDrawer', (state) => setOpenDrawer(state))
        const setDrawerTemplateListener = EventEmitter.addListener('setDrawerTemplate', (state) => setDrawerTemplate(state))

        return () => {
            resetDrawerListener.remove()
            openLoginDrawerListener.remove()
            setOpenDrawerListener.remove()
            setDrawerTemplateListener.remove()
        }
    }, []);
    useEffect(() => {
        const onLogoutClickListener = EventEmitter.addListener('onLogoutClick', () => socketListenerAndEmitters())

        return () => {
            onLogoutClickListener.remove()
        }
    }, []);

    return (
        <>
            {showLoading && <Loader showLoading={showLoading}/>}
            {openDrawer ?
                <DrawerComponent childrenClassNames={`d-flex flex-column align-items-center justify-content-center`}
                                 helpComponentClassNames={`d-flex flex-column align-items-center justify-content-center`}
                                 showCloseButton={true}
                                 onCloseClick={() => resetDrawer(false)}
                                 childrenPosition={"left"}
                                 closeButtonPosition={"childrenSide"}>
                    {getDrawerComponent()}
                </DrawerComponent> : <div className={`d-none`}></div>}
            <div className={`${openDrawer && 'position-fixed w-100'}`}>
                <Outlet/>
                <div className={`${styles.bitblex} w-100 position-relative`}>
                    <div className={`d-flex justify-content-end ${styles.imageBorder}`}>
                        <img src="/img/Vector Bitblex.png" alt="vector icon pic"/>
                    </div>
                </div>
                <div className={`footer-container`}>
                    <Footer/>
                </div>
            </div>
        </>
    );
};

export default MainHOC;