import React, {useEffect, useState} from 'react';
import {
    selectDictionary,
    selectFestivalTopic,
    showLoadingAction,
    useAppDispatch,
    useAppSelector
} from "../../../../redux";
import {IReceipt} from "../../../../shared/interfacesAndTypes/interfaces";
import {confirmReceipt, createReceipt} from "../../../../shared/services/services";
import {notification, Skeleton, Tooltip} from "antd";

export enum feeTypeEnum {
    USDTTRC20 = 'USDTTRC20',
    TOMAN = 'TOMAN',
}

const PreInvoice = (props) => {
    const [responseData, setResponseData] = useState<IReceipt>({
        fee: {currency: "", festival: "", final: "", main: ""},
        pay: {currency: "", value: ""},
        rate: "",
        receive: {currency: "", value: ""},
        side: "",
        sufficientBalance: false,
        time: {now: "", validSec: 0, validUntil: ""}
    });
    const [feeType, setFeeType] = useState(props.transactionType === 'SELL' ? 'USDTTRC20' : 'TOMAN');
    const dictionary = useAppSelector(selectDictionary);
    const festival = useAppSelector(selectFestivalTopic)
    const [skeletonLoading, setSkeletonLoading] = useState(true);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSkeletonLoading(true)
        createReceipt({
            exchangeSide: props.transactionType,
            currency: props.selectedCoin,
            value: props.amount,
            feeSide: feeType === feeTypeEnum.TOMAN ? feeTypeEnum.USDTTRC20 : feeTypeEnum.TOMAN
        }).then(response => {
            if (!!response && response.data) {
                setResponseData(response.data as IReceipt)
                setSkeletonLoading(false)
                if (!response.data['sufficientBalance']) {
                    notification['error']({
                        message: 'موجودی حساب شما کافی نمی باشد',
                        placement: 'bottomLeft'
                    });
                }
            }
        })
    }, [feeType, props.amount, props.exchangeSide, props.selectedCoin, props.transactionType])

    const onChangeFeeType = async () => {
        if (feeType === feeTypeEnum.TOMAN) {
            setFeeType(feeTypeEnum.USDTTRC20)
        } else {
            setFeeType(feeTypeEnum.TOMAN)
        }
    }
    const onApproveClick = async () => {
        dispatch(showLoadingAction(true))
        const res = await confirmReceipt({
            exchangeSide: responseData.side,
            currency: props['selectedCoin'] === feeTypeEnum.USDTTRC20 && 'USDT',
            payValue: responseData.pay.value,
            receiveValue: responseData.receive.value
        })
        dispatch(showLoadingAction(false))
        if (!!res && res.statusCode === 200) {
            props.onApproveClick()
        }
    }
    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 start-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column h-100 mt-5 px-3`}>
                <div className={`w-100 h-100 d-flex flex-column`}>
                    <div className={`d-flex align-items-center justify-content-center`}>
                        <h3 className={`secondaryColor fontWeightBold text-center`}>پیش فاکتور</h3>
                    </div>
                    {!skeletonLoading ?
                        <>
                            <div className={`d-flex flex-column`}>
                                <h6 className={`text-white mt-3 mb-4 ss02`}>
                                    شما
                                    قصد {responseData.side === 'SELL' ? 'فروش' : 'خرید'} {props['amount']} واحد {dictionary.currencies[props['selectedCoin']]} را
                                    دارید
                                </h6>
                                {responseData.sufficientBalance &&
                                    <div className={`w-100`}>
                                        <div className={`card box-shadow bgTertiaryLightColor`}
                                             style={{border: '1px solid #10d078'}}>
                                            <div className={`p-2`}>
                                                <div className={`text-white text-end m-0 ss02`}>
                                                    {responseData.time.now.split(' ')[0]}
                                                    <i className={`fas fa-clock ms-2`}></i>
                                                </div>
                                                <div className={`text-white m-0 ss02`}>
                                                    <i className={`fas fa-hourglass-end text-danger me-2`}></i>
                                                    مهلت پرداخت تا: {responseData.time.validUntil.split(' ')[0]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={`w-100 h-100 d-flex flex-column justify-content-between`}>
                                {feeType === feeTypeEnum.USDTTRC20 ?
                                    <>
                                        <div className={`w-100`}>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                <div className={`p-2 text-center`}>
                                                    قیمت هر واحد:
                                                    <span className={` ss02`}>
                                                    {Number(responseData.rate).toLocaleString('US')}
                                                </span>{' '}{dictionary.currencies[feeTypeEnum.TOMAN]}
                                                </div>
                                            </div>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                <div className={`p-2 text-center ss02`}>
                                                    مبلغ
                                                    کارمزد: {Number(responseData.fee.final).toLocaleString('US')} {dictionary.currencies[responseData.fee.currency]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`w-100`}>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                <div className={`p-2 text-center position-relative`}>
                                                    {responseData.fee.currency === responseData.receive.currency ?
                                                        <Tooltip
                                                            title={<span><i
                                                                className={`fs-7 fas fa-money-bill me-2`}></i>با کارمزد</span>}>
                                                        <span
                                                            className={`secondaryColor ss02`}>دریافت شما: {Number(responseData.receive.value).toLocaleString('US')} {dictionary.currencies[responseData.receive.currency]}</span>
                                                        </Tooltip> :
                                                        <span
                                                            className={` ss02`}>دریافت شما: {Number(responseData.receive.value).toLocaleString('US')} {dictionary.currencies[responseData.receive.currency]}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                {responseData.fee.currency !== responseData.receive.currency ?
                                                    <Tooltip
                                                        title={<span><i className={`fs-7 fas fa-money-bill me-2`}></i>با کارمزد</span>}>
                                                        <div className={`p-2 text-center ss02 secondaryColor`}>
                                                            پرداخت
                                                            شما: {Number(responseData.pay.value).toLocaleString('US')} {dictionary.currencies[responseData.pay.currency]}
                                                        </div>
                                                    </Tooltip> :
                                                    <div className={`p-2 text-center ss02`}>
                                                        پرداخت
                                                        شما: {Number(responseData.pay.value).toLocaleString('US')} {dictionary.currencies[responseData.pay.currency]}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={`w-100`}>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                <div className={`p-2 text-center ss02`}>
                                                    قیمت هر واحد:
                                                    <span className={``}>
                                            {Number(responseData.rate).toLocaleString('US')}
                                        </span>
                                                    {' '}{dictionary.currencies[feeTypeEnum.TOMAN]}
                                                </div>
                                            </div>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                <div className={`p-2 text-center ss02`}>
                                                    مبلغ
                                                    کارمزد: {Number(responseData.fee.final).toLocaleString('US')} {dictionary.currencies[responseData.fee.currency]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`w-100`}>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                {responseData.fee.currency === responseData.receive.currency ?
                                                    <Tooltip
                                                        title={<span><i className={`fs-7 fas fa-money-bill me-2`}></i>با کارمزد</span>}>
                                                        <div className={`p-2 text-center ss02 secondaryColor`}>
                                                            دریافت
                                                            شما: {Number(responseData.receive.value).toLocaleString('US')} {dictionary.currencies[responseData.receive.currency]}
                                                        </div>
                                                    </Tooltip> :
                                                    <div className={`p-2 text-center ss02`}>
                                                        دریافت
                                                        شما: {Number(responseData.receive.value).toLocaleString('US')} {dictionary.currencies[responseData.receive.currency]}
                                                    </div>
                                                }
                                            </div>
                                            <div className={`card box-shadow bgTertiaryLightColor text-white mt-1`}>
                                                {responseData.fee.currency !== responseData.receive.currency ?
                                                    <Tooltip
                                                        title={<span><i className={`fs-7 fas fa-money-bill me-2`}></i>با کارمزد</span>}>
                                                        <div
                                                            className={`p-2 text-center position-relative secondaryColor`}>
                                                        <span
                                                            className={` ss02`}>پرداخت شما: {Number(responseData.pay.value).toLocaleString('US')} {dictionary.currencies[responseData.pay.currency]}</span>
                                                        </div>
                                                    </Tooltip> :
                                                    <div className={`p-2 text-center position-relative`}>
                                                    <span
                                                        className={` ss02`}>پرداخت شما: {Number(responseData.pay.value).toLocaleString('US')} {dictionary.currencies[responseData.pay.currency]}</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </> :
                        <Skeleton active loading={true}></Skeleton>}
                </div>
                <div className={`w-100 mb-4`}>
                    {(!!festival && Object.keys(festival).find(item => item === props.selectedCoin) && responseData.fee.festival) &&
                        <>
                            <i className={`fas fa-gift text-white secondaryColor me-2`}></i>
                            <span className={`text-white mt-3 ss02`}>
                                کارمزد شما در این سفارش {responseData.fee.main} می باشد که به منزله جشنواره جاری به میزان {responseData.fee.festival} کاهش پیدا کرد
                            </span>
                        </>
                    }
                    {!skeletonLoading ?
                        <button className={`btn btnPrimary w-100 mt-3`} onClick={() => onChangeFeeType()}>
                            <i className={`fas fa-rotate text-white me-1`}></i> تغییر پرداخت کارمزد
                        </button> :
                        <Skeleton active loading={true}></Skeleton>
                    }
                    {!!responseData && <div className={`w-100 d-flex align-items-center justify-content-between`}>
                        {!responseData.sufficientBalance ?
                            <button
                                className={`btn btn-danger mt-2 w-5 d-inline-flex w-100 align-items-center justify-content-center`}
                                onClick={() => props.onOpenWallet()}>
                                <i className={`fas fa-wallet text-white me-1`}></i> رفتن به کیف پول
                            </button> :
                            <>
                                <button
                                    className={`btn btnSecondary mt-2 me-1 d-inline-flex w-100 align-items-center justify-content-center`}
                                    onClick={() => onApproveClick()}>
                                    <i className={`fas fa-check-circle text-white me-1`}></i> تایید
                                </button>
                                <button
                                    className={`btn btn-danger mt-2 ms-1 w-5 d-inline-flex w-100 align-items-center justify-content-center`}
                                    onClick={() => props.onBackClick?.()}>
                                    <i className={`fas fa-times-circle text-white me-1`}></i> لغو
                                </button>
                            </>}
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default PreInvoice;