import React from 'react';
import style from './why-bitblex.module.scss';

const WhyBitBlex = (props) => {
    return (
        <div className={`${style.mainContainer}`}>
            <h2 className={`fontWeightBold`}>
                <span className={`primaryColor`}>چرا </span> {' '} <span className={`text-white`}>بیتبلکس؟</span>
            </h2>
            <span className={`fontSizeSmall text-white ss02`}>
                در حوزه رمزارز مطمئن،  بی دردسر و منحصر بفرد با امکان معامله در وب سایت و ربات اختصاصی تلگرام
            </span>
            <div className={`position-relative w-100 mt-5`}>
                <div className={`${style.firstCard} card box-shadow`}>
                    <div className={`row`}>
                        <div className="col-12 col-lg-6 p-0">
                            <div className={`d-flex flex-column h-100 align-items-center justify-content-around`}>
                                <div className={`d-flex align-items-center w-100`}>
                                    <img src="/img/24support.png" alt="24support"/>
                                    <span className={`text-white d-flex flex-column ms-3`}>
                                        <span className={`fontWeightBold ss02`}>پشتیبانی سریع</span>
                                        <span className={`fontSizeTiny mt-2`}>
                                            در کوتاه ترین زمان راهنما و پاسخگو سوالات شما هستیم
                                        </span>
                                    </span>
                                </div>
                                <div className={`d-flex align-items-center w-100 mt-4`}>
                                    <img src="/img/best-price.png" alt="best-price"/>
                                    <span className={`text-white d-flex flex-column ms-3`}>
                                        <span className={`fontWeightBold`}>بهترین نرخ تتر</span>
                                        <span className={`fontSizeTiny mt-2`}>تک نرخی بودن با کارمزد حداقل</span>
                                    </span>
                                </div>
                                <div className={`d-flex align-items-center w-100 mt-4`}>
                                    <img src="/img/why-bitblex-chart.png" alt="why-bitblex-chart"/>
                                    <span className={`text-white d-flex flex-column ms-3`}>
                                        <span className={`fontWeightBold`}>محدودیت بالای حجم معاملات</span>
                                        <span className={`fontSizeTiny mt-2`}>
                                            حجم بالای معاملات در وب سایت و ربات اختصاصی
                                        </span>
                                    </span>
                                </div>
                                <div className={`d-flex align-items-center w-100 mt-4`}>
                                    <img src="/img/fast-deal.png" alt="fast-deal"/>
                                    <span className={`text-white d-flex flex-column ms-3`}>
                                        <span className={`fontWeightBold`}>معاملات حرفه ای</span>
                                        <span
                                            className={`fontSizeTiny mt-2 ss02`}>احراز هویت آسان و سریع در چند لحظه</span>
                                    </span>
                                </div>
                                <div className={`d-flex align-items-center w-100 mt-4`}>
                                    <img src="/img/private-wallet.png" alt="private-wallet"/>
                                    <span className={`text-white d-flex flex-column ms-3`}>
                                    <span className={`fontWeightBold`}>امنیت بالای کیف پول</span>
                                    <span className={`fontSizeTiny mt-2`}>
                                        امکان ساخت و استفاده از ولت شخصی برای هر کاربر با امنیت و سرعت بالا
                                    </span>
                                </span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-lg-6 p-0 position-relative d-none d-lg-flex align-items-center justify-content-center">
                            <img src="/img/why-bitblex.png" alt="why-bitblex" className={`${style.img}`}/>
                        </div>
                    </div>
                </div>
                {props.showBottom ? <div
                    className={`${style.secondCard} card box-shadow border-0 flex-column-reverse flex-md-row justify-content-around`}>
                    <div className={`d-flex flex-column align-items-center justify-content-center mt-4`}>
                        <span className={`secondaryColor`}>گردش مالی ماهیانه</span>
                        <span className={`mt-3 ss02 fs-5`}>+ 20 میلیارد تومان</span>
                    </div>
                    <div className={`d-flex flex-column align-items-center justify-content-center mt-4`}>
                        <span className={`secondaryColor`}>پشتیبانی</span>
                        <span className={`mt-3 ss02 fs-5`}>پشتیبانی آنلاین </span>
                    </div>
                    <div className={`d-flex flex-column align-items-center justify-content-center mt-4`}>
                        <span className={`secondaryColor`}>معاملات</span>
                        <span className={`mt-3 ss02 fs-5`}>+ 6000 تراکنش</span>
                    </div>
                </div> : null}
            </div>
        </div>
    );
};

export default WhyBitBlex;
