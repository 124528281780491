import React from 'react';

const Roles = () => {
    return (
        <div className={`row`}>
            <div className={`col-12  fontSizeNormal overflow-y`}>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        بیت بلکس، تابع قوانین جمهوری اسلامی ایران بوده و بستری برای تبادل دارایی های دیجیتال مانند بیت
                        کوین،
                        لایت کوین و اتریوم با ریال می باشد
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد
                    </li>
                </ul>
                <hr/>
                <h4 className={`text-white`}>تعهدنامه فعالیت در بستر تبادل دارایی های دیجیتال بیت بلکس</h4>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        کاربران موظند قبل از ثبت نام تمامی مفاد این تعهدنامه را مطالعه کرده و صرفا در صورت پذیرش آن
                        اقدام به ثبت نام و معامله نمایند. همچنین تایید این قوانین به معنای قبول آن می باشد
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربران سایت می پذیرند که کلیه ی فعالیت های آن ها در بیت بلکس در چارچوب قوانین جمهوری اسلامی
                        بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام نخواهند داد.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        احراز هویت برای استفاده از کلیه خدمات بیت بلکس در بازار ریالی به ارز دیجیتال ضروری بوده و
                        کاربران موظفند اطلاعات صحیح خود را بارگزاری نموده و
                        .در صورت نیاز با مراجعه حضوری به دفتر پشتیبانی تایید نهایی را دریافت کنند
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        بدیهی است در صورت وجود هرگونه تخلف در احراز هویت، مسئولیت به عهده ی فرد متخلف بوده و بیت بلکس حق
                        توقف ارائه خدمات به کاربر مذبور را داشته و درصورت صلاحدید کلیه موجودی ارزی آنها تا ارجاع موارد
                        تخلف به مراجع ذی صالح و دریافت حکم قانونی ممکن است بلوکه شود.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        بیت بلکس خود را ملزم به حفظ اطالعات شخصی کاربران خود می داند
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربران بیت بلکس تعهد می دهند که از خدمات سایت بیت بلکس تنها برای خود استفاده نموده و مسئولیت
                        استفاده از خدمات بیت بلکس برای فرد غیر که فرآیند احراز هویت را طی نکرده باشد به عهده کاربر بوده
                        و متوجه وب سایت نمی باشد
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        شماره حساب اعلام شده به سایت و همچنین آدرس کیف پول ها جهت برداشت رمزارز نیز می بایست متعلق به
                        کاربر بوده و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر نیستند
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربران بیت بلکس اذعان می دارند که از ریسک های مربوط به سرمایه گذاری در ارزهای دیجیتال مطلع بوده
                        و با علم به این موضوع اقدام به سرمایه گذاری و تبادل می نمایند
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        بیت بلکس به عنوان بازار آنلاین تبادل ارزهای دیجیتال، هیچ گونه مسئولیتی در قبال نحوه ی معاملات
                        کاربران و سود و زیان حاصل از آن ندارد
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از جمله رمزعبور کاربر، به عهده ی شخص کاربر بوده
                        و بیت بلکس هیچ مسئولیتی به عهده نخواهد داشت.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        اکیداً توصیه می شود از رمزعبور پیچیده و امن و کد شناسایی دوعاملی استفاده شود
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        بیت بلکس این اطمینان را می دهد که دارایی های کاربران را نزد خود به امانت و به بهترین شکل و با
                        بالاترین استانداردهای امنیتی ممکن، حفظ نماید. در صورت بروز هرگونه مشکل امنیتی، بیت بلکس متعهد به
                        جبران خسارت در اسرع وقت خواهد بود.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        در صورت تمایل برای برداشت ارزهای دیجیتال، مسئولیت ارائه ی آدرس صحیح کیف پول به عهده ی کاربر
                        خواهد بود. در صورت بروز هر گونه مشکل اعم از اشتباه در ورود آدرس صحیح، نقص آدرس، مشکلات کیف پول
                        مقصد و بلوکه شدن دارایی های کاربران در کیف پول مقصد، هیچ گونه مسئولیتی به عهده ی بیت بلکس نخواهد
                        بود.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        درخواست برداشت ریال از حساب کاربری در سریع ترین زمان ممکن پس از ثبت، بررسی خواهد شد. زمان واریز
                        پول به حساب کاربران بر اساس محدودیت های انتقال وجه بین بانکی، متفاوت خواهد بود . برای اطلاعات
                        بیش تر، به قوانین انتقال وجه بین بانکی ( پایا ، ساتنا ) مراجعه فرمایید.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربر می پذیرد که به جز در موارد مورد تعهد بیت بلکس ، حق هیچ گونه داعیه، طلب و شکایت از سایت بیت
                        بلکس ، مدیران، کارمندان و افراد مرتبط با این سایت را نخواهد داشت.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        اگر بیت بلکس تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا رمزارزی را به حساب کاربر منظور یا در
                        محاسبات خود هر نوع اشتباهی نماید، هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه
                        تشریفات اداری و قضائی و دریافت اجازه کتبی از متعهد (صاحب حساب) در رفع اشتباه و برداشت از حساب
                        های وی اقدام نماید و تشخیص بیت بلکس نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب
                        معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد بیت بلکس از هر جهت از
                        خود ساقط می نماید.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، بیت بلکس حق دارد مستقلاً آن معامله را
                        ابطال و دارایی های هر یک از طرفین را به حساب خودشان عودت دهد. بدیهی است که در صورتی که اشکال از
                        سمت بیت بلکس باشد، موظف خواهد بود که جبران خسارت نماید و در غیراین صورت کاربر حق هرگونه اعتراض و
                        ادعایی را در خصوص نحوه عملکرد بیت بلکس از هر جهت از خود ساقط می نماید.
                    </li>
                </ul>
                <hr/>
                <h4 className={`text-white`}>هزینه ها و کارمزدها</h4>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        هزینه خدمات و کارمزد تراکنش های مالی انجام شده در بیت بلکس مطابق با توضیحات ارائه شده در صفحه
                        کارمزدها و هزینه خدمات می باشد.
                    </li>
                </ul>
                <hr/>
                <h4 className={`text-white`}>قوانین مربوط به اجزای کارکردی</h4>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        بیت بلکس شامل اجزای کارکردی و قابلیت های مختلفی است که هر یک طبق قوانین و ملاحظات اطلاع رسانی
                        شده برای آن ها مدیریت می شوند. برای اطلاع از این قوانین به صفحات مربوط به سوالات متداول، طرح
                        ارجاع کاربران و قوانین جاری بیت بلکس مراجعه نمایید.
                    </li>
                </ul>
                <hr/>
                <h4 className={`text-white`}>فرآیند تایید هویت</h4>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        به منظور پیروی از قوانین ضد پول شویی، نیاز است تمام کاربران نسبت به تایید هویت خود اقدام کنند.
                        تایید هویت در دو مرحله با ارائه مدارک مربوطه صورت می گیرد.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        ارسال اطلاعات هویتی اعم از اطلاعات کارت ملی و استعلام آن ها از ثبت احوال توسط وب سایت بیت بلکس و
                        ارسال شماره همراه و تایید آن توسط و مرحله آخر ارسال عکس هویتی به همراه کارت ملی.بیت بلکس
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربر میپذرید از تمامی قوانین مربوط به احراز هویت پیروی کند و با ارایه مدارک هویتی درست اقدام به
                        معامله واریز و برداشت وجه خود نماید.
                    </li>
                </ul>
                <hr/>
                <h4 className={`text-white`}>تغییر در قوانین</h4>
                <ul>
                    <li style={{listStyleType: 'disc'}}>
                        هرگونه تغییر در قوانین، شروط ارائه خدمات، کارمزدها و مقررات مشابه، از طریق سایت بیت بلکس به
                        کاربران اطلاع رسانی خواهد شد.
                    </li>
                    <li style={{listStyleType: 'disc'}}>
                        کاربر موظف به رعایت کلیه قوانین وب سایت در هر زمان و مکان می باشد.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Roles;