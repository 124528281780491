import React, {useState} from 'react';
import styles from "./technical-tools-guide.module.scss";

const TechnicalToolsGuide = () => {

    const helpList = [
        {id: 1, title: 'راهنمای ثبت نام', image: '/img/signUpHelp.png'},
        {id: 2, title: 'راهنمای احراز هویت', image: '/img/authHelp.png'},
        {id: 3, title: 'راهنمای معامله', image: '/img/exchangeHelp.png'},
        {id: 4, title: 'نحوه برداشت و واریز', image: '/img/withdrawalWayHelp.png'},
        {id: 5, title: 'سوالات متداول', image: '/img/faqHelp.png'},
        {id: 6, title: 'کارمزد ها', image: '/img/feeHelp.png'},
        {id: 7, title: 'قوانین و مقررات', image: '/img/rolesHelp.png'},
    ]
    const [selectedHelp, setSelectedHelp] = useState({id: -1, title: '', image: ''})

    return (
        <div className={`row mt-5`}>
            <div className={`col-12 col-md-12 col-lg-3`}>
                <div className={`card box-shadow ${styles.helpList} ss02`}>
                    <h6 className={`fontWeightBold d-inline-block mt-3 ms-3`}>لیست راهنما ها</h6>
                    {helpList.map(help => {
                        return <div key={help.id}
                                    className={`text-white mt-2 py-1 cursor-pointer ${selectedHelp.id === help.id ? styles.selectedHelp : ''}`}
                                    onClick={() => setSelectedHelp(help)}>
                            <img src={help.image} alt={help.title} className={`mx-2`}/>
                            <span className={`text-white fa-sm lh-base`}>{help.title}</span>
                        </div>
                    })}
                </div>
            </div>
            <div className={`col-12 col-md-12 col-lg-9 mt-4 mt-lg-0`}>
                <h5 className={`fontWeightBold d-inline-block`}>
                    آموزش تصویری نحوه کار با ابزارهای تحلیلی
                </h5>
                <p className={`text-white lh-base mt-2`}>
                    کسب درآمد شما با دعوت دوستانتان به بیت بلکس یک بازی برد-برد برای همگی است. شما فردی
                    هستید
                    که در سود دوستانتان شریک می‌شوید، بیت بلکس به لطف شما دارای خانواده بزرگتری می‌شود،
                    دوستانتان می‌توانند در بازار ارزهای دیجیتال بر روی آینده سرمایه‌گذاری کنند و یا با دعوت
                    از دوستانشان درآمد کسب کنند، همچنین اکوسیستم ارزهای دیجیتال با تعامل بیشتر یک گام دیگر
                    به تعالی و بلوغ نزدیک‌تر می‌شود. پس همین حالا دوستان‌‌تان را دعوت کنید.
                </p>
                <div className={`w-100 ${styles.videoContainer}`}>

                </div>
                <p className={`text-white lh-base mt-2`}>
                    همچنین شما می‌توانید در ردیف دوم آدرس کیف پول ارز مورد نظرتان را به همراه موجودی آن
                    مشاهده کنید. در ردیف بعدی شما به پیش‌بینی یک ساعت آینده ارزی که انتخاب کردید دسترسی
                    خواهید داشت که به وسیله هوش مصنوعی انجام می‌شود. در ادامه به بررسی بخش‌های بعدی ابزارهای
                    تحلیل خواهیم پرداخت.
                </p>
                <h6 className={`fontWeightBold d-inline-block mt-3`}> پیش بینی تکنیکال بازار</h6>
                <p className={`text-white lh-base mt-1`}>
                    به عقیده بسیاری از کارشناسان تحلیل تکنیکال، آینده قیمت یک ارز دیجیتال براساس ابزارهای
                    مختلف تحلیل تکنیکال (میانگین‌های متحرک، اسیلاتور و پیوت‌ها)، قابل پیش‌بینی است.
                </p>
                <div className={`w-100 ${styles.videoContainer}`}>

                </div>
                <p className={`text-white lh-base mt-3`}>
                    همچنین شما می‌توانید در ردیف دوم آدرس کیف پول ارز مورد نظرتان را به همراه موجودی آن
                    مشاهده کنید. در ردیف بعدی شما به پیش‌بینی یک ساعت آینده ارزی که انتخاب کردید دسترسی
                    خواهید داشت که به وسیله هوش مصنوعی انجام می‌شود. در ادامه به بررسی بخش‌های بعدی ابزارهای
                    تحلیل خواهیم پرداخت.
                </p>
            </div>
        </div>
    )
}

export default TechnicalToolsGuide;