import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    currenciesTopicAction,
    festivalTopicAction,
    selectIsAuthenticated,
    selectShowLoading,
    selectToken,
    selectUnreadMessage,
    unreadMessagesAction,
    useAppDispatch,
    useAppSelector,
    userProfileSelector
} from "../redux";
import {IProfileProps} from "../shared/interfacesAndTypes/interfaces";
import {Button, notification, Space} from "antd";
import {io, Socket} from "socket.io-client";
import {axiosWrapper} from "../shared/services/base-service";
import {useNavigate} from "react-router";
import Loader from "../shared/loader/loader";
import ExchangeComponent from "../pages/exchange/exchange";
import {TourProvider} from '@reactour/tour'
import HelmetHOC from "./helmet-HOC";

let socket: Socket;

const ExchangeHoc = () => {
    const showLoading = useAppSelector(selectShowLoading);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const userProfile: IProfileProps = useAppSelector(userProfileSelector)
    const unreadMessages = useAppSelector(selectUnreadMessage);
    const dispatch = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();
    const audio = useRef<HTMLAudioElement | undefined>(typeof Audio !== "undefined" ? new Audio('/notification.mp3') : undefined);
    const [openMessage, setOpenMessage] = useState(false)
    const navigate = useNavigate();

    const onOpenMessages = useCallback(() => {
        setOpenMessage(false)
        setOpenMessage(true)
        notification.destroy()
    }, [])
    const socketListenerAndEmitters = useCallback(() => {
        //currencies
        socket.emit("currenciesTopic", 0, (response) => {
            dispatch(currenciesTopicAction(response));
        });
        socket.on("currenciesEvent", (response) => {
            dispatch(currenciesTopicAction(response));
        });

        // festivals
        socket.emit("festivalsTopic", 0, (response) => {
            dispatch(festivalTopicAction(response));
        });
        socket.on("festivalsEvent", (response) => {
            dispatch(festivalTopicAction(response));
        });

        // messages
        socket.on(`messageEvent:${userProfile.id}`, (response) => {
            notification.destroy()
            const key = `open${Date.now()}`;
            const btn = (
                <Space>
                    <Button type="primary" size="middle" onClick={() => onOpenMessages()}>مشاهده پیام ها</Button>
                </Space>
            );
            api.open({
                message: 'شما پیام جدید دارید',
                description: 'آیا تمایل به مشاهده پیام ها دارید؟',
                duration: 0,
                btn,
                key,
                placement: 'bottomRight'
            });
            dispatch(unreadMessagesAction(unreadMessages + 1));
            if (!!audio.current) {
                audio.current.loop = false;
                audio.current.play();
            }
        });

    }, [api, dispatch, unreadMessages, userProfile.id, onOpenMessages])
    const token = useAppSelector(selectToken);
    if (!!token && isAuthenticated) {
        axiosWrapper.defaults.headers.common['Authorization'] = token;
    }

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
            return;
        }
        socket = io('wss://bitblex.com', {autoConnect: true, path: '/ws/'});
        testSocketConnection();
        const id = setInterval(() => {
            testSocketConnection()
        }, 5000);
        socketListenerAndEmitters();
        return () => clearInterval(id);
    }, [isAuthenticated, navigate, socketListenerAndEmitters, token])
    const testSocketConnection = () => {
        socket.emit("identity", () => {
        });
    }


    return (
        <>
            {contextHolder}
            {showLoading && <Loader showLoading={showLoading}/>}
            <HelmetHOC title="بیت بلکس | Bitblex - معاملات"/>
            <TourProvider steps={[]}>
                {isAuthenticated && <ExchangeComponent openMessage={openMessage}/>}
            </TourProvider>
        </>
    );
};

export default ExchangeHoc;