import React, {useState} from 'react';
import FavWithdrawalList from "../../fav-withdrawals/fav-withdrawal-list/fav-withdrawal-list";
import {IFavWithdrawal} from "../../../../shared/interfacesAndTypes/interfaces";
import {showLoadingAction, useAppDispatch} from "../../../../redux";
import {depositToman} from "../../../../shared/services/services";
import {currencyTypeEnum} from "../../fav-withdrawals/fav-withdrawals";

const OnlineDepositToman = (props) => {
    const {tomanDepositAmount} = props;
    const [favWithdrawal, setFavWithdrawal] = useState<IFavWithdrawal>({
        address: "",
        bank: "",
        cardNo: "",
        network: "",
        shebaNo: "",
        type: "",
        verified: false
    });
    const dispatch = useAppDispatch();

    const onPayClick = async () => {
        dispatch(showLoadingAction(true))
        let response = await depositToman({value: tomanDepositAmount, cardNo: favWithdrawal.cardNo})
        if (!!response && response.statusCode === 200) {
            window.open(response.data['paymentUrl'], '_self', 'noopener,noreferrer');
            props.onBackClick()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex justify-content-between flex-column h-100 mt-5 px-3`}>
                <h3 className={`primaryColor fontWeightBold text-center`}>انتخاب حساب برگزیده</h3>
                <FavWithdrawalList favType={currencyTypeEnum.TOMAN}
                                   onSelectFavWithdrawal={(favWithdrawal) => setFavWithdrawal(favWithdrawal)}
                                   selectable={true}/>
            </div>
            <div className={`w-100 d-flex justify-content-center`}>
                <div
                    className={`card box-shadow bgTertiaryLightColor text-white mt-3 w-100 d-flex flex-column align-items-center justify-content-center`}>
                    <div className={`card-body`}>
                        <div className={`d-flex align-items-center justify-content-center`}>
                            <i className="fas fa-exclamation-circle fs-3 text-white"></i>
                        </div>
                        <div className={`d-flex flex-column mt-2 ss02`}>
                            حساب مبدا را انتخاب کنید و توجه داشته باشید که پرداخت تنها با حساب انتخابی مجاز می‌باشد
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-100 d-flex mb-4`}>
                <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                        disabled={!favWithdrawal.cardNo || favWithdrawal.cardNo.length === 0}
                        onClick={() => onPayClick()}>پرداخت
                </button>
            </div>
        </div>
    );
};

export default OnlineDepositToman;