import React from 'react';
import TlgBot from "../pages/tlg-bot/tlg-bot";
import HelmetHOC from "./helmet-HOC";

const TlgBotHOC = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - ربات تلگرام"/>
        <TlgBot/>
    </>
};

export default TlgBotHOC;