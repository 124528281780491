import React, {useEffect} from 'react';
import {
    selectCurrenciesTopic,
    selectDictionary,
    selectFestivalTopic,
    selectUserProfile,
    useAppSelector
} from "../../../../redux";
import {Button, notification, Skeleton, Space} from "antd";
import {IProfileProps} from "../../../../shared/interfacesAndTypes/interfaces";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

const TransactionType = (props) => {
    const dictionary = useAppSelector(selectDictionary);
    const currenciesTopic = useAppSelector(selectCurrenciesTopic);
    const festival = useAppSelector(selectFestivalTopic)
    const date = new Date().toLocaleString('fa-IR')
    const profile: IProfileProps = useAppSelector(selectUserProfile);
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();

    const onOpenAuthentication = (key) => {
        notification.destroy(key)
        props.onOpenAuthentication()
    }
    const afterSelectTransactionType = () => {
        if (!!profile && profile.userLevel && !!profile.userLevel.id && profile.userLevel.id === 3) {
            const key = `open${Date.now()}`;
            const btn = (
                <Space>
                    <Button type="link" size="small" onClick={() => notification.destroy(key)}>فعلا نه!</Button>
                    <Button type="primary" size="small" onClick={() => onOpenAuthentication(key)}>تکمیل اطلاعات</Button>
                </Space>
            );
            notification.error({
                message: 'برای خرید ارز باید احراز هویت خود را تکمیل کنید',
                // description: 'برای خرید ارز باید احرازهویت خود را تکمیل کنید. آیا تمایل به شروع فرآیند احراز هویت دارید؟',
                btn,
                key,
                placement: 'bottomLeft',
            });
            return
        }
        if (!!profile && profile.userLevel && !!profile.userLevel.id && profile.userLevel.id === 4) {
            notification.warning({
                message: 'هشدار',
                description: 'برای خرید ارز باید تا تایید احرازهویت توسط کارشناسان صبر کنید',
                placement: 'bottomLeft'
            });
            return
        }
        props.afterSelectTransactionType('buy')
    }

    useEffect(() => {
        const queryParams: { [key: string]: string } = {};
        searchParams.forEach((value, key) => queryParams[key] = value);
        if (!!queryParams && !!queryParams.transactionType) {
            props.afterSelectTransactionType(queryParams.transactionType)
            navigate(location.pathname);
        }
    }, [location, navigate, props, searchParams])

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 start-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column mt-5 px-3 h-100`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>معاملات</h3>
                {(!!dictionary && dictionary.currencies[props.selectedCoin]) ?
                    <h5 className={`text-white mt-3`}>
                        شما قصد معامله {dictionary.currencies[props.selectedCoin]} را دارید
                    </h5> : <Skeleton avatar active loading={true}></Skeleton>}
                {(!!dictionary && dictionary.currencies[props.selectedCoin]) ? <>
                    <h6 className={`text-white mt-3`}>
                        قیمت هر واحد:
                        <span className={` ss02`}>
                            {Number(currenciesTopic[props.selectedCoin].currentPrice).toLocaleString('US')}
                        </span>
                    </h6>
                    <h6 className={`text-white mt-1 ss02`}>
                        حداقل معامله: <span
                        className={` `}> {currenciesTopic[props.selectedCoin].minExchange}</span> واحد
                    </h6>
                </> : <Skeleton avatar active loading={true}></Skeleton>}
                <h6 className={`text-white text-end mt-1`}>{date}<i className={`fas fa-clock ms-2`}></i></h6>
                {(!!festival && Object.keys(festival).find(item => item === props.selectedCoin)) && <>
                    <hr className={`mb-2 mt-4 primaryColor`} style={{height: 3}}/>
                    <i className={`fas fa-gift text-white secondaryColor me-2`}></i>
                    <span className={`text-white mt-3`}>
                        جشنواره تخفیف {festival[props.selectedCoin].discountPercent} درصد کارمزد تا سقف {festival[props.selectedCoin].maxExchange} واحد معامله در روز
                    </span>
                </>}
                <hr className={`my-2 primaryColor`} style={{height: 3}}/>
                <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                        onClick={() => afterSelectTransactionType()}>
                    <i className={`fas fa-basket-shopping me-2`}></i>
                    خرید
                </button>
                <button className={`btn btnPrimary mt-4 w-100 fontWeightBold`}
                        onClick={() => props.afterSelectTransactionType('sell')}>
                    <i className={`fas fa-cash-register me-2`}></i>
                    فروش
                </button>
            </div>
        </div>
    );
};

export default TransactionType;