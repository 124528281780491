/* eslint-disable */
import React, {useEffect, useState} from 'react';
import {withdrawalCryptoService, withdrawalOtpRequest} from "../../../../shared/services/services";
import {selectCurrenciesTopic, showLoadingAction, useAppDispatch, useAppSelector} from "../../../../redux";
import {Button, InputNumber, notification} from "antd";
import {CountdownCircleTimer} from "react-countdown-circle-timer";

const ApproveWithdrawalUsdt = (props) => {
    const currenciesTopic = useAppSelector(selectCurrenciesTopic);
    const [otpCode, setOtpCode] = useState(0);
    const [sendOtpAgain, setSendOtpAgain] = useState<number | boolean>(-1);
    const dispatch = useAppDispatch();

    useEffect(() => {
        onSendOtpAgainClick();
    }, []);
    const onApproveClick = async () => {
        if (otpCode === 0) {
            notification['error']({
                message: 'خطا',
                description: 'لطفا کد ارسال شده در پیامک را وارد کنید',
                placement: 'bottomRight'
            });
            return
        }
        dispatch(showLoadingAction(true))
        let response = await withdrawalCryptoService({
            otp: otpCode,
            currency: "USDTTRC20",
            value: props.USDTWithdrawalAmount,
            address: props.address
        })
        if (!!response && response.statusCode === 200) {
            notification['success']({
                message: 'تراکنش با موفقیت انجام شد',
                description: '',
                placement: 'bottomRight'
            });
            props.onApproveClick()
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onChangeOtpCode = (amount) => {
        setOtpCode(amount)
    }
    const onSendOtpAgainClick = async () => {
        dispatch(showLoadingAction(true))
        const res = await withdrawalOtpRequest()
        if (!!res && res.statusCode === 200) {
            setSendOtpAgain(false);
            dispatch(showLoadingAction(false))
        } else {
            dispatch(showLoadingAction(false))
        }
    }
    const onCompleteTimer = () => {
        setSendOtpAgain(true)
        return {shouldRepeat: true}
    }
    const renderTime = ({remainingTime}) => {
        if (remainingTime === 0) {
            return <div className="timer">مهلت ارسال کد به پایان رسید!</div>;
        }
        return (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="text">زمان باقی مانده</div>
                <div className="fa-2x ss02">{remainingTime}</div>
                <div className="text">ثانیه</div>
            </div>
        );
    };

    return (
        <div className={`position-relative h-100 w-100 d-flex flex-column`}>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => props.onBackClick?.()}>
                برگشت <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
            <div className={`d-flex flex-column h-100 mt-5 px-3`}>
                <h3 className={`secondaryColor fontWeightBold text-center`}>برداشت تتر</h3>
                <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                    <div className={`p-2 text-center`}>
                        مقدار:
                        <span className={`ss02`}>
                                {' '}{props.USDTWithdrawalAmount}
                            </span> تتر
                    </div>
                </div>
                <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                    <div className={`p-2 text-center`}>
                        کارمزد انتقال در شبکه (TRC20) :
                        <span className={`ss02`}>
                                {' '}{currenciesTopic['USDTTRC20'].withdrawalFee} تتر
                        </span>
                    </div>
                </div>
                <div className={`card box-shadow bgTertiaryLightColor text-white mt-2`}>
                    <div className={`p-2 text-center`}>
                        کیف پول:
                        <span className={`fontSizeTiny`}>
                                {' '}{props.address}
                            </span>
                    </div>
                </div>
                <h6 className={`text-white mt-3`}>جهت انجام تراکنش برداشت، کد تایید پیامک شده به موبایل خود را وارد
                    کنید</h6>
                {sendOtpAgain !== -1 && <div className={`d-flex flex-column align-items-center`}>
                    {sendOtpAgain ?
                        <>
                            <div className="timer">مهلت ارسال کد به پایان رسید!</div>
                            <Button type="primary" onClick={() => onSendOtpAgainClick()}
                                    className={`btn btnOutlinePrimary px-4 fontWeightBold w-100 d-flex align-items-center justify-content-center py-3 mt-3`}>
                                درخواست مجدد کد
                            </Button>
                        </> :
                        <>
                            <InputNumber bordered={false}
                                         placeholder="کد اعتبار سنجی"
                                         onChange={onChangeOtpCode}
                                         controls={false}
                                         min={0}
                                         maxLength={4}
                                         className={`inputs w-100 border-radius-7 ss02`}/>
                            <CountdownCircleTimer
                                duration={180}
                                colors={["#10d078", "#0d6efd", "#dc3545"]}
                                colorsTime={[180, 90, 0]}
                                isPlaying
                                onComplete={() => onCompleteTimer()}>
                                {renderTime}
                            </CountdownCircleTimer>
                        </>
                    }
                </div>
                }
            </div>
            <div className={`d-flex flex-column card box-shadow bgTertiaryLightColor w-100`}>
                <div className={`p-2 text-center w-100`}>کارمزد انتقال تتر مربوط به بلاکچین هر شبکه است و این سایت در آن
                    ذی نفع نیست
                </div>
            </div>
            <div className={`d-flex flex-column card box-shadow bgTertiaryLightColor w-100 mt-2`}>
                <div className={`p-2 text-center w-100`}>کارمزد شبکه بلاکچین برای انتقال از مبلغ درخواستی شما کسر
                    می‌شود
                </div>
            </div>
            <div className={`d-flex flex-column w-100 mb-4`}>
                <button className={`btn btnSecondary mt-4 w-100 fontWeightBold`}
                        disabled={otpCode < 999}
                        onClick={() => onApproveClick()}>تایید
                </button>
            </div>
        </div>
    );
};

export default ApproveWithdrawalUsdt;