import {useRouteError} from "react-router-dom";
import React from "react";
import {useNavigate} from "react-router";

export default function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();

    console.error(error);

    return (
        <div className="bgTertiaryColor d-flex align-items-start justify-content-center flex-column p-3 h-100 w-100">
            <p className="text-white">خطای غیر منتظره ای رخ داده است!</p>
            <p className="text-white">
                {error ? <i>{error['statusText'] || error['message']}</i> : <></>}
            </p>
            <button className={`btn btnTertiary position-absolute top-10 end-0 d-flex align-items-center`}
                    onClick={() => navigate('/')}>
                صفحه اصلی <i className={`fas fa-arrow-left text-white ms-1`}></i>
            </button>
        </div>
    );
}