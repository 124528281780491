import React from 'react';
import {Helmet} from "react-helmet";

const HelmetHOC = (props: any) => {

    return <Helmet>
        <meta charSet="utf-8"/>
        <title>{props.title}</title>
        <meta name="description" content="بیت بلکس"/>
        <meta name="description" content="بیت بلکس؛ ساده، راحت، مطمئن"/>
        <meta name="description" content="بیت بلکس؛ ساده، راحت، مطمئن؛ تتر خود را تبدیل کنید"/>
        <meta name="keywords" content="تتر، ارز دیجیتال، صرافی، بیت بلکس، بیتبلکس"/>
    </Helmet>;
};

export default HelmetHOC;
