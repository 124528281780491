import React, {useCallback, useEffect, useState} from "react";

const ChatWebView = () => {
    const [isLoaded, setLoaded] = useState(false);

    const tryAddTgFuncs = useCallback(() => {
        if (window["Telegram"]) {
            window["Telegram"].WebApp.enableClosingConfirmation();
            window["Telegram"].WebApp.ready();
            window["Telegram"].WebApp.expand();

            window.addEventListener("goftino_closeWidget", function () {
                window["Telegram"].WebApp.close();
            });
        } else {
            setTimeout(() => tryAddTgFuncs(), 1000);
        }
    }, []);

    useEffect(() => {
        const tgScript = document.createElement("script");
        tgScript.type = "text/javascript";
        tgScript.src = "https://telegram.org/js/telegram-web-app.js";
        document.head.appendChild(tgScript);

        window.addEventListener("goftino_ready", function () {
            setLoaded(true);
            window["Goftino"].open();
        });

        tryAddTgFuncs();
    }, [tryAddTgFuncs]);

    return (
        <div
            className={`h-100 w-100 d-flex align-items-center justify-content-center fontSize2xlarge`}
        >
            {!isLoaded ? `درحال اتصال به پشتیبانی ...` : ""}
        </div>
    );
};

export default ChatWebView;
