import {IProfileProps} from "../shared/interfacesAndTypes/interfaces";

type tokenType = {
    token: string;
    hasToken: boolean;
};

export const tokenInitialState: tokenType = {
    token: '',
    hasToken: false
};

type userMobileType = {
    userMobile: string;
};

export const userMobileInitialState: userMobileType = {
    userMobile: '',
};

type showLoadingType = {
    showLoading: boolean;
};

export const showLoadingInitialState: showLoadingType = {
    showLoading: false,
};

export const userProfileInitialState: { profile: IProfileProps } = {
    profile: {
        name: '',
        email: '',
        phoneNo: '',
        birthDate: '',
        nationalCode: '',
        tgActive: false,
        balances: {
            "USDT": '',
            "TOMAN": ''
        },
        blockedBalances: {
            "USDT": '',
            "TOMAN": ''
        },
        userLevel: {
            id: -1,
            title: ''
        },
        referral: {
            id: '',
            siteLink: '',
            tgLink: ''
        },
        unreadMsg: 0
    }
};

export const dictionaryInitialState = {
    currencies: {},
    math: {},
    userLevel: {},
    adminLevel: {},
    transaction: {},
    platform: {},
}

type currenciesTopicType = {
    currencies: object;
};

export const currenciesTopicInitialState: currenciesTopicType = {
    currencies: {}
}

type festivalTopicType = {
    festivalTopic: object
};

export const festivalTopicInitialState: festivalTopicType = {
    festivalTopic: {
        festCount: 0
    }
}