import React, {useEffect, useState} from 'react';
import {getChartData} from "../services/services";
import {DurationTypes} from "../interfacesAndTypes/interfaces";
import {Chart, Filler} from "chart.js";
import {Line} from "react-chartjs-2";
import {Button, Dropdown, MenuProps, Radio} from "antd";
import {showLoadingAction, useAppDispatch} from "../../redux";
import useWindowDimensions from "../dimensions/dimensions";
import styles from './chart-modal.module.scss'

Chart.register(Filler);

const ChartModal = () => {
    const [chartLabels, setChartLabels] = useState<Array<string>>([]);
    const [chartFilterLabels, setChartFilterLabels] = useState<Array<string>>([]);
    const [isEnoughChartData, setIsEnoughChartData] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [chartState, setChartState] = useState<any>({
        labels: [""],
        datasets: [{
            label: 'قیمت',
            fill: true,
            backgroundColor: '#000',
            data: []
        }]
    })
    const [selectedFilterChart, setSelectedFilterChart] = useState<DurationTypes>('1day')
    const [maxTicksLimit, setMaxTicksLimit] = useState(13)
    const dispatch = useAppDispatch();
    const filterChartOptions = [
        {value: '4hour', label: 'چهار ساعت'},
        {value: '1day', label: 'یک روز'},
        {value: '1week', label: 'یک هفته'},
        {value: '1month', label: 'یک ماه'},
        {value: '6month', label: 'شش ماه'}
    ]
    const width = useWindowDimensions();
    let showRadios = width > 992;
    const items: MenuProps['items'] = [
        {label: 'چهار ساعت', key: '4hour'},
        {label: 'یک روز', key: '1day'},
        {label: 'یک هفته', key: '1week'},
        {label: 'یک ماه', key: '1month'},
        {label: 'شش ماه', key: '6month'},
    ];

    const onClick = (data) => {
        setSelectedFilterChart(data.key)
    };

    useEffect(() => {
        /* let maxTicksLimit = 13
         switch (selectedFilterChart) {
             case "4hour":
                 maxTicksLimit = 5;
                 break;
             case "1day":
                 maxTicksLimit = 13;
                 break;
             case "1week":
                 maxTicksLimit = 7;
                 break;
             case "1month":
                 maxTicksLimit = 7;
                 break;
             case "6month":
                 maxTicksLimit = 7;
                 break;
             default:
                 console.log("Unknown timeframe");
         }
         console.log(maxTicksLimit)
         setConfig(prevState => ({
             ...prevState,
             scales: {
                 ...prevState.scales,
                 x: {
                     ...prevState.scales!.x,
                     ticks: {
                         ...prevState.scales!.x!.ticks,
                         maxTicksLimit
                     }
                 }
             }
         }))*/
    }, [selectedFilterChart])

    useEffect(() => {
        dispatch(showLoadingAction(true))
        getChartData('USDTTRC20', selectedFilterChart).then(response => {
            if (!!response && response.data) {
                setChartLabels(response.data['fullLabels'])
                setChartFilterLabels(response.data['visibleLabels'])
                setChartData(response.data['allPrices'])
                switch (selectedFilterChart) {
                    case "4hour":
                        setMaxTicksLimit(5);
                        break;
                    case "1day":
                        setMaxTicksLimit(13);
                        break;
                    case "1week":
                        setMaxTicksLimit(13);
                        break;
                    case "1month":
                        setMaxTicksLimit(12);
                        break;
                    case "6month":
                        setMaxTicksLimit(10);
                        break;
                    default:
                        throw new Error("Unknown timeframe");
                }
                if (response.data['allPrices'].length < 2) {
                    setIsEnoughChartData(false)
                } else {
                    setIsEnoughChartData(true)
                }
                dispatch(showLoadingAction(false))
            } else {
                dispatch(showLoadingAction(false))
            }
        })
    }, [dispatch, selectedFilterChart]);

    useEffect(() => {
        let element = document.getElementById("lineChart") as HTMLCanvasElement
        let ctx = element.getContext('2d');

        let gradient = ctx!.createLinearGradient(0, 25, 0, 400);
        let lineColor = '', gradiantFirstColor = '';
        if (chartData[0] < chartData[chartData.length - 1]) {
            lineColor = '#00cd98';
            gradiantFirstColor = 'rgba(0, 205, 152, 0.15)'
        } else {
            lineColor = '#BA4647';
            gradiantFirstColor = 'rgba(186, 70, 71, 0.15)'
        }
        gradient.addColorStop(0, gradiantFirstColor);
        gradient.addColorStop(1, "rgba(27, 33, 38, 0.15)");

        setChartState({
            labels: chartFilterLabels,
            datasets: [{
                label: 'قیمت',
                data: chartData,
                fill: true,
                backgroundColor: gradient,
                borderColor: lineColor,
                lineTension: 0,
                borderWidth: 2,
                pointRadius: 3,
                pointBorderColor: "rgba(0, 0, 0, 0)",
                pointBackgroundColor: "rgba(0, 0, 0, 0)",
                pointHoverBackgroundColor: lineColor,
                pointHoverBorderColor: lineColor,
            }]
        })
    }, [chartData, chartFilterLabels]);

    useEffect(() => {
        const orientationChanged = event => {
            setIsEnoughChartData(false)
            setIsEnoughChartData(true)
        };
        window.addEventListener("orientationchange", orientationChanged);
        return () => {
            window.removeEventListener("orientationchange", orientationChanged);
        };
    }, []);

    return <div className={`w-100 border-radius-7 ${styles.container} ss02 h-100`}>
        {showRadios ? <Radio.Group className={`mt-3`}
                                   size={'middle'}
                                   onChange={(e) => setSelectedFilterChart(e.target.value)}
                                   value={selectedFilterChart}>
                {filterChartOptions.map((item, key) => {
                    return <Radio.Button key={key} value={item.value}
                                         className={`fontWeightBold`}>{item.label}</Radio.Button>
                })}
            </Radio.Group> :
            <Dropdown placement="bottomLeft"
                      arrow={{pointAtCenter: true}}
                      trigger={['click']}
                      menu={{items, onClick}}>
                <Button size={"middle"}
                        className={`btn btn-light d-flex align-items-center justify-content-center mt-3`}>
                    <i className="fas fa-filter me-2"></i> {filterChartOptions.find(filter => filter.value === selectedFilterChart)!.label}
                </Button>
            </Dropdown>}
        <div className={`position-relative`}>
            <Line id={`lineChart`}
                  data={chartState}
                  options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      layout: {padding: {left: 8, right: 8}},
                      interaction: {
                          intersect: false,
                          mode: 'index',
                          axis: 'xy'
                      },
                      plugins: {
                          legend: {display: false},
                          title: {
                              display: true,
                              font: {family: "dana VF", size: 14},
                              padding: {top: 4, bottom: 10},
                              color: 'rgba(238, 238, 238, 1)',
                              text: `(USDT) تتر ⬅ ` +
                                  Number(chartData[chartData.length - 1]).toLocaleString("en-US") +
                                  ` تومان`,
                              align: "center",
                          },
                          tooltip: {
                              displayColors: false,
                              titleFont: {
                                  family: 'dana VF',
                                  size: 15,
                                  lineHeight: 1.2,
                                  weight: 'bold',
                              },
                              bodyFont: {
                                  family: 'dana VF',
                                  size: 15,
                                  lineHeight: 1.2,
                                  weight: 'bold'
                              },
                              backgroundColor: '#1a202c',
                              borderColor: '#424454',
                              borderWidth: 2,
                              callbacks: {
                                  title: function (title) {
                                      return chartLabels[title[0].dataIndex];
                                  },
                                  label: function (context) {
                                      let label = context.label || '';
                                      if (label.length === 0) {
                                          return `قیمت: ${context.formattedValue} تومان`;
                                      }
                                  }
                              },
                          }
                      },
                      scales: {
                          y: {
                              ticks: {
                                  maxTicksLimit: 10,
                                  color: "rgba(238, 238, 238, 0.5)",
                                  font: {family: "dana VF", size: 15}
                              },
                              grid: {
                                  display: true,
                                  color: 'rgba(238, 238, 238, 0.05)'
                              },
                          },
                          x: {
                              ticks: {
                                  maxTicksLimit: maxTicksLimit,
                                  font: {family: "dana VF", size: 15},
                                  maxRotation: 0,
                                  minRotation: 0,
                                  color: 'rgba(238, 238, 238, 0.5)'
                              },
                              grid: {
                                  display: true,
                                  color: 'rgba(238, 238, 238, 0.05)'
                              }
                          }
                      }
                  }}
                  height={450}
                  style={{visibility: isEnoughChartData ? 'visible' : 'hidden'}}/>
            {!isEnoughChartData &&
                <div className={`${styles.notEnoughData} fontSizeLarge`}>
                    <i className={`fas fa-times-circle text-danger me-2`}></i>
                    <span className={`text-muted`}>داده کافی برای نمایش وجود ندارد</span>
                </div>
            }
        </div>
    </div>
};

export default ChartModal;
