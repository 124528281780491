import React from 'react';
import styles from './pwa.module.scss'
import {useNavigate} from "react-router";
import {createSearchParams} from "react-router-dom";

const Pwa = () => {
    const navigate = useNavigate();

    const onOkClick = async () => {
        let query = {fromPwa: 'true'}
        await navigate({
            pathname: "/",
            search: createSearchParams(query).toString()
        })
    }

    return (
        <div
            className={`d-flex flex-column align-items-center justify-content-center h-100 w-100 overflow-hidden card-body`}>
            <img className={`d-flex`}
                 src="/img/logo.png"
                 alt="bitblex"
                 width={`150`}
                 height={`30`}/>
            <p className={`text-white text-center mt-3 lh-1 mb-2`}>وب اپلیکیشن بیت بلکس را به صفحه اصلی </p>
            <p className={`text-white text-center`}> تلفن همراه خود اضافه کنید</p>
            <div className={`mt-3 d-flex flex-column`}>
                <div className={`d-flex align-items-center`}>
                    <div
                        className={`${styles.icon} d-flex flex-column align-items-center justify-content-center  me-3`}>
                        <i className="fas fa-share text-white"></i>
                    </div>
                    <div className={`d-flex flex-column`}>
                        <span className={`text-white fw-bold`}>Share</span>
                        <span className={`text-muted mt-2`}>انتخاب این گزینه از نوار پایین</span>
                    </div>
                </div>
                <div className={`d-flex mt-4 align-items-center`}>
                    <div
                        className={`${styles.icon} d-flex flex-column align-items-center justify-content-center  me-3`}>
                        <i className="fas fa-plus text-white"></i>
                    </div>
                    <div className={`d-flex flex-column`}>
                        <span className={`text-white fw-bold`}>Add to home screen</span>
                        <span className={`text-muted mt-2`}>انتخاب این گزینه از منوی ظاهر شده</span>
                    </div>
                </div>
                <div className={`d-flex mt-4 align-items-center`}>
                    <div
                        className={`${styles.icon} bg-white d-flex flex-column align-items-center justify-content-center  me-3`}>
                        <img className={`d-flex`}
                             src="/logo192.png"
                             alt="bitblex"
                             width={`40`}
                             height={`40`}/>
                    </div>
                    <div className={`d-flex flex-column`}>
                        <span className={`text-white fw-bold`}>Add</span>
                        <span className={`text-muted mt-2`}>کلیک بر روی این گزینه</span>
                    </div>
                </div>
            </div>
            <div className={`p-2 mt-2 text-center`}>
                <i className="fas fa-exclamation-circle me-1"></i>
                <span className={`text-white mt-3 lh-base`}>
                    توجه: برای استفاده از نسخه pwa در گوشی های آیفون، از مرورگر سافاری استفاده کنید
                </span>
            </div>
            <button className={`btn btnPrimary mt-4 fontWeightBold px-2 w-100`}
                    onClick={() => onOkClick()}>متوجه شدم
            </button>
        </div>
    );
};

export default Pwa;