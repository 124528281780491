import React from 'react';
import IntroComponent from "../pages/intro/intro";
import HelmetHOC from "./helmet-HOC";

const IntroHoc = () => {

    return <>
        <HelmetHOC title="بیت بلکس | Bitblex - خوش آمدید"/>
        <IntroComponent/>
    </>
};

export default IntroHoc;