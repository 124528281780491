import React from 'react';
import Navbar from "../../shared/navbar/navbar";
import styles from "./Invite-friends.module.scss";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import {Collapse, Input, notification} from "antd";
import {selectIsAuthenticated, useAppSelector, userProfileSelector} from "../../redux";
import {IProfileProps} from "../../shared/interfacesAndTypes/interfaces";
import ReactPlayer from "react-player";

const {Panel} = Collapse;

const InviteFriends = () => {

    const questions = [
        {
            question: 'چگونه باید درآمد دعوت از دوستانمان را دریافت کنیم؟',
            answer: ' پس از هر معامله‌ای که دوستانتان در بیت بلکس انجام می‌دهند، مبلغ کارمزد به صورت مستقیم و به صورت ریالی در حساب شما شارژ می‌شود و شما می‌توانید آن را برداشت کنید. '
        },
        {
            question: ' آیا کسب درآمد در دعوت از دوستان محدودیت دارد؟',
            answer: ' خیر، طرح «دعوت از دوستان» بیت بلکس، شامل هیچگونه محدودیتی نمی‌باشد. شما می‌توانید به هر تعداد و تا هر زمانی که دوستانتان با بیت بلکس معامله می‌کنند با استفاده از طرح «دعوت از دوستان» در سود بیت بلکس شریک باشید و کسب درآمد کنید. '
        },
        {
            question: ' آیا دعوت از دوستان کلاهبرداری است؟',
            answer: ' طرح «دعوت از دوستان» یکی از روش‌های بازاریابی رایج است و فقط مختص یک صنعت خاص مانند ارزهای دیجیتال نیست. این روش‌ سال‌هاست که در بین شرکت‌های مختلف اجرا می‌شود. جالب است بدانید حتی کسب درآمد از کد دعوت امروزه به عنوان یکی از محبوبترین روش‌های کسب درآمد در بین افراد فعال در دنیای اینترنت پذیرفته شده است. '
        },
        {
            question: ' طرح معرفی به دوستان چه مزایایی دارد؟',
            answer: 'شما با معرفی دوستان خود به بیت بلکس می‌توانید از مزایای زیر برخوردار شوید:',
            items: [
                'به ازای ثبت نام دوستان مستقیم، مبلغ 30 هزار تومان (یک بار) پس از انجام اولین معامله وی به فرد معرف تعلق می گیرد',
                'از هر معامله خرید یا فروش دوستان مستقیم کاربر، معادل یکدهم درصد (0.1%) از حجم کل سفارش بعنوان هدیه برای وی لحاظ خواهد شد',
                'به ازای هر معامله دوستانتان در تالار معاملات، 30٪ از کارمزد تراکنش دوستان خود را به عنوان پاداش دریافت می‌کنید'
            ]
        }
    ]
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const userProfile: IProfileProps = useAppSelector(userProfileSelector)

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }
    const onCopyToClipboardClick = () => {
        navigator.clipboard.writeText('');
        notification['success']({
            message: 'با موفقیت کپی شد',
            description: '',
            placement: 'bottomRight'
        });
    }

    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`${styles.gradiant2}`}></div>
            <div className={`container d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div className={`${styles.container} container`}>
                    <div className={`row lh-lg fw-light`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-6 order-1 d-flex justify-content-center flex-column ss02 fontSizeSmall`}>
                            <h4 className={`fontWeightBold`}>با دعوت از دوستان خود</h4>
                            <h4 className={`fontWeightBold`}>در سود صرافی سهیم شوید!</h4>
                            <div className={`mt-3`}>
                                به راحتی لینک دعوت از دوستان یا کد ریفرال خود را برای دوستانتان ارسال کنید تا به صورت مادام العمر و بی دردسر از تراکنش های صرافی درامد کسب کنید
                            </div>
                            {!isAuthenticated ?
                                <div className={`d-flex mt-2`}>
                                    <button className={`btn btn-primary py-1 fontSizeSmall`}
                                            onClick={() => resetDrawer(true)}>
                                        همین الان شروع کنید
                                        <i className={`fas fa-arrow-left ms-2 fontSizeTiny`}></i>
                                    </button>
                                </div>
                                :
                                <div className={`${styles.startNow} d-flex align-items-center mt-2`}>
                                    <Input placeholder={``}
                                           className={`border-o text-white`}
                                           bordered={false}
                                           readOnly={true}
                                           value={userProfile.referral?.id}
                                    />
                                    <button className={`btn btn-primary py-1 fontSizeSmall`}
                                            onClick={() => onCopyToClipboardClick()}>
                                        کپی
                                        <i className={`fas fa-copy ms-2 fontSizeTiny`}></i>
                                    </button>
                                </div>}
                        </div>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-6 order-2 mt-3 mt-xl-0`}>
                            <div className={`w-100 videoContainer ${styles.videoContainer}`}>
                                <ReactPlayer url='video/earnMoney.mp4'
                                             width='100%'
                                             height='100%'
                                             style={{borderRadius: 20}}
                                             controls={true}/>
                            </div>
                        </div>
                    </div>
                    <div className={`mt-5 d-flex flex-column flex-lg-row align-items-center justify-content-between`}>
                        <div className={``}>
                            <h4 className={`fontWeightBold mb-3`}>چطور میتوانم دوستانم را دعوت کنم و درآمد کسب کنم؟</h4>
                            <span className={`text-semi-muted`}>به راحتی با انجام مراحل زیر تا کسب درامد </span>
                        </div>
                        <div className={`mt-lg-0 mt-3`}>
                            <button className={`btn btn-primary py-1 fontSizeSmall`}
                                    onClick={() => resetDrawer(true)}>
                                همین الان شروع کنید
                                <i className={`fas fa-arrow-left ms-2 fontSizeTiny`}></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-100 position-relative`}>
                <img src="/img/Path%20138.png" alt="Path" className={`position-absolute d-none d-lg-block`}/>
                <div className={`container d-flex flex-column flex-lg-row justify-content-between`}>
                    <div className={`${styles.polygon1} d-flex flex-column`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`position-relative`}>
                                <img src="/img/invite-friend-1.png" alt="Polygon" className={`${styles.polygon}`}/>
                                <span className={`position-absolute text-dark lh-base bg-white d-flex
                                                align-items-center justify-content-center fa-xl ss02 ${styles.step} ${styles.step1}`}>
                                    1
                                </span>
                            </div>
                        </div>
                        <div className={`fw-bold text-white mt-3 text-center`}>تکمیل ثبت نام و دریافت لینک اختصاصی</div>
                        <div className={`text-semi-muted mt-2 fa-sm lh-base text-center`}>
                            در ربات یا وبسایت بیت بلکس ثبت نام کنید و از حساب کاربری خود لبنک اختصاصی را دریافت کنید
                        </div>
                    </div>
                    <div className={`${styles.polygon2} d-flex flex-column`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`position-relative`}>
                                <img src="/img/invite-friend-2.png" alt="Polygon" className={`${styles.polygon}`}/>
                                <span className={`position-absolute text-dark lh-base bg-white d-flex
                                                align-items-center justify-content-center fa-xl ss02 ${styles.step} ${styles.step2}`}>
                                    2
                                </span>
                            </div>
                        </div>
                        <div className={`fw-bold text-white mt-3 text-center`}>ارسال بدون محدودیت لینک دعوت یا کد معرف</div>
                        <div className={`text-semi-muted mt-2 fa-sm lh-base text-center`}>
                            لینک یا کد معرف اختصاصی خود را با دوستان و یا در شبکه‌های اجتماعی به اشتراک بگذارید.
                        </div>
                    </div>
                    <div className={`${styles.polygon3} d-flex flex-column`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`position-relative`}>
                                <img src="/img/invite-friend-3.png" alt="Polygon" className={`${styles.polygon}}`}/>
                                <span className={`position-absolute text-dark lh-base bg-white d-flex
                                                align-items-center justify-content-center fa-xl ss02 ${styles.step} ${styles.step3}`}>
                                    3
                                </span>
                            </div>
                        </div>
                        <div className={`fw-bold text-white mt-3 text-center`}>ثبت نام دوستان و دریافت هدیه</div>
                        <div className={`text-semi-muted mt-2 fa-sm lh-base text-center`}>
                            کافیست دوستان‌تان با کد معرف یا لینک دعوت شما ثبت نام کنند و با احراز معاملات خودشون رو انجام دهند.
                        </div>
                    </div>
                    <div className={`${styles.polygon4} d-flex flex-column`}>
                        <div className={`d-flex flex-column align-items-center justify-content-center`}>
                            <div className={`position-relative`}>
                                <img src="/img/invite-friend-4.png" alt="Polygon" className={`${styles.polygon}`}/>
                                <span className={`position-absolute text-dark lh-base bg-white d-flex
                                                align-items-center justify-content-center fa-xl ss02 ${styles.step} ${styles.step4}`}>
                                    4
                                </span>
                            </div>
                        </div>
                        <div className={`fw-bold text-white mt-3 text-center`}>کسب درآمد مادام المر از معاملات</div>
                        <div className={`text-semi-muted mt-2 fa-sm lh-base text-center`}>
                            شما روزانه از تراکنش‌های دوستان مستقیم و غیرمستقیم خود تا سقف
                            <span className={`mx-2 px-2 badge bg-success`}>۳۰٪</span>
                            درصد بصورت مادام العمر هدیه دریافت می‌کنید
                        </div>
                    </div>
                </div>
            </div>
            <div className={`container d-flex flex-column mt-5`}>
                <div className={`mt-5 row`}>
                    <div className={`col-12 col-md-12 col-lg-6 p-0`}>
                        <div className={`row p-0 m-0`}>
                            <div
                                className={`col-12 col-mg-12 col-lg-5 d-flex d-lg-block align-items-center justify-content-center`}>
                                <img src="/img/invite-friend-benefit-1.png" alt="invite-friend-benefit"
                                     style={{height: '70%'}}/>
                            </div>
                            <div className={`col-12 col-md-12 col-lg-7 d-flex align-items-center flex-column`}>
                                <h5 className={`fontWeightBold`}>دریافت هدیه پس از ثبت نام دوستان</h5>
                                <p className={`text-white lh-base`}>
                                    حال به راحتی در معاملات صرافی شریک شوید. به راحتی و بدون دردسر
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-12 col-lg-6 p-0`}>
                        <div className={`row p-0 m-0`}>
                            <div
                                className={`col-12 col-mg-12 col-lg-5 d-flex d-lg-block align-items-center justify-content-center`}>
                                <img src="/img/invite-friend-benefit-2.png" alt="invite-friend-benefit"
                                     style={{height: '70%'}}/>
                            </div>
                            <div className={`col-12 col-md-12 col-lg-7 d-flex align-items-center flex-column`}>
                                <h5 className={`fontWeightBold`}>درآمد از دعوت دوستان مستقیم و غیر مستقیم</h5>
                                <p className={`text-white lh-base`}>
                                    از تمام تراکنش های دوستانتان سود دریافت می کنید
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-5`}>
                    <h4 className={`fontWeightBold mb-3`}> چرا دعوت از دوستان یک بازی برد-برد است؟ </h4>
                    <span className={`text-semi-muted lh-lg`}>
                             کسب درآمد شما با دعوت دوستانتان به بیت بلکس یک
                            بازی برد-برد برای همگی است. شما فردی هستید که در سود دوستانتان شریک می‌شوید، بیت بلکس به لطف
                            شما دارای خانواده بزرگتری می‌شود، دوستانتان می‌توانند در بازار ارزهای دیجیتال بر روی آینده
                            سرمایه‌گذاری کنند و یا با دعوت از دوستانشان درآمد کسب کنند، همچنین اکوسیستم ارزهای دیجیتال با
                            تعامل بیشتر یک گام دیگر به تعالی و بلوغ نزدیک‌تر می‌شود. پس همین حالا دوستان‌‌تان را دعوت کنید.
                        </span>
                </div>
                <Collapse accordion className={`bg-transparent w-100 box-shadow mt-3 mb-3 border-0`}>
                    {questions.map((question, key) => {
                        return <Panel
                            className={`${styles.collapse} inviteFriendPanel`}
                            header={
                                <div key={key} className={`cursor-pointer text-white`}>
                                    <img src="/img/FaqBullets.png" alt="invite-friend-bullets"
                                         className={`me-3 ${styles.faqBullets}`}/>
                                    {question.question}
                                </div>
                            }
                            key={key}>
                                <span className={`text-white`}>
                                    {question.answer}
                                    {(!!question.items && question.items.length > 0) ?
                                        <ul className={`list-group mt-3`}>
                                            {question.items.map((item, key) => {
                                                return <div className={`d-flex align-items-center`}>
                                                    <img src="/img/FaqBullets.png"
                                                         alt="invite-friend-bullets"
                                                         height={15} width={15}
                                                         className={`me-1 ${styles.faqBullet}`}/>
                                                    <li key={key}
                                                        className={`border-0 ${styles.listGroupItem}`}>
                                                        {item}
                                                    </li>
                                                </div>
                                            })}
                                        </ul> : <></>
                                    }
                                </span>
                        </Panel>
                    })}
                </Collapse>
            </div>
        </>
    )
}

export default InviteFriends;