import React from 'react';
import Navbar from "../../shared/navbar/navbar";
import styles from "./tlg-bot.module.scss";
import EventEmitter from "../../shared/event-emitter/event-emitter";
import ReactPlayer from "react-player";


const TlgBot = () => {

    const resetDrawer = (status) => {
        EventEmitter.emit('resetDrawer', status)
    }

    return (
        <>
            <div className={`${styles.gradiant}`}></div>
            <div className={`${styles.gradiant2}`}></div>
            <div className={`container d-flex flex-column`}>
                <Navbar onLoginClick={() => resetDrawer(true)}/>
                <div className={`${styles.container} container`}>
                    <div className={`row lh-lg fw-light`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-6 order-1 d-flex justify-content-center flex-column ss02 fontSizeSmall`}>
                            <h4 className={`fontWeightBold`}>ربات تلگرام</h4>
                            <div className={`mt-3`}>
                                پلتفرم ما در بیتبلکس تنها محدود به وبسایت نیست؛ بلکه برای راحتی شما
                                ربات‌ تلگرام رو طراحی و برنامه نویسی کردیم تا شما از تمام امکانات یک صرافی با امنیت بالا
                                داخل پلتفرم محبوب تلگرام بهره مند شوید.
                                با ارتقا کیفیت خدماتمون در کنار وبسایت، همه کاربر ها میتوانند با چند کلیک به راحتی و
                                امنیت بسیار بالا، از طریق ربات تلگرام به حساب کاربری خودشون وارد شوند و تمام عملیات ثبت
                                نام، احرازهویت، واریز و برداشت و معاملات رو به راحتی بیشتر انجام دهند .
                            </div>
                            <div className={`mt-3`}>
                                <button className={`btn btn-primary py-1 fontSizeSmall`}
                                        onClick={() => window.open('https://t.me/BitblexBot', '_blank', 'noopener,noreferrer')}>
                                    شروع معامله با ربات
                                    <i className={`fas fa-check-circle ms-2`}></i>
                                </button>
                            </div>
                        </div>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-6 order-2 mt-3 mt-xl-0`}>
                            <div className={`w-100 videoContainer ${styles.videoContainer}`}>
                                <ReactPlayer url='video/tgBot.mp4'
                                             width='100%'
                                             height='100%'
                                             style={{borderRadius: 20}}
                                             controls={true}/>
                            </div>
                        </div>
                    </div>
                    <h4 className={`fontWeightBold`} style={{marginTop: 150}}>ویژگی های ربات ما</h4>
                    <div className={`mt-3`}>مراحل زیر را در ربات دنبال کنید</div>
                    <div className={`row lh-lg fw-light mt-5`}>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-5`}>
                            <img src="/img/onlyOneClick.png" alt="onlyOneClick" className={`${styles.img}`}/>
                        </div>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-center flex-column ss02 fontSizeSmall mt-3 mt-xl-0`}>
                            <h4 className={`fontWeightBold`}>فقط با یک‌ کلیک شروع کنید</h4>
                            <div className={`mt-3`}>
                                از لینک بالا ی همین صفحه میتونید به ربات بیت‌بلکس متصل بشید یا از داخل تلگرام نام
                                BitblexBot@
                                رو جستجو کنید و دکمه START رو بزنید.
                                حالا با ورود شماره موبایل به نام خودتون و گذروندن ادامه مراحل میتونید به حساب کاربریتون
                                در داخل بیت‌بلکس وصل بشید.
                            </div>
                        </div>
                    </div>
                    <div className={`row lh-lg fw-light mt-5`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-center flex-column ss02 fontSizeSmall mt-3 mt-xl-0 order-2 order-xl-1`}>
                            <h4 className={`fontWeightBold`}>تمام امکانات ربات تلگرام همانند وب سایت</h4>
                            <div className={`mt-3`}>
                                افتخار و توان تیم برنامه نویسی ما این بوده که تمام ‌امکانات وبسایت رو در ربات تلگرام هم
                                در اختیار کاربرانمون بگذاریم
                                به عبارتی شما کاربر عزیز میتونید از ابتدا تا انتها داخل یکی از پلتفرم های بیت‌بلکس
                                معاملات رمزارز خود را انجام دهید
                                <br/>
                                امکانات ربات:
                                <ul>
                                    <li style={{listStyleType: 'disc'}}>ثبت‌ نام سریع</li>
                                    <li style={{listStyleType: 'disc'}}>احراز هویت هوشمند</li>
                                    <li style={{listStyleType: 'disc'}}>مدیریت کیف پول شخصی شما</li>
                                    <li style={{listStyleType: 'disc'}}>معامله تتر به صورت آنی و سفارش‌ گذاری</li>
                                    <li style={{listStyleType: 'disc'}}>کسب درامد با دعوت از دوستان</li>
                                    <li style={{listStyleType: 'disc'}}>پشتیبانی آنلاین</li>
                                </ul>
                            </div>
                        </div>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-5 order-1 order-xl-2`}>
                            <img src="/img/connectToWallet.png" alt="connectToWallet" className={`${styles.img}`}/>
                        </div>
                    </div>
                    <div className={`row lh-lg fw-light mt-5`}>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-5`}>
                            <img src="/img/highSecurity.png" alt="highSecurity" className={`${styles.img}`}/>
                        </div>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-center flex-column ss02 fontSizeSmall mt-3 mt-xl-0`}>
                            <h4 className={`fontWeightBold`}>امنیت بالا</h4>
                            <div className={`mt-3`}>
                                اکثر کاربران حرفه ای بدلیل امنیت بالای پلتفرم محبوب تلگرام، از این پیام‌رسان قوی
                                استفاده میکنند و ما هم طبق اولویت های خودمون به نیاز های کاربرانمون اهمیت میدیم.
                                ما در ربات‌ بیت‌بلکس با استفاده از استاندارد های امنیتی تلگرام، تمام امکانات صرافی رو در
                                اختیار کاربران عزیز گذاشتیم .
                                از رمز نگراری چند لایه گرفته تا مدیریت حرفه کیف پول شخصی کاربرانمون ، به همین دلیل بدون
                                دغدغه و بی دردسر از ربات بیت‌بلکس استفاده کنید.
                            </div>
                        </div>
                    </div>
                    <div className={`row lh-lg fw-light mt-5`}>
                        <div
                            className={`col-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-center flex-column ss02 fontSizeSmall mt-3 mt-xl-0 order-2 order-xl-1`}>
                            <h4 className={`fontWeightBold`}>دریافت جایزه</h4>
                            <div className={`mt-3`}>
                                از معاملات صرافی با معرفی دوستان خود کسب درامد کنید و جایزه بگیرید !
                                فقط کافیه از پنل کاربری خود در قسمت کسب درآمد، لینک دعوت خودتون رو برای دوستانتون ارسال کنید و
                                با ثبت نام و معامله دوستان شما، بصورت مادالعمر از تراکنش هایشان درامد کسب کنید .
                            </div>
                        </div>
                        <div className={`col-12 col-md-12 col-lg-12 col-xl-5 order-1 order-xl-2`}>
                            <img src="/img/tlgPrice.png" alt="tlgPrice" className={`${styles.img}`}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TlgBot;